import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb.tsx';
import CheckboxFive from '../../components/Checkboxes/CheckboxFive.tsx';
import CheckboxFour from '../../components/Checkboxes/CheckboxFour.tsx';
import CheckboxOne from '../../components/Checkboxes/CheckboxOne.tsx';
import CheckboxThree from '../../components/Checkboxes/CheckboxThree.tsx';
import CheckboxTwo from '../../components/Checkboxes/CheckboxTwo.tsx';
import SwitcherFour from '../../components/Switchers/SwitcherFour.tsx';
import SwitcherOne from '../../components/Switchers/SwitcherOne.tsx';
import SwitcherThree from '../../components/Switchers/SwitcherThree.tsx';
import SwitcherTwo from '../../components/Switchers/SwitcherTwo.tsx';
import DatePickerOne from '../../components/Forms/DatePicker/DatePickerOne.tsx';
import DatePickerTwo from '../../components/Forms/DatePicker/DatePickerTwo.tsx';
import SelectGroupTwo from '../../components/Forms/SelectGroup/SelectGroupTwo.tsx';
import MultiSelect from '../../components/Forms/MultiSelect.tsx';
import React from 'react';
const FormElements = () => {
  return (
    <>
      <Breadcrumb pageName="Form Elements" />

      <div className="tw-grid tw-grid-cols-1 tw-gap-9 sm:tw-grid-cols-2">
        <div className="tw-flex tw-flex-col tw-gap-9">
          {/* <!-- Input Fields --> */}
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-6.5 dark:tw-border-strokedark">
              <h3 className="tw-font-medium tw-text-black dark:tw-text-white">
                Input Fields
              </h3>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5.5 tw-p-6.5">
              <div>
                <label className="tw-mb-3 tw-block tw-text-black dark:tw-text-white">
                  Default Input
                </label>
                <input
                  type="text"
                  placeholder="Default Input"
                  className="tw-w-full tw-rounded-lg tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                />
              </div>

              <div>
                <label className="tw-mb-3 tw-block tw-text-black dark:tw-text-white">
                  Active Input
                </label>
                <input
                  type="text"
                  placeholder="Active Input"
                  className="tw-w-full tw-rounded-lg tw-border-[1.5px] tw-border-primary tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-bg-form-input dark:tw-text-white"
                />
              </div>

              <div>
                <label className="tw-mb-3 tw-block tw-font-medium tw-text-black dark:tw-text-white">
                  Disabled label
                </label>
                <input
                  type="text"
                  placeholder="Disabled label"
                  disabled
                  className="tw-w-full tw-rounded-lg tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary dark:disabled:tw-bg-black"
                />
              </div>
            </div>
          </div>

          {/* <!-- Toggle switch input --> */}
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-6.5 dark:tw-border-strokedark">
              <h3 className="tw-font-medium tw-text-black dark:tw-text-white">
                Toggle switch input
              </h3>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5.5 tw-p-6.5">
              <SwitcherOne />
              <SwitcherTwo />
              <SwitcherThree />
              <SwitcherFour />
            </div>
          </div>

          {/* <!-- Time and date --> */}
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-6.5 dark:tw-border-strokedark">
              <h3 className="tw-font-medium tw-text-black dark:tw-text-white">
                Time and date
              </h3>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5.5 tw-p-6.5">
              <DatePickerOne />
              <DatePickerTwo />
            </div>
          </div>

          {/* <!-- File upload --> */}
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-6.5 dark:tw-border-strokedark">
              <h3 className="tw-font-medium tw-text-black dark:tw-text-white">
                File upload
              </h3>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5.5 tw-p-6.5">
              <div>
                <label className="tw-mb-3 tw-block tw-text-black dark:tw-text-white">
                  Attach file
                </label>
                <input
                  type="file"
                  className="tw-w-full tw-cursor-pointer tw-rounded-lg tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-outline-none tw-transition file:tw-mr-5 file:tw-border-collapse file:tw-cursor-pointer file:tw-border-0 file:tw-border-r file:tw-border-solid file:tw-border-stroke file:tw-bg-whiter file:tw-py-3 file:tw-px-5 file:hover:tw-bg-primary file:hover:tw-bg-opacity-10 focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:file:tw-border-form-strokedark dark:file:tw-bg-white/30 dark:file:tw-text-white dark:focus:tw-border-primary"
                />
              </div>

              <div>
                <label className="tw-mb-3 tw-block tw-text-black dark:tw-text-white">
                  Attach file
                </label>
                <input
                  type="file"
                  className="tw-w-full tw-rounded-md tw-border tw-border-stroke tw-p-3 tw-outline-none tw-transition file:tw-mr-4 file:tw-rounded file:tw-border-[0.5px] file:tw-border-stroke file:tw-bg-[#EEEEEE] file:tw-py-1 file:tw-px-2.5 file:tw-text-sm focus:tw-border-primary file:focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:file:tw-border-strokedark dark:file:tw-bg-white/30 dark:file:tw-text-white"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="tw-flex tw-flex-col tw-gap-9">
          {/* <!-- Textarea Fields --> */}
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-6.5 dark:tw-border-strokedark">
              <h3 className="tw-font-medium tw-text-black dark:tw-text-white">
                Textarea Fields
              </h3>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5.5 tw-p-6.5">
              <div>
                <label className="tw-mb-3 tw-block tw-text-black dark:tw-text-white">
                  Default textarea
                </label>
                <textarea
                  rows={6}
                  placeholder="Default textarea"
                  className="tw-w-full tw-rounded-lg tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                ></textarea>
              </div>

              <div>
                <label className="tw-mb-3 tw-block tw-text-black dark:tw-text-white">
                  Active textarea
                </label>
                <textarea
                  rows={6}
                  placeholder="Active textarea"
                  className="tw-w-full tw-rounded-lg tw-border-[1.5px] tw-border-primary tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-bg-form-input dark:tw-text-white"
                ></textarea>
              </div>

              <div>
                <label className="tw-mb-3 tw-block tw-text-black dark:tw-text-white">
                  Disabled textarea
                </label>
                <textarea
                  rows={6}
                  disabled
                  placeholder="Disabled textarea"
                  className="tw-w-full tw-rounded-lg tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary dark:disabled:tw-bg-black"
                ></textarea>
              </div>
            </div>
          </div>

          {/* <!-- Checkbox and radio --> */}
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-6.5 dark:tw-border-strokedark">
              <h3 className="tw-font-medium tw-text-black dark:tw-text-white">
                Checkbox and radio
              </h3>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5.5 tw-p-6.5">
              <CheckboxOne />
              <CheckboxTwo />
              <CheckboxThree />
              <CheckboxFour />
              <CheckboxFive />
            </div>
          </div>

          {/* <!-- Select input --> */}
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-6.5 dark:tw-border-strokedark">
              <h3 className="tw-font-medium tw-text-black dark:tw-text-white">
                Select input
              </h3>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5.5 tw-p-6.5">
              <SelectGroupTwo />
              <MultiSelect id="multiSelect" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormElements;
