import React from "react";
import { MdLocalShipping } from "react-icons/md";
import { CgRedo } from "react-icons/cg";

const BannerBottom = () => {
  return (
    <div className="tw-w-full tw-bg-white tw-border-b-[1px] tw-py-4 tw-border-b-gray-200 tw-px-4">
      <div className="tw-max-w-container tw-mx-auto tw-h-20 tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center tw-gap-2 tw-w-72 tw-shadow-sm hover:tw-shadow-md tw-duration-300">
          <span className="tw-font-bold tw-font-titleFont tw-w-6 tw-text-center">2</span>
          <p className="tw-text-lightText tw-text-base">Two years warranty</p>
        </div>
        <div className="tw-flex md:tw-w-auto tw-items-center tw-gap-2 tw-w-72 tw-shadow-sm hover:tw-shadow-md tw-duration-300">
          
          <p className="tw-text-lightText tw-text-base">Great Services</p>
        </div>
        <div className="tw-flex md:tw-w-auto tw-items-center tw-gap-2 tw-w-72 tw-shadow-sm hover:tw-shadow-md tw-duration-300">
          <span className="tw-text-2xl tw-text-center tw-w-6">
            <CgRedo />
          </span>
          <p className="tw-text-lightText tw-text-base">Return policy in 30 days</p>
        </div>
      </div>
    </div>
  );
};

export default BannerBottom;
