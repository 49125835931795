import React, { useState } from 'react';

const SwitcherThree = () => {
  const [enabled, setEnabled] = useState(false);

  return (
    <div>
      <label
        htmlFor="toggle3"
        className="tw-flex tw-cursor-pointer tw-select-none tw-items-center"
      >
        <div className="tw-relative">
          <input
            type="checkbox"
            id="toggle3"
            className="tw-sr-only"
            onChange={() => {
              setEnabled(!enabled);
            }}
          />
          <div className="tw-block tw-h-8 tw-w-14 tw-rounded-full tw-bg-meta-9 dark:tw-bg-[#5A616B]"></div>
          <div
            className={`tw-dot tw-absolute tw-left-1 tw-top-1 tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-transition ${
              enabled && '!tw-right-1 !tw-translate-x-full !tw-bg-primary dark:!tw-bg-white'
            }`}
          >
            <span className={`tw-hidden ${enabled && '!tw-block'}`}>
              <svg
                className="tw-fill-white dark:tw-fill-black"
                width="11"
                height="8"
                viewBox="0 0 11 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                  fill=""
                  stroke=""
                  strokeWidth="0.4"
                ></path>
              </svg>
            </span>
            <span className={`${enabled && 'tw-hidden'}`}>
              <svg
                className="tw-h-4 tw-w-4 tw-stroke-current"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      </label>
    </div>
  );
};

export default SwitcherThree;
