import React, { useState } from 'react';

const CheckboxThree = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <div>
      <label
        htmlFor="checkboxLabelThree"
        className="tw-flex tw-cursor-pointer tw-select-none tw-items-center"
      >
        <div className="tw-relative">
          <input
            type="checkbox"
            id="checkboxLabelThree"
            className="tw-sr-only"
            onChange={() => {
              setIsChecked(!isChecked);
            }}
          />
          <div
            className={`tw-box tw-mr-4 tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded tw-border ${
              isChecked && 'tw-border-primary tw-bg-gray dark:tw-bg-transparent'
            }`}
          >
            <span
              className={`tw-text-primary tw-opacity-0 ${
                isChecked && '!tw-opacity-100'
              }`}
            >
              <svg
                className="tw-h-3.5 tw-w-3.5 tw-stroke-current"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        Checkbox Text
      </label>
    </div>
  );
};

export default CheckboxThree;
