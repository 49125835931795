import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb.tsx';
import SelectGroupOne from '../../components/Forms/SelectGroup/SelectGroupOne.tsx';
import React from 'react';

const FormLayout = () => {
  return (
    <>
      <Breadcrumb pageName="Form Layout" />

      <div className="tw-grid tw-grid-cols-1 tw-gap-9 sm:tw-grid-cols-2">
        <div className="tw-flex tw-flex-col tw-gap-9">
          {/* <!-- Contact Form --> */}
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-6.5 dark:tw-border-strokedark">
              <h3 className="tw-font-medium tw-text-black dark:tw-text-white">
                Contact Form
              </h3>
            </div>
            <form action="#">
              <div className="tw-p-6.5">
                <div className="tw-mb-4.5 tw-flex tw-flex-col tw-gap-6 xl:tw-flex-row">
                  <div className="tw-w-full xl:tw-w-1/2">
                    <label className="tw-mb-2.5 tw-block tw-text-black dark:tw-text-white">
                      First name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your first name"
                      className="tw-w-full tw-rounded tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                    />
                  </div>

                  <div className="tw-w-full xl:tw-w-1/2">
                    <label className="tw-mb-2.5 tw-block tw-text-black dark:tw-text-white">
                      Last name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your last name"
                      className="tw-w-full tw-rounded tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                    />
                  </div>
                </div>

                <div className="tw-mb-4.5">
                  <label className="tw-mb-2.5 tw-block tw-text-black dark:tw-text-white">
                    Email <span className="tw-text-meta-1">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    className="tw-w-full tw-rounded tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                  />
                </div>

                <SelectGroupOne />

                <div className="tw-mb-6">
                  <label className="tw-mb-2.5 tw-block tw-text-black dark:tw-text-white">
                    Message
                  </label>
                  <textarea
                    rows={6}
                    placeholder="Type your message"
                    className="tw-w-full tw-rounded tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                  ></textarea>
                </div>

                <button className="tw-flex tw-w-full tw-justify-center tw-rounded tw-bg-primary tw-p-3 tw-font-medium tw-text-gray hover:tw-bg-opacity-90">
                  Send Message
                </button>
              </div>
            </form>
          </div>

          {/* <!-- Sign Up Form --> */}
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-6.5 dark:tw-border-strokedark">
              <h3 className="tw-font-medium tw-text-black dark:tw-text-white">
                Sign Up Form
              </h3>
            </div>
            <form action="#">
              <div className="tw-p-6.5">
                <div className="tw-mb-4.5">
                  <label className="tw-mb-2.5 tw-block tw-text-black dark:tw-text-white">
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    className="tw-w-full tw-rounded tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                  />
                </div>

                <div className="tw-mb-4.5">
                  <label className="tw-mb-2.5 tw-block tw-text-black dark:tw-text-white">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    className="tw-w-full tw-rounded tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                  />
                </div>

                <div className="tw-mb-4.5">
                  <label className="tw-mb-2.5 tw-block tw-text-black dark:tw-text-white">
                    Password
                  </label>
                  <input
                    type="password"
                    placeholder="Enter password"
                    className="tw-w-full tw-rounded tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                  />
                </div>

                <div className="tw-mb-5.5">
                  <label className="tw-mb-2.5 tw-block tw-text-black dark:tw-text-white">
                    Re-type Password
                  </label>
                  <input
                    type="password"
                    placeholder="Re-enter password"
                    className="tw-w-full tw-rounded tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-text-black tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary disabled:tw-cursor-default disabled:tw-bg-whiter dark:tw-border-form-strokedark dark:tw-bg-form-input dark:tw-text-white dark:focus:tw-border-primary"
                  />
                </div>

                <button className="tw-flex tw-w-full tw-justify-center tw-rounded tw-bg-primary tw-p-3 tw-font-medium tw-text-gray hover:tw-bg-opacity-90">
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormLayout;
