import React, {useState} from "react";

import Sidebar from "./Cart/Sidebar";

import Banner from "./banner/Banner";
import BannerBottom from "./banner/BannerBottom";
import BestSellers from "./bestSellers/BestSellers";
import NewArrivals from "./newArriwals/NewArriwals";
import Sale from "./sale/Sale";
import SpecialOffers from "./specialOffers/SpecialOffers";
import YearProduct from "./yearProduct/YearProduct";
import AnimatedCTA from "./AnimatedCTA";

import Navigationbar from "../Navbar/Navbar";

import PricingPlansCardContainer from "./PricingPlansCardContainer";
import PricingPage from "./pricing/PricingPage";


import ServicePage from "./Services/ServicePage";

import { useNavigate } from "react-router-dom";

const ShopHome = () => {

  const navigate = useNavigate(); // Initialize the navigate function

  // Define the handleCTAClick function
  const handleCTAClick = () => {
    navigate('/plans'); // Navigate to the /plans route
  };


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (

    
    <>
    {/* <Navigationbar /> */}
    <Navigationbar toggleSidebar={toggleSidebar}/>
    <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
    <div className="tw-w-full tw-mx-auto">
      <Banner />
      <BannerBottom />
      <div className="tw-max-w-container tw-mx-auto tw-px-4">

        {/* <NewArrivals /> */}
        <BestSellers />
        {/* <YearProduct /> */}
        {/* <SpecialOffers /> */}
        <AnimatedCTA
            title="Unlock the Power of Data"
            description="Transform your business decisions with our comprehensive European company database. Start your journey to data-driven success today!"
            buttonText="Check out plans in detail"
            onButtonClick={handleCTAClick}
        />
        {/* <PricingPlansCardContainer /> */}
        <PricingPage />
      </div>
      <ServicePage />
    </div>
    </>
  );
};

export default ShopHome;