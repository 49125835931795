import React, { useState } from 'react';

const SwitcherTwo = () => {
  const [enabled, setEnabled] = useState(false);

  return (
    <div x-data="{ switcherToggle: false }">
      <label
        htmlFor="toggle2"
        className="tw-flex tw-cursor-pointer tw-select-none tw-items-center"
      >
        <div className="tw-relative">
          <input
            id="toggle2"
            type="checkbox"
            className="tw-sr-only"
            onChange={() => {
              setEnabled(!enabled);
            }}
          />
          <div className="tw-h-5 tw-w-14 tw-rounded-full tw-bg-meta-9 tw-shadow-inner dark:tw-bg-[#5A616B]"></div>
          <div
            className={`tw-dot tw-absolute tw-left-0 -tw-top-1 tw-h-7 tw-w-7 tw-rounded-full tw-bg-white tw-shadow-switch-1 tw-transition ${
              enabled && '!tw-right-0 !tw-translate-x-full !tw-bg-primary dark:!tw-bg-white'
            }`}
          ></div>
        </div>
      </label>
    </div>
  );
};

export default SwitcherTwo;
