import React from 'react';
import { Database, MonitorSmartphone, BarChart3, Search, Lightbulb, TrendingUp, BarChartHorizontal, MonitorCheck, Cog, Users } from 'lucide-react';

import BenefitItem from '../BenefitItem';
import ServiceCard from '../ServiceCard';

const ServicePage = () => {
  const services = [
    {
      title: 'Kseco API',
      description: 'Integrate our B2B company database into any software for unified and accurate data. Benefit from automating data updates, enriching CRM data, and streamlining data management.',
      icon: Database,
      buttonText: 'Discover Kseco API'
    },
    {
      title: 'Kseco Screener',
      description: 'Screen through our entire database of European companies using advanced filtering and sorting options. Enhance your B2B lead generation, market research, and competitor analysis efforts.',
      icon: MonitorSmartphone,
      buttonText: 'Explore Kseco Screener'
    },
    {
      title: 'Kseco Smart Charts',
      description: 'Generate detailed reports using our database with real-time visualisations and analytics, making data insights accessible. Simplify your data visualisation, performance reporting, and business analysis processes.',
      icon: BarChart3,
      buttonText: 'Create with Smart Charts'
    },
    {
      title: 'Kseco Product Scanner',
      description: 'Identify and target companies based on their product portfolio using advanced keyword searches and filtering options. An ideal tool for market scanning, keyword analysis, and competitive intelligence.',
      icon: Search,
      buttonText: 'Start Product Scanning'
    }
  ];

  const benefits = [
    {
      title: 'Enhanced Decision-Making',
      description: 'Access accurate data on over 80M European companies for well-informed decisions based on detailed insights.',
      icon: Lightbulb
    },
    {
      title: 'Data-Driven Competitive Advantage',
      description: 'Gain a competitive edge by analysing market trends and identifying competitors with our powerful tools.',
      icon: TrendingUp
    },
    {
      title: 'Optimised Marketing and Sales Strategies',
      description: 'Identify leads and market opportunities with advanced filtering and sorting, boosting your marketing and sales efforts.',
      icon: BarChartHorizontal
    },
    {
      title: 'User-Friendly Interface',
      description: 'Minimise the learning curve and reduce manual tasks with our easy-to-use interface and automated data updates.',
      icon: MonitorCheck
    },
    {
      title: 'Streamlined Operations',
      description: 'Seamlessly integrate our data into your CRM or business software, keeping your systems up-to-date.',
      icon: Cog
    },
    {
      title: 'Seamless Collaboration',
      description: 'Enhance collaboration with a unified data format and language, ensuring consistency and accuracy.',
      icon: Users
    }
  ];

  return (
    <div className="tw-min-h-screen tw-bg-gray-100">
      <header className="tw-bg-white tw-py-8">
        <div className="tw-container tw-mx-auto tw-px-4">
          <h1 className="tw-text-4xl tw-font-bold tw-text-center tw-mb-2">Get Access to the</h1>
          <h2 className="tw-text-3xl tw-font-semibold tw-text-center tw-mb-4">Database of 80M+ Companies</h2>
          <p className="tw-text-xl tw-text-center tw-text-gray-600">See how our aggregated company data can boost your company's growth.</p>
        </div>
      </header>

      <main className="tw-container tw-mx-auto tw-px-4 tw-py-12">
        <section className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 tw-mb-16">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </section>

        <section className="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-8 tw-mb-16">
          <h3 className="tw-text-2xl tw-font-semibold tw-text-center tw-mb-8">Benefits For Your Organisation</h3>
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8">
            {benefits.map((benefit, index) => (
              <BenefitItem key={index} {...benefit} />
            ))}
          </div>
        </section>

        <section className="tw-bg-gray-50 tw-rounded-md tw-shadow-md tw-p-6 tw-mx-auto tw-max-w-2xl tw-space-y-4">
  <h3 className="tw-text-xl tw-font-semibold tw-text-center tw-text-gray-800">
    Leave your email below or contact us at 
    <a href="mailto:sales@kseco.com" className="tw-text-[#366196] tw-underline tw-ml-1 hover:tw-text-[#2b4e78]">sales@kseco.com</a>
  </h3>
  <p className="tw-text-center tw-text-base tw-text-gray-600">
    for a demo and a quote based on your data needs.
  </p>
  
  <form className="tw-w-full tw-mx-auto">
    <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-mb-4">
      <input 
        type="email" 
        placeholder="Your email" 
        className="tw-w-full sm:tw-w-2/3 tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-[#366196] focus:tw-border-[#366196] tw-transition"
      />
      <button 
        type="submit" 
        className="tw-bg-[#366196] tw-text-white tw-w-full sm:tw-w-auto tw-px-5 tw-py-2 tw-ml-0 sm:tw-ml-2 tw-rounded-md hover:tw-bg-[#2b4e78] tw-transition-colors"
      >
        Get a Quote
      </button>
    </div>
    
    <div className="tw-flex tw-items-center tw-text-sm tw-text-gray-600">
      <input 
        type="checkbox" 
        id="privacy-policy" 
        className="tw-h-4 tw-w-4 tw-text-[#366196] tw-border-gray-300 tw-rounded focus:tw-ring-1 focus:tw-ring-[#366196] tw-transition tw-mr-2" 
      />
      <label htmlFor="privacy-policy" className="tw-flex-grow">
        I have read and agree to the 
        <a href="#" className="tw-text-[#366196] tw-underline hover:tw-text-[#2b4e78]"> Privacy Policy</a>
      </label>
    </div>
  </form>
</section>


      </main>
    </div>
  );
};

export default ServicePage;
