import React, { useState } from 'react';
import styles from './pricing.module.css';
import { IconCheck, IconX } from '@tabler/icons-react'; // Import Tabler icons

// Pricing data
const frequencies = [
  { id: '1', value: '1', label: 'Monthly', priceSuffix: '/month' },
  { id: '2', value: '2', label: 'Annually', priceSuffix: '/year' },
  { id: '3', value: '3', label: 'Weekly', priceSuffix: '/week' },
];

const tiers = [
  {
    name: 'Free',
    id: '0',
    href: '/subscribe',
    price: { '1': '$0', '2': '$0', '3': '' },
    discountPrice: { '1': '', '2': '', '3': '' },
    description: `Get all goodies for free, no credit card required.`,
    features: [
      `Minimal companies data`,
      `Last 10 companies showcase`,
      `Last 10 companies from cantons`,
      ``,
    ],
    featured: false,
    highlighted: false,
    soldOut: false,
    cta: `Sign up`,
  },
  {
    name: 'Pro',
    id: '1',
    href: '/subscribe',
    price: { '1': '$3.99', '2': '$49.99', '3': '' },
    discountPrice: { '1': '', '2': '', '3': '' },
    description: `When you grow, need more power and flexibility.`,
    features: [
      `All in the free plan plus`,
      `Customizable data`,
      `Professional company API`,
      `Integration with third-party apps`,
    ],
    featured: false,
    highlighted: true,
    soldOut: false,
    cta: `Get started`,
  },
  {
    name: 'Scaler',
    id: '2',
    href: '/contact-us',
    price: { '1': '$14.99', '2': '$179.88', '3': '' },
    discountPrice: { '1': '', '2': '', '3': '' },
    description: `When you grow, need more power and flexibility.`,
    features: [
      `All in the pro plan plus`,
      `Priority support`,
      `Enterprise-grade security`,
      `Database integration`,
      `All in-one API`,
    ],
    featured: true,
    highlighted: false,
    soldOut: false,
    cta: `Get started`,
  },
];

// Utility function for classnames
const cn = (...args) => args.filter(Boolean).join(' ');

export default function PricingPage() {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const bannerText = 'Save 25% with the Scaler plan and Join us today';

  return (
    <div className={cn('tw-flex tw-flex-col tw-w-full tw-items-center', styles.fancyOverlay)}>
      <div className="tw-w-full tw-flex tw-flex-col tw-items-center">
        <div className="tw-mx-auto tw-max-w-7xl tw-px-6 lg:tw-px-8 tw-flex tw-flex-col tw-items-center">
          <div className="tw-w-full lg:tw-w-auto tw-mx-auto tw-max-w-4xl lg:tw-text-center">
            <h1 className="tw-text-black dark:tw-text-white tw-text-4xl tw-font-semibold tw-max-w-xs sm:tw-max-w-none md:tw-text-6xl !tw-leading-tight">
              Pricing
            </h1>

            <p className="tw-text-black dark:tw-text-white tw-mt-6 md:tw-text-xl lg:tw-text-center tw-max-w-prose">
              KSECO-CH provides companies data, database, API scaling, and much more, so join us to leverage your business flexibility.
            </p>
          </div>

          {bannerText && (
            <div className="tw-w-full lg:tw-w-auto tw-flex tw-justify-center tw-my-4">
              <p className="tw-w-full tw-px-4 tw-py-3 tw-text-xs tw-bg-purple-100 tw-text-black dark:tw-bg-purple-300/30 dark:tw-text-white/80 tw-rounded-xl">
                {bannerText}
              </p>
            </div>
          )}

          {frequencies.length > 1 && (
            <div className="tw-mt-16 tw-flex tw-justify-center">
              <div
                role="radiogroup"
                className="tw-grid tw-gap-x-1 tw-rounded-full tw-p-1 tw-text-center tw-text-xs tw-font-semibold tw-leading-5 tw-bg-white dark:tw-bg-black tw-ring-1 tw-ring-inset tw-ring-gray-200/30 dark:tw-ring-gray-800"
                style={{
                  gridTemplateColumns: `repeat(${frequencies.length}, minmax(0, 1fr))`,
                }}
              >
                <p className="tw-sr-only">Payment frequency</p>
                {frequencies.map((option) => (
                  <label
                    className={cn(
                      frequency.value === option.value
                        ? 'tw-bg-purple-500/90 tw-text-white dark:tw-bg-purple-900/70 dark:tw-text-white/70'
                        : 'tw-bg-transparent tw-text-gray-500 hover:tw-bg-purple-500/10',
                      'tw-cursor-pointer tw-rounded-full tw-px-2.5 tw-py-2 tw-transition-all',
                    )}
                    key={option.value}
                    htmlFor={option.value}
                  >
                    {option.label}

                    <button
                      value={option.value}
                      id={option.value}
                      className="tw-hidden"
                      role="radio"
                      aria-checked={frequency.value === option.value}
                      onClick={() => {
                        setFrequency(frequencies.find((f) => f.value === option.value));
                      }}
                    >
                      {option.label}
                    </button>
                  </label>
                ))}
              </div>
            </div>
          )}

          <div
            className={cn(
              'tw-isolate tw-mx-auto tw-mt-4 tw-mb-28 tw-grid tw-max-w-md tw-grid-cols-1 tw-gap-8 lg:tw-mx-0 lg:tw-max-w-none tw-select-none',
              tiers.length === 2 ? 'lg:tw-grid-cols-2' : '',
              tiers.length === 3 ? 'lg:tw-grid-cols-3' : '',
            )}
          >
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className={cn(
                  tier.featured
                    ? '!tw-bg-gray-900 tw-ring-gray-900 dark:!tw-bg-gray-100 dark:tw-ring-gray-100'
                    : 'tw-bg-white dark:tw-bg-gray-900/80 tw-ring-gray-300/70 dark:tw-ring-gray-700',
                  'tw-max-w-xs tw-ring-1 tw-rounded-3xl tw-p-8 xl:tw-p-10',
                  tier.highlighted ? styles.fancyGlassContrast : '',
                )}
              >
                <h3
                  id={tier.id}
                  className={cn(
                    tier.featured ? 'tw-text-white dark:tw-text-black' : 'tw-text-black dark:tw-text-white',
                    'tw-text-2xl tw-font-bold tw-tracking-tight',
                  )}
                >
                  {tier.name}
                </h3>
                <p
                  className={cn(
                    tier.featured
                      ? 'tw-text-gray-300 dark:tw-text-gray-500'
                      : 'tw-text-gray-600 dark:tw-text-gray-400',
                    'tw-mt-4 tw-text-sm tw-leading-6',
                  )}
                >
                  {tier.description}
                </p>
                <p className="tw-mt-6 tw-flex tw-items-baseline tw-gap-x-1">
                  <span
                    className={cn(
                      tier.featured ? 'tw-text-white dark:tw-text-black' : 'tw-text-black dark:tw-text-white',
                      'tw-text-4xl tw-font-bold tw-tracking-tight',
                      tier.discountPrice && tier.discountPrice[frequency.value] ? 'tw-line-through' : '',
                    )}
                  >
                    {typeof tier.price === 'string' ? tier.price : tier.price[frequency.value]}
                  </span>

                  <span
                    className={cn(
                      tier.featured ? 'tw-text-white dark:tw-text-black' : 'tw-text-black dark:tw-text-white',
                    )}
                  >
                    {typeof tier.discountPrice === 'string'
                      ? tier.discountPrice
                      : tier.discountPrice[frequency.value]}
                  </span>

                  {typeof tier.price !== 'string' && (
                    <span
                      className={cn(
                        tier.featured ? 'tw-text-gray-300 dark:tw-text-gray-500' : 'dark:tw-text-gray-400 tw-text-gray-600',
                        'tw-text-sm tw-font-semibold tw-leading-6',
                      )}
                    >
                      {frequency.priceSuffix}
                    </span>
                  )}
                </p>
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className={cn('tw-flex tw-mt-6 tw-shadow-sm', tier.soldOut ? 'tw-pointer-events-none' : '')}
                >
                  <button
                    disabled={tier.soldOut}
                    className={cn(
                      'tw-w-full tw-inline-flex tw-items-center tw-justify-center tw-font-medium tw-ring-offset-background hover:tw-opacity-80 tw-transition-all tw-focus-visible:tw-outline-none tw-focus-visible:tw-ring-2 tw-focus-visible:tw-ring-ring tw-focus-visible:tw-ring-offset-2 tw-disabled:tw-pointer-events-none tw-disabled:tw-opacity-50 tw-text-black dark:tw-text-white tw-h-12 tw-rounded-md tw-px-6 sm:tw-px-10 tw-text-md',
                      tier.featured || tier.soldOut ? 'tw-grayscale' : '',
                      !tier.highlighted && !tier.featured
                        ? 'tw-bg-gray-100 dark:tw-bg-gray-600 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-gray-800'
                        : 'tw-bg-purple-300/70 tw-text-purple-foreground hover:tw-bg-purple-400/70 dark:tw-bg-purple-700 dark:hover:tw-bg-purple-800/90',
                      tier.featured ? '!tw-bg-gray-100 dark:!tw-bg-black' : '',
                    )}
                  >
                    {tier.soldOut ? 'Sold out' : tier.cta}
                  </button>
                </a>

                <ul
                  className={cn(
                    tier.featured
                      ? 'tw-text-gray-300 dark:tw-text-gray-500'
                      : 'tw-text-gray-700 dark:tw-text-gray-400',
                    'tw-mt-8 tw-space-y-3 tw-text-sm tw-leading-6 xl:tw-mt-10',
                  )}
                >
                  {tier.features.map((feature, index) => (
                    <li key={index} className="tw-flex tw-gap-x-3">
                      {feature ? (
                        <IconCheck
                          className={cn(
                            tier.highlighted ? 'tw-text-purple-500' : 'tw-text-gray-500',
                            tier.featured ? 'tw-text-white dark:tw-text-black' : '',
                            'tw-h-6 tw-w-5 tw-flex-none',
                          )}
                          aria-hidden="true"
                        />
                      ) : (
                        <IconX
                          className={cn(
                            tier.highlighted ? 'tw-text-purple-500' : 'tw-text-gray-500',
                            'tw-h-6 tw-w-5 tw-flex-none',
                          )}
                          aria-hidden="true"
                        />
                      )}
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
