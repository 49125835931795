import { Product } from '../../types/product.ts';
import ProductOne from '../../images/product/product-01.png';
import ProductTwo from '../../images/product/product-02.png';
import ProductThree from '../../images/product/product-03.png';
import ProductFour from '../../images/product/product-04.png';
import React from 'react';
const productData: Product[] = [
  {
    image: ProductOne,
    name: 'Apple Watch Series 7',
    category: 'Electronics',
    price: 296,
    sold: 22,
    profit: 45,
  },
  {
    image: ProductTwo,
    name: 'Macbook Pro M1',
    category: 'Electronics',
    price: 546,
    sold: 12,
    profit: 125,
  },
  {
    image: ProductThree,
    name: 'Dell Inspiron 15',
    category: 'Electronics',
    price: 443,
    sold: 64,
    profit: 247,
  },
  {
    image: ProductFour,
    name: 'HP Probook 450',
    category: 'Electronics',
    price: 499,
    sold: 72,
    profit: 103,
  },
];

const TableTwo = () => {
  return (
    <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
      <div className="tw-py-6 tw-px-4 md:tw-px-6 xl:tw-px-7.5">
        <h4 className="tw-text-xl tw-font-semibold tw-text-black dark:tw-text-white">
          Top Products
        </h4>
      </div>

      <div className="tw-grid tw-grid-cols-6 tw-border-t tw-border-stroke tw-py-4.5 tw-px-4 dark:tw-border-strokedark sm:tw-grid-cols-8 md:tw-px-6 2xl:tw-px-7.5">
        <div className="tw-col-span-3 tw-flex tw-items-center">
          <p className="tw-font-medium">Product Name</p>
        </div>
        <div className="tw-col-span-2 tw-hidden tw-items-center sm:tw-flex">
          <p className="tw-font-medium">Category</p>
        </div>
        <div className="tw-col-span-1 tw-flex tw-items-center">
          <p className="tw-font-medium">Price</p>
        </div>
        <div className="tw-col-span-1 tw-flex tw-items-center">
          <p className="tw-font-medium">Sold</p>
        </div>
        <div className="tw-col-span-1 tw-flex tw-items-center">
          <p className="tw-font-medium">Profit</p>
        </div>
      </div>

      {productData.map((product, key) => (
        <div
          className="tw-grid tw-grid-cols-6 tw-border-t tw-border-stroke tw-py-4.5 tw-px-4 dark:tw-border-strokedark sm:tw-grid-cols-8 md:tw-px-6 2xl:tw-px-7.5"
          key={key}
        >
          <div className="tw-col-span-3 tw-flex tw-items-center">
            <div className="tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row sm:tw-items-center">
              <div className="tw-h-12.5 tw-w-15 tw-rounded-md">
                <img src={product.image} alt="Product" />
              </div>
              <p className="tw-text-sm tw-text-black dark:tw-text-white">
                {product.name}
              </p>
            </div>
          </div>
          <div className="tw-col-span-2 tw-hidden tw-items-center sm:tw-flex">
            <p className="tw-text-sm tw-text-black dark:tw-text-white">
              {product.category}
            </p>
          </div>
          <div className="tw-col-span-1 tw-flex tw-items-center">
            <p className="tw-text-sm tw-text-black dark:tw-text-white">
              ${product.price}
            </p>
          </div>
          <div className="tw-col-span-1 tw-flex tw-items-center">
            <p className="tw-text-sm tw-text-black dark:tw-text-white">{product.sold}</p>
          </div>
          <div className="tw-col-span-1 tw-flex tw-items-center">
            <p className="tw-text-sm tw-text-meta-3">${product.profit}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableTwo;
