import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { STATISTICS_API } from '../../api/api';

const ApexChart = () => {
  const [companyCounts, setCompanyCounts] = useState({ active: 0, bankrupt: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyCounts = async () => {
      try {
        const token = localStorage.getItem('TOKEN');
        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        const response = await axios.get(STATISTICS_API.getActiveBankruptCompanies, { headers });
        const { active_companies_count, bankrupt_companies_count } = response.data.data;
        
        setCompanyCounts({ 
          active: active_companies_count || 0, 
          bankrupt: bankrupt_companies_count || 0 
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchCompanyCounts();
  }, []);

  const { active, bankrupt } = companyCounts;

  const series = [active, bankrupt];

  const options = {
    labels: ['Active Companies', 'Bankrupt Companies'],
    theme: {
      monochrome: {
        enabled: true
      }
    },
    fill: {
      colors: ['#6895cc', '#366094']
    },
    noData: { text: "Empty Data" },
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toLocaleString();
        }
      }
    }
  };

  return (
    <Chart
      type="pie"
      width={500}
      height={450}
      series={loading ? [] : series}
      options={options}
    />
  );
};

export default ApexChart;
