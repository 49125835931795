import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './css/satoshi.css';
import './css/style.css';
import 'jsvectormap/dist/jsvectormap.css';
import 'flatpickr/dist/flatpickr.min.css';
import App from './App.tsx';

const EcommerceDashboardApp: React.FC = () => {
  return <App />;
};

export default EcommerceDashboardApp;