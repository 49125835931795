import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu, X, LayoutDashboard, Calendar, User, FileText, Table, Settings, PieChart, Layers, LogOut } from 'lucide-react';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const location = useLocation();
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

    useEffect(() => {
    console.log("Sidebar Component - Open:", sidebarOpen);
  }, [sidebarOpen]);

  // Close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // Close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const SidebarLink = ({ to, icon: Icon, children }: { to: string; icon: any; children: React.ReactNode }) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `tw-flex tw-items-center tw-gap-3 tw-px-4 tw-py-3 tw-text-sm tw-font-medium tw-rounded-lg tw-transition-all ${
          isActive
            ? 'tw-text-white tw-bg-boxdark'
            : 'tw-text-bodydark2 hover:tw-text-white hover:tw-bg-boxdark-2'
        }`
      }
    >
      <Icon className="tw-w-5 tw-h-5" />
      {children}
    </NavLink>
  );

  return (
    <aside
      ref={sidebar}
      className={`tw-fixed tw-inset-y-0 tw-left-0 tw-z-50 tw-w-56 tw-bg-boxdark tw-transition-transform tw-duration-300 tw-ease-in-out ${
        sidebarOpen ? 'tw-translate-x-0' : '-tw-translate-x-full'
      } lg:tw-translate-x-0`}
    >
      <div className="tw-flex tw-flex-col tw-h-full">
        {/* Sidebar Header */}
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-px-6 tw-py-5.5 lg:tw-py-6.5">
          <NavLink to="/admin">
            <span className="tw-text-xl tw-font-bold tw-text-white">Dashboard</span>
          </NavLink>

          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            className="tw-block lg:tw-hidden tw-text-white hover:tw-text-opacity-80 tw-transition-opacity"
          >
            {sidebarOpen ? <X className="tw-w-6 tw-h-6" /> : <Menu className="tw-w-6 tw-h-6" />}
          </button>
        </div>

        {/* Navigation */}
        <nav className="tw-flex-1 tw-px-4 tw-pb-4 tw-space-y-2 tw-overflow-y-auto">
          <div className="tw-mb-4 tw-pt-4">
            <h3 className="tw-mb-4 tw-ml-4 tw-text-sm tw-font-medium tw-text-bodydark2">MENU</h3>
            <SidebarLink to="/admin" icon={LayoutDashboard}>Dashboard</SidebarLink>
            <SidebarLink to="/admin/calendar" icon={Calendar}>Calendar</SidebarLink>
            <SidebarLink to="/admin/profile" icon={User}>Profile</SidebarLink>
            <SidebarLink to="/admin/forms" icon={FileText}>Forms</SidebarLink>
            <SidebarLink to="/admin/tables" icon={Table}>Tables</SidebarLink>
          </div>

          <div className="tw-mb-4">
            <h3 className="tw-mb-4 tw-ml-4 tw-text-sm tw-font-medium tw-text-bodydark2">TOOLS</h3>
            <SidebarLink to="/admin/settings" icon={Settings}>Settings</SidebarLink>
            <SidebarLink to="/admin/charts" icon={PieChart}>Charts</SidebarLink>
            <SidebarLink to="/admin/ui-elements" icon={Layers}>UI Elements</SidebarLink>
          </div>
        </nav>

        {/* Footer */}
        <div className="tw-px-4 tw-pb-4 tw-mt-auto">
          <button
            className="tw-flex tw-items-center tw-gap-3 tw-w-full tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-text-bodydark2 tw-rounded-lg hover:tw-text-white hover:tw-bg-boxdark-2 tw-transition-colors"
          >
            <LogOut className="tw-w-5 tw-h-5" />
            Sign Out
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;