import React, { useEffect, useState } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { useLocation } from "react-router-dom";

const Breadcrumbs = ({ prevLocation, title }) => {
  const location = useLocation();
  const [locationPath, setLocationPath] = useState("");

  useEffect(() => {
    setLocationPath(location.pathname.split("/")[1]);
  }, [location]);

  return (
    <div className="tw-w-full tw-py-10 xl:tw-py-10 tw-flex tw-flex-col tw-gap-3">
      <h1 className="tw-text-5xl tw-text-primeColor tw-font-titleFont tw-font-bold">
        {title}
      </h1>
      <p className="tw-text-sm tw-font-normal tw-text-lightText tw-capitalize tw-flex tw-items-center">
        <span>{prevLocation === "" ? "Home" : prevLocation}</span>

        <span className="tw-px-1">
          <HiOutlineChevronRight />
        </span>
        <span className="tw-capitalize tw-font-semibold tw-text-primeColor">
          {locationPath}
        </span>
      </p>
    </div>
  );
};

export default Breadcrumbs;
