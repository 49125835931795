import React from "react";

const Badge = ({ text }) => {
  return (
    <div className="tw-bg-primeColor tw-w-[92px] tw-h-[35px] tw-text-white tw-flex tw-justify-center tw-items-center tw-text-base tw-font-semibold hover:tw-bg-black tw-duration-300 tw-cursor-pointer">
      {text}
    </div>
  );
};

export default Badge;
