import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { 
  Calendar,
  BookOpen,
  Users,
  Building2,
  FileText,
  Wallet,
  CalendarRange,
  DollarSign,
  MapPin,
  Briefcase,
  Flag,
  Layers,
  Search,
  MapPinned,
  type LucideIcon
} from 'lucide-react';
import { format as formatDate } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { FilterContainer } from './filterComponents/filter-container.tsx';
import { SearchInput } from './filterComponents/search-input.tsx';
import { RangeInputs } from './filterComponents/range-inputs.tsx';


// Map of filter keys to their corresponding Lucide icons
const filterIcons: Record<string, LucideIcon> = {
  publication: BookOpen,
  gender: Users,
  municipality: Building2,
  officialPublication: FileText,
  capital: Wallet,
  publicationDate: Calendar,
  publicationDateRange: CalendarRange,
  shareValue: DollarSign,
  canton: MapPin,
  companyType: Briefcase,
  nationality: Flag,
  purposes: Layers,
  services: Search,
  city: MapPinned,
};

// Update the date picker styles to be more minimalistic
const customDatePickerStyles = `
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 99999 !important;
    position: fixed !important;
    transform: scale(0.8) !important;
    transform-origin: top left;
  }
  .react-datepicker {
    font-family: inherit;
    font-size: 0.75rem !important;
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .react-datepicker__month-container {
    width: 200px;
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom: 1px solid #e2e8f0;
    padding: 0.25rem;
  }
  .react-datepicker__current-month {
    color: #1f2937;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.25rem 0;
  }
  .react-datepicker__day-name {
    color: #6b7280;
    font-size: 0.65rem;
    width: 1.7rem;
    line-height: 1.7rem;
    margin: 0.1rem;
  }
  .react-datepicker__day {
    color: #1f2937;
    font-size: 0.65rem;
    width: 1.7rem;
    line-height: 1.7rem;
    margin: 0.1rem;
    border-radius: 0.15rem;
  }
  .react-datepicker__day:hover {
    background-color: #f3f4f6;
  }
  .react-datepicker__day--selected {
    background-color: #406fab;
    color: white;
    font-weight: normal;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: rgba(64, 111, 171, 0.1);
    color: #406fab;
  }
  .react-datepicker__input-container input {
    width: 100%;
    padding: 0.375rem 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    color: #1f2937;
  }
  .react-datepicker__input-container input:focus {
    outline: none;
    border-color: #406fab;
    box-shadow: 0 0 0 1px #406fab;
  }
  .react-datepicker__navigation {
    top: 0.35rem;
  }
  .react-datepicker__navigation-icon::before {
    border-width: 1px 1px 0 0;
    height: 6px;
    width: 6px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__today-button {
    background: #f3f4f6;
    border-top: 1px solid #e2e8f0;
    padding: 0.25rem;
    font-size: 0.65rem;
    color: #406fab;
  }
`;

export function ModernFilterList({
  title,
  list,
  textKey,
  valueKey,
  value,
  filterKey,
  onChange,
  icon: CustomIcon,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleCheckboxChange = (item) => {
    // Special handling for publication filter to prevent multiple requests
    if (filterKey === "publication") {
      // Only trigger onChange if the value is actually changing
      const currentValue = internalValue || "";
      const newValue = currentValue === item[valueKey] ? "" : item[valueKey];
      
      console.log('Publication checkbox change:', {
        currentValue,
        newValue,
        item
      });
      
      // Update internal state first
      setInternalValue(newValue);
      
      // Then notify parent
      if (currentValue !== newValue) {
        onChange(filterKey, newValue);
      }
    } else {
      // For nationality filter, use the ID
      const valueToUse = filterKey === "nationality" ? item.id : item[valueKey];
      
      const newValue = (internalValue || []).includes(valueToUse)
        ? internalValue.filter((v) => v !== valueToUse)
        : [...(internalValue || []), valueToUse];
      
      // Update internal state first
      setInternalValue(newValue);
      
      // Then notify parent
      onChange(filterKey, newValue);
    }
  };

  const IconComponent = CustomIcon || filterIcons[filterKey];

  const filteredList = useMemo(() => {
    if (!searchTerm) return list;
    return list?.filter((item) =>
      (item[textKey] || item).toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [list, searchTerm, textKey]);

  // Helper function to check if an item is selected
  const isItemSelected = useCallback((item) => {
    if (!internalValue) return false;
    
    if (filterKey === "publication") {
      return internalValue === item[valueKey];
    }
    
    // For nationality, check against item.id
    const valueToCheck = filterKey === "nationality" ? item.id : item[valueKey];
    return Array.isArray(internalValue) && internalValue.includes(valueToCheck);
  }, [internalValue, filterKey, valueKey]);

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-space-y-2">
        <SearchInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
        />
        <div className="tw-max-h-48 tw-overflow-y-auto tw-space-y-1">
          {filteredList?.map((item, index) => (
            <label
              key={index}
              className="tw-flex tw-items-center tw-space-x-2 tw-px-1 tw-py-0.5 tw-text-sm tw-text-gray-600 hover:tw-bg-gray-50 tw-rounded tw-cursor-pointer"
            >
              <input
                type="checkbox"
                className="tw-rounded tw-border-gray-300 tw-text-[#406fab] focus:tw-ring-[#406fab]"
                checked={isItemSelected(item)}
                onChange={() => handleCheckboxChange(item)}
              />
              <span className="tw-truncate">{item[textKey] || item}</span>
            </label>
          ))}
        </div>
      </div>
    </FilterContainer>
  );
}

export function ModernRangeFilter({
  title,
  value,
  filterKey,
  onChange,
  min,
  max,
  step = 1000
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [localMin, setLocalMin] = useState(value?.min || min);
  const [localMax, setLocalMax] = useState(value?.max || max);

  const handleApply = () => {
    onChange(filterKey, {
      min: localMin,
      max: localMax
    });
  };

  const handleClearFilter = () => {
    setLocalMin(min);
    setLocalMax(max);
    onChange(filterKey, null); // Set filter to null to completely remove it
  };

  const IconComponent = filterIcons[filterKey] || Wallet;

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-space-y-4">
        <RangeInputs
          minValue={localMin}
          maxValue={localMax}
          onMinChange={setLocalMin}
          onMaxChange={setLocalMax}
          min={min}
          max={max}
          step={step}
        />
        <div className="tw-space-y-2">
          <button
            onClick={handleApply}
            className="tw-w-full tw-rounded-md tw-bg-[#406fab] tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white hover:tw-bg-[#406fab]/90 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-[#406fab]/20 tw-focus:ring-offset-2"
          >
            Apply Range
          </button>
          <button
            onClick={handleClearFilter}
            className="tw-w-full tw-rounded-md tw-border tw-border-red-500 tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-red-500 hover:tw-bg-red-50 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-red-500/20 tw-focus:ring-offset-2"
          >
            Clear {title} Filter
          </button>
        </div>
      </div>
    </FilterContainer>
  );
}

export function ModernDateFilter({
  title,
  value,
  filterKey,
  onChange,
  icon: CustomIcon,
}) {
  const [isOpen, setIsOpen] = useState(false);
  
  // Get the appropriate icon from our map, or use the custom icon if provided
  const IconComponent = CustomIcon || filterIcons[filterKey] || Calendar;

  // Convert string date to Date object if needed
  const parseDate = (dateValue) => {
    if (!dateValue) return null;
    if (dateValue instanceof Date) return dateValue;
    if (typeof dateValue === 'string') {
      const parsedDate = new Date(dateValue);
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    return null;
  };

  const handleDateChange = (date) => {
    onChange(filterKey, date);
  };

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-relative">
        <DatePicker
          selected={parseDate(value)}
          onChange={handleDateChange}
          className="tw-w-full tw-rounded-md tw-border tw-border-[#406fab] tw-bg-transparent tw-px-3 tw-py-2 tw-text-sm tw-ring-offset-background tw-placeholder:text-[#697382] tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-[#406fab]/20 tw-focus:ring-offset-2"
          placeholderText="Select date..."
          dateFormat="dd/MM/yyyy"
          isClearable
          showYearDropdown
          scrollableYearDropdown
        />
        <Calendar className="tw-absolute tw-right-3 tw-top-1/2 tw-h-4 tw-w-4 tw--translate-y-1/2 tw-text-[#697382]" />
      </div>
    </FilterContainer>
  );
}

export function ModernMunicipalityFilter({
  title,
  value,
  filterKey,
  onChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const IconComponent = filterIcons[filterKey] || Building2;

  const handleSearchChange = (e) => {
    onChange(filterKey, e.target.value);
  };

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-relative">
        <Search className="tw-absolute tw-left-3 tw-top-1/2 tw-h-4 tw-w-4 tw--translate-y-1/2 tw-text-muted-foreground" />
        <input
          type="text"
          placeholder="Search municipality..."
          value={value || ""}
          onChange={handleSearchChange}
          className="tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-background tw-px-9 tw-py-2 tw-text-sm tw-ring-offset-background tw-placeholder:text-muted-foreground tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-ring tw-focus:ring-offset-2"
        />
      </div>
    </FilterContainer>
  );
}

export function ModernOfficialPublicationFilter({
  title,
  value,
  filterKey,
  onChange
}) {
  const [isOpen, setIsOpen] = useState(false);
  const IconComponent = filterIcons[filterKey] || FileText;

  const publicationOptions = [
    { id: 'SHAB', name: 'SHAB' },
    { id: 'FUSC', name: 'FUSC' },
    { id: 'FOSC', name: 'FOSC' }
  ];

  const handleCheckboxChange = (publicationId) => {
    const currentValue = value || [];
    const newValue = currentValue.includes(publicationId)
      ? currentValue.filter(id => id !== publicationId)
      : [...currentValue, publicationId];
    console.log('Changing official publication filter:', newValue);
    onChange(filterKey, newValue);
  };

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-space-y-2">
        {publicationOptions.map((option) => (
          <label
            key={option.id}
            className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-p-2 tw-rounded hover:tw-bg-gray-100"
          >
            <input
              type="checkbox"
              checked={value?.includes(option.id)}
              onChange={() => handleCheckboxChange(option.id)}
              className="tw-rounded tw-border-gray-300 tw-text-[#406fab] focus:tw-ring-[#406fab]"
            />
            <span className="tw-text-sm tw-text-gray-700">{option.name}</span>
          </label>
        ))}
      </div>
    </FilterContainer>
  );
}

export function ModernPublicationFilter({
  title,
  value,
  filterKey,
  onChange
}) {
  const [isOpen, setIsOpen] = useState(false);
  const IconComponent = filterIcons[filterKey] || BookOpen;

  const publicationOptions = [
    { id: 'All Publications', name: 'All Publications' },
    { id: 'All Albanian Publications', name: 'All Albanian Publications' },
    { id: 'Today Publications', name: 'Today Publications' }
  ];

  const handleCheckboxChange = (selectedValue) => {
    // If the same value is selected, clear it
    if (value === selectedValue) {
      onChange(filterKey, '');
    } else {
      onChange(filterKey, selectedValue);
    }
  };

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-space-y-2">
        {publicationOptions.map((option) => (
          <label
            key={option.id}
            className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-p-2 tw-rounded hover:tw-bg-gray-100"
          >
            <input
              type="checkbox"
              checked={value === option.id}
              onChange={() => handleCheckboxChange(option.id)}
              className="tw-rounded tw-border-gray-300 tw-text-[#406fab] focus:tw-ring-[#406fab]"
            />
            <span className="tw-text-sm tw-text-gray-700">{option.name}</span>
          </label>
        ))}
      </div>
    </FilterContainer>
  );
}

export function ModernPublicationDateFilter({
  title,
  value,
  filterKey,
  onChange,
  icon: CustomIcon,
}) {
  const [isOpen, setIsOpen] = useState(false);
  
  const IconComponent = CustomIcon || filterIcons[filterKey] || Calendar;

  const publicationDateOptions = [
    { id: 'today', label: 'Today' },
    { id: 'yesterday', label: 'Yesterday' },
    { id: 'lastWeek', label: 'Last Week' },
    { id: 'lastYear', label: 'Last Year' }
  ];

  const handleOptionChange = (optionId) => {
    onChange(filterKey, value === optionId ? undefined : optionId);
  };

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-space-y-2">
        {publicationDateOptions.map((option) => (
          <div key={option.id} className="tw-flex tw-items-center">
            <input
              type="checkbox"
              id={`publication-date-${option.id}`}
              checked={value === option.id}
              onChange={() => handleOptionChange(option.id)}
              className="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600"
            />
            <label
              htmlFor={`publication-date-${option.id}`}
              className="tw-ml-2 tw-text-sm tw-text-gray-700"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </FilterContainer>
  );
}

export function ModernPublicationRangeFilter({
  title,
  value,
  filterKey = 'publicationDateRange',
  onChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [localValue, setLocalValue] = useState(value || { from: null, to: null });
  const IconComponent = filterIcons[filterKey] || CalendarRange;

  // Update local value when parent value changes
  useEffect(() => {
    setLocalValue(value || { from: null, to: null });
  }, [value]);

  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.textContent = customDatePickerStyles;
    document.head.appendChild(styleTag);
    return () => styleTag.remove();
  }, []);

  const parseDate = (dateValue) => {
    if (!dateValue) return null;
    if (dateValue instanceof Date) return dateValue;
    if (typeof dateValue === 'string') {
      const parsedDate = new Date(dateValue);
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    return null;
  };

  const handleStartDateChange = (date) => {
    setLocalValue(prev => ({
      ...prev,
      from: date
    }));
  };

  const handleEndDateChange = (date) => {
    setLocalValue(prev => ({
      ...prev,
      to: date
    }));
  };


  //the first version chage for the date publication change

  // const handleApplyFilter = () => {
  //   // Only send the filter if we have at least one date
  //   if (localValue.from || localValue.to) {
  //     onChange(filterKey, localValue);
  //   } else {
  //     handleClearFilter();
  //   }
  // };

  const handleApplyFilter = () => {
    if (localValue.from || localValue.to) {
      const formattedValue = {
        from: localValue.from ? formatDate(localValue.from, 'yyyy-MM-dd') : null,
        to: localValue.to ? formatDate(localValue.to, 'yyyy-MM-dd') : null
      };
      onChange(filterKey, formattedValue);
    } else {
      handleClearFilter();
    }
  };

  const handleClearFilter = () => {
    setLocalValue({ from: null, to: null });
    onChange(filterKey, null);
  };

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-space-y-4">
        <div className="date-picker-wrapper">
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
            From Date
          </label>
          <DatePicker
            selected={parseDate(localValue?.from)}
            onChange={handleStartDateChange}
            dateFormat="yyyy-MM-dd"
            isClearable
            placeholderText="Select start date"
            maxDate={parseDate(localValue?.to) || new Date()}
            popperPlacement="auto"
            popperModifiers={[
              {
                name: "preventOverflow",
                options: {
                  boundariesElement: "viewport"
                }
              },
              {
                name: "offset",
                options: {
                  offset: [0, 8]
                }
              }
            ]}
          />
        </div>
        
        <div className="date-picker-wrapper">
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
            To Date
          </label>
          <DatePicker
            selected={parseDate(localValue?.to)}
            onChange={handleEndDateChange}
            dateFormat="yyyy-MM-dd"
            isClearable
            placeholderText="Select end date"
            minDate={parseDate(localValue?.from)}
            maxDate={new Date()}
            popperPlacement="auto"
            popperModifiers={[
              {
                name: "preventOverflow",
                options: {
                  boundariesElement: "viewport"
                }
              },
              {
                name: "offset",
                options: {
                  offset: [0, 8]
                }
              }
            ]}
          />
        </div>

        <div className="tw-space-y-2">
          <button
            onClick={handleApplyFilter}
            className="tw-w-full tw-rounded-md tw-bg-[#406fab] tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white hover:tw-bg-[#406fab]/90 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-[#406fab]/20 tw-focus:ring-offset-2"
          >
            Apply Filter
          </button>
          <button
            onClick={handleClearFilter}
            className="tw-w-full tw-rounded-md tw-border tw-border-red-500 tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-red-500 hover:tw-bg-red-50 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-red-500/20 tw-focus:ring-offset-2"
          >
            Clear Date Range
          </button>
        </div>
      </div>
    </FilterContainer>
  );
}

export function ModernServiceFilter({
  title,
  value,
  filterKey,
  onChange,
  icon: CustomIcon,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value || "");

  useEffect(() => {
    setSearchTerm(value || "");
  }, [value]);

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    onChange(filterKey, newValue);
  };

  const IconComponent = CustomIcon || filterIcons[filterKey] || Search;

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-space-y-2">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search services..."
          className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-[#406fab] focus:tw-border-[#406fab] tw-text-sm"
        />
      </div>
    </FilterContainer>
  );
}

export function ModernCityFilter({
  title,
  value,
  filterKey,
  onChange,
  icon: CustomIcon,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value || "");

  useEffect(() => {
    setSearchTerm(value || "");
  }, [value]);

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    onChange(filterKey, newValue);
  };

  const IconComponent = CustomIcon || filterIcons[filterKey] || MapPinned;

  return (
    <FilterContainer
      title={title}
      icon={<IconComponent className="tw-h-4 tw-w-4 tw-text-[#406fab]" />}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <div className="tw-space-y-2">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search city..."
          className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-[#406fab] focus:tw-border-[#406fab] tw-text-sm"
        />
      </div>
    </FilterContainer>
  );
}