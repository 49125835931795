import React, { useState } from 'react';

const SelectGroupOne: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);

  const changeTextColor = () => {
    setIsOptionSelected(true);
  };

  return (
    <div className="tw-mb-4.5">
      <label className="tw-mb-2.5 tw-block tw-text-black dark:tw-text-white">
        {' '}
        Subject{' '}
      </label>

      <div className="tw-relative tw-z-20 tw-bg-transparent dark:tw-bg-form-input">
        <select
          value={selectedOption}
          onChange={(e) => {
            setSelectedOption(e.target.value);
            changeTextColor();
          }}
          className={`tw-relative tw-z-20 tw-w-full tw-appearance-none tw-rounded tw-border tw-border-stroke tw-bg-transparent tw-py-3 tw-px-5 tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary dark:tw-border-form-strokedark dark:tw-bg-form-input dark:focus:tw-border-primary ${
            isOptionSelected ? 'tw-text-black dark:tw-text-white' : ''
          }`}
        >
          <option value="" disabled className="tw-text-body dark:tw-text-bodydark">
            Select your subject
          </option>
          <option value="USA" className="tw-text-body dark:tw-text-bodydark">
            USA
          </option>
          <option value="UK" className="tw-text-body dark:tw-text-bodydark">
            UK
          </option>
          <option value="Canada" className="tw-text-body dark:tw-text-bodydark">
            Canada
          </option>
        </select>

        <span className="tw-absolute tw-top-1/2 tw-right-4 tw-z-30 -tw-translate-y-1/2">
          <svg
            className="tw-fill-current"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.8">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                fill=""
              ></path>
            </g>
          </svg>
        </span>
      </div>
    </div>
  );
};

export default SelectGroupOne;
