import { Link } from 'react-router-dom';
import DropdownMessage from './DropdownMessage.tsx';
import DropdownNotification from './DropdownNotification.tsx';
import DropdownUser from './DropdownUser.tsx';
import LogoIcon from '../../images/logo/logo-icon.svg';
import DarkModeSwitcher from './DarkModeSwitcher.tsx';

import React from 'react';
const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  return (
    <header className="tw-sticky tw-top-0 tw-z-999 tw-flex tw-w-full tw-bg-white tw-drop-shadow-1 dark:tw-bg-boxdark dark:tw-drop-shadow-none">
      <div className="tw-flex tw-flex-grow tw-items-center tw-justify-between tw-px-4 tw-py-4 tw-shadow-2 md:tw-px-6 2xl:tw-px-11">
        <div className="tw-flex tw-items-center tw-gap-2 sm:tw-gap-4 lg:tw-hidden">
          {/* <!-- Hamburger Toggle BTN --> */}
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
            className="tw-z-99999 tw-block tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-p-1.5 tw-shadow-sm dark:tw-border-strokedark dark:tw-bg-boxdark lg:tw-hidden"
          >
            <span className="tw-relative tw-block tw-h-5.5 tw-w-5.5 tw-cursor-pointer">
              <span className="tw-du-block tw-absolute tw-right-0 tw-h-full tw-w-full">
                <span
                  className={`tw-relative tw-left-0 tw-top-0 tw-my-1 tw-block tw-h-0.5 tw-w-0 tw-rounded-sm tw-bg-black tw-delay-[0] tw-duration-200 tw-ease-in-out dark:tw-bg-white ${
                    !props.sidebarOpen && '!tw-w-full tw-delay-300'
                  }`}
                ></span>
                <span
                  className={`tw-relative tw-left-0 tw-top-0 tw-my-1 tw-block tw-h-0.5 tw-w-0 tw-rounded-sm tw-bg-black tw-delay-150 tw-duration-200 tw-ease-in-out dark:tw-bg-white ${
                    !props.sidebarOpen && 'tw-delay-400 !tw-w-full'
                  }`}
                ></span>
                <span
                  className={`tw-relative tw-left-0 tw-top-0 tw-my-1 tw-block tw-h-0.5 tw-w-0 tw-rounded-sm tw-bg-black tw-delay-200 tw-duration-200 tw-ease-in-out dark:tw-bg-white ${
                    !props.sidebarOpen && '!tw-w-full tw-delay-500'
                  }`}
                ></span>
              </span>
              <span className="tw-absolute tw-right-0 tw-h-full tw-w-full tw-rotate-45">
                <span
                  className={`tw-absolute tw-left-2.5 tw-top-0 tw-block tw-h-full tw-w-0.5 tw-rounded-sm tw-bg-black tw-delay-300 tw-duration-200 tw-ease-in-out dark:tw-bg-white ${
                    !props.sidebarOpen && '!tw-h-0 !tw-delay-[0]'
                  }`}
                ></span>
                <span
                  className={`tw-delay-400 tw-absolute tw-left-0 tw-top-2.5 tw-block tw-h-0.5 tw-w-full tw-rounded-sm tw-bg-black tw-duration-200 tw-ease-in-out dark:tw-bg-white ${
                    !props.sidebarOpen && '!tw-h-0 !tw-delay-200'
                  }`}
                ></span>
              </span>
            </span>
          </button>
          {/* <!-- Hamburger Toggle BTN --> */}

          <Link className="tw-block tw-flex-shrink-0 lg:tw-hidden" to="/">
            <img src={LogoIcon} alt="Logo" />
          </Link>
        </div>

        <div className="tw-hidden sm:tw-block">
          <form action="https://formbold.com/s/unique_form_id" method="POST">
            <div className="tw-relative">
              <button className="tw-absolute tw-left-0 tw-top-1/2 -tw-translate-y-1/2">
                <svg
                  className="tw-fill-body hover:tw-fill-primary dark:tw-fill-bodydark dark:hover:tw-fill-primary"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
                    fill=""
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
                    fill=""
                  />
                </svg>
              </button>

              <input
                type="text"
                placeholder="Type to search..."
                className="tw-w-full tw-bg-transparent tw-pl-9 tw-pr-4 tw-text-black focus:tw-outline-none dark:tw-text-white xl:tw-w-125"
              />
            </div>
          </form>
        </div>

        <div className="tw-flex tw-items-center tw-gap-3 2xsm:tw-gap-7">
          <ul className="tw-flex tw-items-center tw-gap-2 2xsm:tw-gap-4">
            {/* <!-- Dark Mode Toggler --> */}
            {/* <DarkModeSwitcher /> */}
            {/* <!-- Dark Mode Toggler --> */}

            {/* <!-- Notification Menu Area --> */}
            <DropdownNotification />
            {/* <!-- Notification Menu Area --> */}

            {/* <!-- Chat Notification Area --> */}
            {/* <DropdownMessage /> */}
            {/* <!-- Chat Notification Area --> */}
          </ul>

          {/* <!-- User Area --> */}
          <DropdownUser />
          {/* <!-- User Area --> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
