import React from 'react';
import { Trash2 } from 'lucide-react';

const SidebarCartItem = ({ item }) => {
  return (
    <div className="tw-flex tw-py-6 tw-border-b tw-border-gray-200">
      <div className="tw-h-24 tw-w-24 tw-flex-shrink-0 tw-overflow-hidden tw-rounded-md tw-border tw-border-gray-200">
        <img
          src={item.image}
          alt={item.name}
          className="tw-h-full tw-w-full tw-object-cover tw-object-center"
        />
      </div>
      <div className="tw-ml-4 tw-flex tw-flex-1 tw-flex-col">
        <div>
          <div className="tw-flex tw-justify-between tw-text-base tw-font-medium tw-text-gray-900">
            <h3>{item.name}</h3>
            <p className="tw-ml-4">${item.price.toFixed(2)}</p>
          </div>
        </div>
        <div className="tw-flex tw-flex-1 tw-items-end tw-justify-between tw-text-sm">
          <p className="tw-text-gray-500">Qty 1</p>
          <div className="tw-flex">
            <button
              type="button"
              className="tw-font-medium tw-text-indigo-600 hover:tw-text-indigo-500"
            >
              <Trash2 className="tw-h-5 tw-w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarCartItem;
