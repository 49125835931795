import { User, Mail, MapPin } from 'lucide-react';
import React from 'react';

interface ProfileHeaderProps {
  username: string;
  email: string;
  location: string;
}

export function ProfileHeader({ username, email, location }: ProfileHeaderProps) {
  return (
    <div className="tw-bg-white tw-rounded-2xl tw-p-6 tw-shadow-card">
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 md:tw-flex-row md:tw-gap-6">
        <div className="tw-relative">
          <div className="tw-w-24 tw-h-24 tw-rounded-full tw-bg-gray tw-flex tw-items-center tw-justify-center">
            <User className="tw-w-12 tw-h-12 tw-text-primary" />
          </div>
          <span className="tw-absolute tw-bottom-0 tw-right-0 tw-w-4.5 tw-h-4.5 tw-bg-meta-3 tw-rounded-full tw-border-2 tw-border-white"></span>
        </div>
        
        <div className="tw-flex tw-flex-col tw-items-center md:tw-items-start">
          <h3 className="tw-text-2xl tw-font-semibold tw-text-black">{username}</h3>
          <div className="tw-flex tw-flex-col tw-gap-2 tw-mt-2 tw-items-center md:tw-items-start">
            <div className="tw-flex tw-items-center tw-gap-2">
              <Mail className="tw-w-4.5 tw-h-4.5 tw-text-body" />
              <p className="tw-text-body">{email}</p>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2">
              <MapPin className="tw-w-4.5 tw-h-4.5 tw-text-body" />
              <p className="tw-text-body">{location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}