import React, { useState } from "react";

const SectorFilter = ({ onFilterChange, sectors }) => {
    const [selectedSectorIds, setSelectedSectorIds] = useState([]);
  
    const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      const sectorId = parseInt(value);
      let updatedSelectedSectorIds;
  
      if (checked) {
        updatedSelectedSectorIds = [...selectedSectorIds, sectorId];
      } else {
        updatedSelectedSectorIds = selectedSectorIds.filter(id => id !== sectorId);
      }
  
      setSelectedSectorIds(updatedSelectedSectorIds);
      // Change from 'sectors' to 'purposes' to match the working API
      onFilterChange('purposes', updatedSelectedSectorIds);
    };
  
    return (
      <li className="dropright sidebar-item nh">
        <a href="#" className="dropdown-toggle sidebar-title" data-toggle="dropdown">
          <i className="bi bi-flag cit"></i> Sector
        </a>
        <div className="dropdown-menu">
          <ul className="list-unstyled">
            {sectors && sectors.map(sector => (
              <li className="drop" key={sector.id || sector.name}>
                <label>
                  <input
                    type="checkbox"
                    value={sector.id}
                    onChange={handleCheckboxChange}
                    checked={selectedSectorIds.includes(parseInt(sector.id))}
                  />
                  {" " + sector.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </li>
    );
  };
  
export default SectorFilter;