import React from 'react';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../../utlity/cn.ts';

interface FilterContainerProps {
  title: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

export function FilterContainer({
  title,
  icon,
  children,
  isOpen,
  onToggle,
}: FilterContainerProps) {
  return (
    <div className="tw-mb-4">
      <button
        onClick={onToggle}
        className="tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-lg 
        tw-bg-white tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-font-medium 
        tw-text-[#406fab] hover:tw-bg-[#406fab]/5"
      >
        <div className="tw-flex tw-items-center tw-gap-2">
          {icon}
          <span>{title}</span>
        </div>
        <ChevronDown
          className={cn(
            "tw-h-4 tw-w-4 tw-text-[#697382] tw-transition-transform tw-duration-300",
            isOpen && "tw-rotate-180"
          )}
        />
      </button>
      <div
        className={cn(
          "tw-transform tw-overflow-hidden tw-transition-all tw-duration-300 tw-ease-in-out",
          isOpen ? "tw-max-h-96" : "tw-max-h-0"
        )}
      >
        <div className="tw-px-4 tw-py-3 tw-bg-[#f8f9fa] tw-rounded-b-lg">
          {children}
        </div>
      </div>
    </div>
  );
}