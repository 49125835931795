import React , { useState, useEffect,  useCallback }from 'react';
import styles from './CompanyAdvancedSearch.module.css';
import "./theme-default.css";
import "./test.css";
import "./flaticon.css";
import "./responsive.css";
import "../Charts/home.css";
import Navbar from "../Navbar/Navbar";
import axios from 'axios';

import { FaBuilding, FaEye } from 'react-icons/fa';

import SectorFilter from './SectorFilter';

import LegalFormFilter from './LegalFormFilter';
import CantonFilter from './CantonFilter';

import Spinner from 'react-bootstrap/Spinner';


import CustomMultiSelectCheckbox from './CustomMultiselectCheckbox';

import { debounce } from 'lodash';

import Multiselect from 'react-multiselect-checkboxes';

import NationalityFilter from './NationalityFilter';

import { CANTON_API, COMPANY_API, SECTOR_API, COMPANY_TYPE_API, NATIONALITY_API } from '../../api/api';


const staticLegalForms = [
  "Einzelunternehmen", "Kollektivgesellschaft", "Aktiengesellschaft", "Genossenschaft",
  "Gesellschaft mit beschränkter Haftung", "Verein", "Stiftung", "Istitut des öffentlichen Rechts",
  "Zweigniederlassung", "Kommanditgesellschaft", "Zweigniederlassung auslänische Gesellschaft"
];


const CompanyAdvancedFilter = () => {
  const [filter, setFilter] = useState({
      canton: [],
      companyType: [],
      nationality: [],
      purposes: [],
      services: [],
      sectors: [],
      publication: "All Publications",
      city: "",
      date: "",
  });

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [purposes, setPurposes] = useState([]);

  const [cantons, setCantons] = useState([]);

  const [isFormVisible, setIsFormVisible] = useState(true); // To control form visibility
  const [isDebouncing, setIsDebouncing] = useState(false); // To control spinner visibility during debounce

  
  useEffect(() => {
    getCantons();
  }, []);
 

  const getPurposes = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get("https://api.kseco.eu/api/sectors", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPurposes(response.data.data || []);
    } catch (error) {
      console.error("Error fetching purposes data:", error);
    }
  };

  useEffect(() => {
    getPurposes();
  }, []);


  const getCantons = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get(CANTON_API.getAllCantons, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCantons(response.data.data);
    } catch (error) {
      console.error("Error fetching cantons:", error);
    }
  };
  const handleFilterChange = (key, value) => {
    // If `companyType` is the key, ensure it is always an array
    if (key === 'companyType') {
        value = Array.isArray(value) ? value : [value];
    }
    
    setFilter(prevFilter => ({
        ...prevFilter,
        [key]: value,
    }));
};

const onCheckBoxClicked = (key, value) => {
  setFilter(prevFilter => ({
      ...prevFilter,
      [key]: value,
  }));
};

const handleSubmit = (event) => {
  event.preventDefault();

  setIsDebouncing(true);

  applyFilters();
};

const applyFilters = debounce(() => {

  setIsDebouncing(false); 
  setIsFormVisible(false);

  setFilter(prevFilter => ({
    ...prevFilter,
    searchTerm, // Ensure searchTerm is part of the filter
  }));
  getCompanies(); // Trigger the API call
}, 1000); // Adjust debounce timing as needed


const handleCitySearchChange = (event) => {
  const cityValue = event.target.value;
  
  setFilter((prevFilter) => ({
    ...prevFilter,
    city: cityValue,
  }));
  

  applyFilters();
};

const getCompanies = async () => {
  setLoading(true);
  try {
      const token = localStorage.getItem("TOKEN");
      const endpoint = COMPANY_API.getAllCompanies;

      let params = {
          ...filter,
          publication: filter.publication === "All Albanian Publications" ? "albanian" :
                       filter.publication === "Today Publications" ? "today" : "all",
      };

    //   if (searchTerm) {
    //     params[searchTerm.startsWith('CHE') || /^\d{3}\.\d{3}\.\d{3}$/.test(searchTerm) ? 'che' : 'name'] = searchTerm;
    // }
      
    if (filter.city) {
      params.city = filter.city;
    }

      if (filter.date) {
          params.date = filter.date;
      } else {
          delete params.date;
      }

      if (filter.purposes && filter.purposes.length > 0) {
          params.purposes = filter.purposes.join(',');
      } else {
          delete params.purposes;
      }

      // Ensure `companyType` is always an array
      if (filter.companyType && Array.isArray(filter.companyType) && filter.companyType.length > 0) {
        params.companyType = filter.companyType;
      } else {
          delete params.companyType;
      }

      if (filter.searchTerm && filter.searchTerm.trim() !== '') {
        params.name = filter.searchTerm; // Assuming the API expects `name`
      }

      console.log(filter)
      const response = await axios.get(endpoint, {
          params,
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });

      console.log("API Response:", response.data.data); // Debugging log
      setCompanies(response.data.data);
  } catch (error) {
      console.error("Error fetching companies:", error);
      setCompanies([]);
  } finally {
      setLoading(false);
  }
};


  useEffect(() => {
      getCompanies();
  }, [filter]);

  return (
    <div>
    <Navbar />
    <div className={styles.advancedSearchWrapper}>
        {/* Header Section */}
        <div className={styles.headerSection}>
            <h2 className={styles.heading}>Advanced Search</h2>
            <p className={styles.note}>
                Please note that each click requires a short loading time to update the results.
            </p>
        </div>


        {isFormVisible ? ( 


        <div className={styles.advancedSearchContainer}>
            <form className={styles.searchForm} onSubmit={handleSubmit}>
                <div className={`${styles.formGroup} ${styles.fullWidth}`}>
                    <label className={styles.label}>Name Search</label>
                    <input
                        type="text"
                        className={styles.input}
                        placeholder="Business name or UID"
                        // onChange={(event) => setSearchTerm(event.target.value)}
                        onChange={(event) => setSearchTerm(event.target.value)} 
                        // onChange={(event) => {
                        //   setSearchTerm(event.target.value); 
                        //   applyFilters(); 
                        // }}
                    />
                    <p className={styles.smallNote}>Enter only one letter to search for that exact first letter or enter more than one letter or whole words to search for that combination in the name.</p>
                </div>

                {/* Status */}
                <div className={styles.formGroup}>
                    <label className={styles.label}>Status</label>
                    <div className={styles.checkboxGroup}>
                        <label className={styles.checkboxLabel}>
                            <input type="checkbox" checked /> active
                        </label>
                        <label className={styles.checkboxLabel}>
                            <input type="checkbox" /> in liquidation
                        </label>
                        <label className={styles.checkboxLabel}>
                            <input type="checkbox" /> deleted
                        </label>
                    </div>
                </div>

                {/* Legal Form */}
                <div className={styles.formGroup}>
                    <label className={styles.label}>Legal form</label>
                    <LegalFormFilter onFilterChange={handleFilterChange} />
                </div>
                
                {/* Sector */}
                <div className={styles.formGroup}>
                    <label className={styles.label}>Sector</label>
                    {/* <CustomMultiSelectCheckbox
                        options={sector.map(sectorItem => ({ value: sectorItem.id, label: sectorItem.name }))}
                        selectedOptions={selectedSectors}
                        onChange={handleSectorChange}
                    /> */}
                    <SectorFilter onFilterChange={handleFilterChange} sectors={purposes} />

                </div>

                {/* Nationality */}
                <div className={styles.formGroup}>
                    <label className={styles.label}>Nationality</label>
                    <NationalityFilter onFilterChange={onCheckBoxClicked} />
                </div>

                {/* With Entries for Jobs */}
                <div className={styles.formGroup}>
                    <label className={styles.label}>With entries for companies</label>
                    <select className={styles.select}>
                        <option>COMPANIES</option>
                    </select>
                </div>

                {/* Canton */}
                <div className={styles.formGroup}>
                    <label className={styles.label}>Canton</label>

                    <CantonFilter
                          cantons={cantons}  
                          onFilterChange={onCheckBoxClicked} 
                          value={filter.canton}  
                    />
                </div>

                {/* Postal Code */}
                <div className={styles.formGroup}>
                    <label className={styles.label}>Postal code</label>
                    <input type="text" className={styles.input} placeholder="8000, 60*" />
                </div>

                {/* City */}
                <div className={styles.formGroup}>
                    <label className={styles.label}>City</label>
                    <input 
                      type="text" 
                      className={styles.input} 
                      placeholder="Zürich, Lugano" 
                      value={filter.city} // Bind the input value to the city filter
                      // onChange={(event) => handleCitySearchChange(event)}
                      onChange={(event) => handleFilterChange('city', event.target.value)}
                    />
                    <p className={styles.smallNote}>(Use original language (Zürich, Genève))</p>
                </div>

                {/* Submit Button */}
                <div className={`${styles.formGroup} ${styles.fullWidth}`}>
                    <div className={styles.flexContainer}>
                        <p className={styles.resultsCount}>{companies.length} Results</p>
                        <div className={styles.buttonSpinnerContainer}>

                           {isDebouncing && <Spinner animation="border" variant="primary" />}


                            <button className={styles.buttonSubmit} type="submit">
                                Search
                                <svg className={styles.icon} viewBox="0 0 24 24" fill="currentColor">
                                    <path
                                        fillRule="evenodd"
                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        ) : (

          <div>
  <div className={styles.resultsContainer}>
    <h3>Last 3 Results</h3>
    {companies.slice(-3).map((company) => (
      <div className={styles.companyCard} key={company.id}>
        <div className={styles.companyIcon}>
          <FaBuilding /> {/* Icon Added */}
        </div>
        <div className={styles.companyInfo}>
          <div className={styles.companyName}>{company.companyName}</div>
          <div className={styles.companyAddress}>{company.companyType}</div>
        </div>
        <div className={styles.companyActions}>
          <button className={styles.viewNetworkBtn}>
            <FaEye className={styles.iconEye} /> View network
          </button>
          <button className={styles.followBtn}>+ Follow</button>
        </div>
      </div>
    ))}
  </div>

  <div className={styles.premiumContainer}>
    <h3>Unlock all search results now</h3>
    <p className={styles.paragraphPremium}>
      This is a preview of the search results. As a Premium member, you can use the advanced search without limit and search for companies in more detail using the available filters.
    </p>
    <button className={styles.premiumButton}>Get Premium membership</button>
  </div>
</div>



        )}
    </div>
    <footer className="section bg-footer foot">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Information
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <a href="">Pages</a>
                  </li>
                  <li>
                    <a href="">Our Team</a>
                  </li>
                  <li>
                    <a href="">Feuchers</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Ressources
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <a href="">Monitoring Grader </a>
                  </li>
                  <li>
                    <a href="">Video Tutorial</a>
                  </li>
                  <li>
                    <a href="">Term &amp; Service</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Help
                </h6>
                <ul className="list-unstyled footer-link mt-4">
                  <li>
                    <a href="">Sign Up </a>
                  </li>
                  <li>
                    <a href="">Login</a>
                  </li>
                  <li>
                    <a href="">Terms of Services</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white">
                  Contact Us
                </h6>
                <p className="contact-info mt-4">
                  Contact us if need help withanything
                </p>
                <p className="contact-info">+01 123-456-7890</p>
                <div className="mt-5">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="#">
                        <i className="fab facebook footer-social-icon bi bi-facebook"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <i className="fab twitter footer-social-icon bi bi-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <i className="fab google footer-social-icon bi bi-instagram"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <i className="fab facebook footer-social-icon bi bi-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-5">
          <p className="footer-alt mb-0 f-14">
            2022 © KSECO.All Rights Reserved
          </p>
        </div>
      </footer>
</div>
  );
};

export default CompanyAdvancedFilter;

