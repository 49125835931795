import React, { useState } from 'react';

const CheckboxFour = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <div>
      <label
        htmlFor="checkboxLabelFour"
        className="tw-flex tw-cursor-pointer tw-select-none tw-items-center"
      >
        <div className="tw-relative">
          <input
            type="checkbox"
            id="checkboxLabelFour"
            className="tw-sr-only"
            onChange={() => {
              setIsChecked(!isChecked);
            }}
          />
          <div
            className={`tw-mr-4 tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-border ${
              isChecked && 'tw-border-primary'
            }`}
          >
            <span
              className={`tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-transparent ${
                isChecked && '!tw-bg-primary'
              }`}
            >
              {' '}
            </span>
          </div>
        </div>
        Checkbox Text
      </label>
    </div>
  );
};

export default CheckboxFour;
