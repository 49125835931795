import React from 'react';
import { Search } from 'lucide-react';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export function SearchInput({ value, onChange, placeholder = "Search..." }: SearchInputProps) {
  return (
    <div className="tw-group tw-relative">
      <Search className="tw-absolute tw-left-3 tw-top-1/2 tw-h-4 tw-w-4 tw--translate-y-1/2 tw-text-bodydark2 tw-transition-colors group-hover:tw-text-primary" />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="tw-w-full tw-rounded-md tw-border tw-border-stroke tw-bg-transparent tw-px-9 tw-py-2 tw-text-sm tw-text-bodydark 
        tw-transition-all tw-duration-300
        placeholder:tw-text-bodydark2
        focus:tw-border-primary focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-primary/30
        hover:tw-border-primary/50"
      />
    </div>
  );
}