import React from 'react';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Dashboard from './components/Dashboard/Dashboard';
import Register from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import Details from './components/Details/details';
import Home from './components/Charts/login';
import BarChart from './components/Charts/Barchart';
import Login from './components/Login/Login';
import Test from './components/Home/home';
import Registration from './components/Register/registration';
import Pricing from './components/Pricing/pricing';
import CompanyAdvancedSearch from './components/Home/CompanyAdvancedSearch';
import StripeWrapper from './components/StripeElementsWrapper';
import { NotificationProvider } from './components/Details/NotificationContext';
import EcommerceDashboardApp from './components/EcommerceDashboard/EcommerceDashboardApp.tsx';
import NotificationManager from './components/Home/NotificationManager';

import ShopHome from './components/Shop/ShopHome';
import Cart from './components/Shop/Cart/Cart';
import PaymentSuccess from './components/PaymentSuccess';


function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <NotificationProvider>
        <Router>
          <Routes>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/Dashboard" element={<Dashboard />} />
              <Route path="/login" element={<Home />} />
            </Route>
            <Route path="/register" element={<Register />} />
            <Route path="/details/:id" element={<Details />} />
            <Route path="/" element={<Login />} />
            <Route path="/Barchart" element={<BarChart />} />
            <Route path="/home" element={<Test />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/company-advanced-search" element={<CompanyAdvancedSearch />} />
            <Route path="/shop" element={<ShopHome />} />
            <Route path="/shop/cart" element={<Cart />} />
            <Route path="/checkout" element={<StripeWrapper />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />


            <Route path="/admin/*" element={<EcommerceDashboardApp />} />

            
          </Routes>
          <NotificationManager />
        </Router>
      </NotificationProvider>
    </I18nextProvider>
  );
}
export default App;
