import React from 'react';
import { X } from 'lucide-react';
import SidebarCartItem from './SidebarCartItem';

import { useNavigate } from 'react-router-dom';


const dummyItems = [
  { id: 1, name: 'Product 1', price: 19.99, image: 'https://source.unsplash.com/random/100x100?product' },
  { id: 2, name: 'Product 2', price: 29.99, image: 'https://source.unsplash.com/random/100x100?item' },
  { id: 3, name: 'Product 3', price: 39.99, image: 'https://source.unsplash.com/random/100x100?goods' },
];

const Sidebar = ({ isOpen, onClose }) => {


    const navigate = useNavigate();
    
    const handleCheckoutClick = () => {
      navigate('/checkout');
    };

    const handleViewCartClick = () => {
        navigate('/shop/cart');
    };

  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <div 
          className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-40"
          onClick={onClose}
        ></div>
      )}
      {/* Sidebar */}
      <div
        className={`tw-fixed tw-inset-y-0 tw-right-0 tw-w-full md:tw-w-1/3 tw-bg-white tw-shadow-xl tw-transform ${
          isOpen ? 'tw-translate-x-0' : 'tw-translate-x-full'
        } tw-transition-transform tw-duration-300 tw-ease-in-out tw-z-50`}
      >
        <div className="tw-h-full tw-flex tw-flex-col">
          <div className="tw-px-4 tw-py-6 tw-bg-gray-50 tw-border-b">
            <div className="tw-flex tw-items-center tw-justify-between">
              <h2 className="tw-text-lg tw-font-medium tw-text-gray-900">Shopping Bag (3)</h2>
              <button
                onClick={onClose}
                className="tw-text-gray-400 tw-hover:text-gray-500"
              >
                <X className="tw-h-6 tw-w-6" />
              </button>
            </div>
          </div>
          <div className="tw-flex-1 tw-overflow-y-auto tw-py-6 tw-px-4">
            {dummyItems.map((item) => (
              <SidebarCartItem key={item.id} item={item} />
            ))}
          </div>
          <div className="tw-border-t tw-border-gray-200 tw-py-6 tw-px-4">
            <div className="tw-flex tw-justify-between tw-text-base tw-font-medium tw-text-gray-900">
              <p>Subtotal</p>
              <p>$89.97</p>
            </div>
            <p className="tw-mt-0.5 tw-text-sm tw-text-gray-500">Shipping and taxes calculated at checkout.</p>
            <div className="tw-mt-6">
              <div className='tw-flex tw-flex-col tw-space-y-4'>
                <button 
                    className="tw-w-full tw-bg-indigo-600 tw-border tw-border-transparent tw-rounded-md tw-py-3 tw-px-8 tw-flex tw-items-center tw-justify-center tw-text-base tw-font-medium tw-text-white tw-hover:bg-indigo-700 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-offset-2 tw-focus:ring-indigo-500"
                    onClick={handleViewCartClick}
                >
                    View Cart
                </button>
                <button
                   onClick={handleCheckoutClick} 
                   className="tw-w-full tw-bg-[#366196] tw-border tw-border-transparent tw-rounded-md tw-py-3 tw-px-8 tw-flex tw-items-center tw-justify-center tw-text-base tw-font-medium tw-text-white tw-hover:bg-[#2b4e78] tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-offset-2 tw-focus:ring-indigo-500">
                    
                    Checkout
                </button>
              </div>
            </div>
            <div className="tw-mt-6 tw-flex tw-justify-center tw-text-sm tw-text-center tw-text-gray-500">
              <p>
                or{' '}
                <button
                  type="button"
                  className="tw-text-indigo-600 tw-font-medium tw-hover:text-indigo-500"
                  onClick={onClose}
                >
                  Continue Shopping<span aria-hidden="true"> &rarr;</span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
