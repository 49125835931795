import { BRAND } from '../../types/brand.ts';
import BrandOne from '../../images/brand/brand-01.svg';
import BrandTwo from '../../images/brand/brand-02.svg';
import BrandThree from '../../images/brand/brand-03.svg';
import BrandFour from '../../images/brand/brand-04.svg';
import BrandFive from '../../images/brand/brand-05.svg';

import React from 'react';
const brandData: BRAND[] = [
  {
    logo: BrandOne,
    name: 'Google',
    visitors: 3.5,
    revenues: '5,768',
    sales: 590,
    conversion: 4.8,
  },
  {
    logo: BrandTwo,
    name: 'Twitter',
    visitors: 2.2,
    revenues: '4,635',
    sales: 467,
    conversion: 4.3,
  },
  {
    logo: BrandThree,
    name: 'Github',
    visitors: 2.1,
    revenues: '4,290',
    sales: 420,
    conversion: 3.7,
  },
  {
    logo: BrandFour,
    name: 'Vimeo',
    visitors: 1.5,
    revenues: '3,580',
    sales: 389,
    conversion: 2.5,
  },
  {
    logo: BrandFive,
    name: 'Facebook',
    visitors: 3.5,
    revenues: '6,768',
    sales: 390,
    conversion: 4.2,
  },
];

const TableOne = () => {
  return (
    <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-px-5 tw-pt-6 tw-pb-2.5 tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark sm:tw-px-7.5 xl:tw-pb-1">
      <h4 className="tw-mb-6 tw-text-xl tw-font-semibold tw-text-black dark:tw-text-white">
        Top Channels
      </h4>

      <div className="tw-flex tw-flex-col">
        <div className="tw-grid tw-grid-cols-3 tw-rounded-sm tw-bg-gray-2 dark:tw-bg-meta-4 sm:tw-grid-cols-5">
          <div className="tw-p-2.5 xl:tw-p-5">
            <h5 className="tw-text-sm tw-font-medium tw-uppercase xsm:tw-text-base">
              Source
            </h5>
          </div>
          <div className="tw-p-2.5 tw-text-center xl:tw-p-5">
            <h5 className="tw-text-sm tw-font-medium tw-uppercase xsm:tw-text-base">
              Visitors
            </h5>
          </div>
          <div className="tw-p-2.5 tw-text-center xl:tw-p-5">
            <h5 className="tw-text-sm tw-font-medium tw-uppercase xsm:tw-text-base">
              Revenues
            </h5>
          </div>
          <div className="tw-hidden tw-p-2.5 tw-text-center sm:tw-block xl:tw-p-5">
            <h5 className="tw-text-sm tw-font-medium tw-uppercase xsm:tw-text-base">
              Sales
            </h5>
          </div>
          <div className="tw-hidden tw-p-2.5 tw-text-center sm:tw-block xl:tw-p-5">
            <h5 className="tw-text-sm tw-font-medium tw-uppercase xsm:tw-text-base">
              Conversion
            </h5>
          </div>
        </div>

        {brandData.map((brand, key) => (
          <div
            className={`tw-grid tw-grid-cols-3 sm:tw-grid-cols-5 ${
              key === brandData.length - 1
                ? ''
                : 'tw-border-b tw-border-stroke dark:tw-border-strokedark'
            }`}
            key={key}
          >
            <div className="tw-flex tw-items-center tw-gap-3 tw-p-2.5 xl:tw-p-5">
              <div className="tw-flex-shrink-0">
                <img src={brand.logo} alt="Brand" />
              </div>
              <p className="tw-hidden tw-text-black dark:tw-text-white sm:tw-block">
                {brand.name}
              </p>
            </div>

            <div className="tw-flex tw-items-center tw-justify-center tw-p-2.5 xl:tw-p-5">
              <p className="tw-text-black dark:tw-text-white">{brand.visitors}K</p>
            </div>

            <div className="tw-flex tw-items-center tw-justify-center tw-p-2.5 xl:tw-p-5">
              <p className="tw-text-meta-3">${brand.revenues}</p>
            </div>

            <div className="tw-hidden tw-items-center tw-justify-center tw-p-2.5 sm:tw-flex xl:tw-p-5">
              <p className="tw-text-black dark:tw-text-white">{brand.sales}</p>
            </div>

            <div className="tw-hidden tw-items-center tw-justify-center tw-p-2.5 sm:tw-flex xl:tw-p-5">
              <p className="tw-text-meta-5">{brand.conversion}%</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableOne;
