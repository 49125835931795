import React from 'react';
import { LucideIcon } from 'lucide-react';

const BenefitItem = ({ title, description, icon: Icon }) => {
  return (
    <div className="tw-flex tw-items-start tw-space-x-4">
      <div className="tw-text-[#366196] tw-mt-1">
        <Icon size={24} />
      </div>
      <div>
        <h4 className="tw-font-semibold tw-mb-1">{title}</h4>
        <p className="tw-text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default BenefitItem;
