import React from 'react';
import { LucideIcon } from 'lucide-react';

const ServiceCard = ({ title, description, icon: Icon, buttonText }) => {
  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md tw-flex tw-flex-col tw-items-center tw-text-center">
      <div className="tw-mb-4 tw-text-[#366196]">
        <Icon size={48} />
      </div>
      <h3 className="tw-text-xl tw-font-semibold tw-mb-2">{title}</h3>
      <p className="tw-text-gray-600 tw-mb-4">{description}</p>
      <button className="tw-mt-auto tw-bg-[#366196] tw-text-white tw-py-2 tw-px-4 tw-rounded-md hover:tw-bg-[#2b4e78] tw-transition-colors">
        {buttonText}
      </button>
    </div>
  );
};

export default ServiceCard;
