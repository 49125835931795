import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './Shop/CheckOutForm';
import { useState, useEffect } from 'react';
import { Shield, CreditCard, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripeWrapper = () => {
    const [clientSecret, setClientSecret] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0); 

    const navigate = useNavigate();
    
    useEffect(() => {
        const fetchPaymentIntent = async () => {
            try {
                const token = localStorage.getItem('TOKEN');
                if (!token) {
                    throw new Error('Please log in to continue');
                }


            const response = await fetch(`http://localhost:8000/api/payment/create-intent`, { // Note the /api prefix
                method: "POST",
                credentials: 'include',
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "Accept": "application/json"
                },
            });
            
                
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to create payment intent');
                }
                
                const data = await response.json();
                setClientSecret(data.clientSecret);
                
                // Optionally update the total amount display
                if (data.total) {
                    setTotal(data.total);
                }
            } catch (err) {
                console.error('Payment Intent Error:', err);
                setError(err.message);
                
                // Redirect to cart if it's empty
                if (err.message === 'Cart is empty') {
                    navigate('/cart');
                }
            } finally {
                setLoading(false);
            }
        };
    
        fetchPaymentIntent();
    }, [navigate]);

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#366196',
            borderRadius: '8px',
        },
        rules: {
            '.Input': {
                border: '1px solid #E5E7EB',
                boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            },
        },
    };
    
    const options = {
        clientSecret,
        appearance,
    };

    if (loading) {
        return (
            <div className="tw-min-h-screen tw-bg-gray-50 tw-flex tw-items-center tw-justify-center">
                <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-t-2 tw-border-b-2 tw-border-[#366196]"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="tw-min-h-screen tw-bg-gray-50 tw-flex tw-items-center tw-justify-center">
                <div className="tw-bg-red-50 tw-text-red-500 tw-p-4 tw-rounded-lg">
                    {error}
                </div>
            </div>
        );
    }

    return (
        <div className="tw-min-h-screen tw-bg-gray-50 tw-py-12">
            <div className="tw-max-w-4xl tw-mx-auto tw-px-4">
                {/* Security Banner */}
                <div className="tw-bg-blue-50 tw-p-4 tw-rounded-lg tw-mb-8 tw-flex tw-items-center tw-justify-center tw-gap-2">
                    <Shield className="tw-w-5 tw-h-5 tw-text-blue-600" />
                    <span className="tw-text-blue-700 tw-text-sm">Secure Checkout - Protected by Stripe</span>
                </div>

                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-8">
                    {/* Order Summary Section */}
                    <div className="md:tw-col-span-1">
                        <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-6">
                            <h3 className="tw-text-lg tw-font-semibold tw-mb-4">Order Summary</h3>
                            <div className="tw-space-y-3">
                                <div className="tw-flex tw-justify-between">
                                    <span className="tw-text-gray-600">Subtotal</span>
                                    <span>$89.97</span>
                                </div>
                                <div className="tw-flex tw-justify-between">
                                    <span className="tw-text-gray-600">Shipping</span>
                                    <span>$10.00</span>
                                </div>
                                <div className="tw-flex tw-justify-between tw-pt-3 tw-border-t tw-font-medium">
                                    <span>Total</span>
                                    <span>$99.97</span>
                                </div>
                            </div>

                            {/* Payment Methods */}
                            <div className="tw-mt-6 tw-pt-6 tw-border-t">
                                <h4 className="tw-text-sm tw-font-medium tw-mb-3">Accepted Payment Methods</h4>
                                <div className="tw-flex tw-gap-2">
                                    <img src="/visa.svg" alt="Visa" className="tw-h-8" />
                                    <img src="/mastercard.svg" alt="Mastercard" className="tw-h-8" />
                                    <img src="/amex.svg" alt="American Express" className="tw-h-8" />
                                </div>
                            </div>
                        </div>

                        {/* Security Features */}
                        <div className="tw-mt-6 tw-space-y-4">
                            <div className="tw-flex tw-items-center tw-gap-3">
                                <Shield className="tw-w-5 tw-h-5 tw-text-green-600" />
                                <span className="tw-text-sm tw-text-gray-600">SSL Encrypted Payment</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-gap-3">
                                <CreditCard className="tw-w-5 tw-h-5 tw-text-green-600" />
                                <span className="tw-text-sm tw-text-gray-600">Secure Card Processing</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-gap-3">
                                <Clock className="tw-w-5 tw-h-5 tw-text-green-600" />
                                <span className="tw-text-sm tw-text-gray-600">Quick & Easy Checkout</span>
                            </div>
                        </div>
                    </div>

                    {/* Payment Form Section */}
                    <div className="md:tw-col-span-2">
                        <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-6">
                            <h2 className="tw-text-2xl tw-font-bold tw-mb-6">Payment Details</h2>
                            {clientSecret && (
                                <Elements stripe={stripePromise} options={options}>
                                    <CheckoutForm />
                                </Elements>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StripeWrapper;