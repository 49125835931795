import { Link } from 'react-router-dom';
import React from 'react';

interface BreadcrumbProps {
  pageName: string;
}

const Breadcrumb = ({ pageName }: BreadcrumbProps) => {
  return (
    <div className="tw-mb-8 tw-flex tw-flex-col tw-gap-4 tw-sm:flex-row tw-sm:items-center tw-sm:justify-between tw-bg-gray-100 tw-p-4 tw-rounded-md">
      <h2 className="tw-text-xl tw-font-bold tw-text-gray-800 tw-dark:text-white">
        {pageName}
      </h2>

      <nav>
        <ol className="tw-flex tw-items-center tw-gap-2">
          <li>
            <Link className="tw-font-medium tw-text-blue-600 hover:tw-text-blue-800" to="/admin">
              Dashboard
            </Link>
          </li>
          <li className="tw-font-medium tw-text-gray-600">/</li>
          <li className="tw-font-medium tw-text-primary">{pageName}</li>
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
