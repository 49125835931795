import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../designLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";

const Product = (props) => {
  const _id = props.productName;
  const idString = (_id) => String(_id).toLowerCase().split(" ").join("");
  const rootId = idString(_id);
  const navigate = useNavigate();
  const productItem = props;

  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: productItem,
      },
    });
  };

  const handleAddToCart = () => {
    alert(`${props.productName} added to cart.`);
  };

  return (
    <div className="tw-w-full tw-max-w-sm tw-bg-white tw-rounded-lg tw-shadow-lg tw-overflow-hidden tw-group tw-transform tw-transition-all tw-duration-300 tw-ease-in-out hover:tw-shadow-2xl hover:tw-scale-105">
      {/* Image Section */}
      <div className="tw-relative tw-h-60 tw-overflow-hidden">
        <Image className="tw-w-full tw-h-full tw-object-cover tw-transition-transform tw-duration-500 group-hover:tw-scale-110" imgSrc={props.img} />
        {props.badge && (
          <div className="tw-absolute tw-top-4 tw-left-4">
            <Badge text="New" />
          </div>
        )}
      </div>

      {/* Product Info */}
      <div className="tw-p-6 tw-space-y-2">
        <h2 className="tw-text-lg tw-font-bold tw-text-gray-800">{props.productName}</h2>
        <p className="tw-text-sm tw-text-gray-500">{props.color}</p>
        <p className="tw-text-lg tw-font-semibold tw-text-primeColor">${props.price}</p>
      </div>

      {/* Action Buttons */}
      <div className="tw-w-full tw-bg-white tw-p-4 tw-space-y-3 tw-border-t tw-border-gray-200">
        <ul className="tw-flex tw-justify-between tw-items-center tw-space-x-4">
          <li
            onClick={handleAddToCart}
            className="tw-flex tw-items-center tw-space-x-2 tw-text-sm tw-text-gray-600 hover:tw-text-primeColor tw-transition-all tw-duration-300 tw-cursor-pointer"
          >
            <FaShoppingCart />
            <span>Add to Cart</span>
          </li>
          <li
            onClick={handleProductDetails}
            className="tw-flex tw-items-center tw-space-x-2 tw-text-sm tw-text-gray-600 hover:tw-text-primeColor tw-transition-all tw-duration-300 tw-cursor-pointer"
          >
            <MdOutlineLabelImportant />
            <span>Details</span>
          </li>
          <li className="tw-flex tw-items-center tw-space-x-2 tw-text-sm tw-text-gray-600 hover:tw-text-primeColor tw-transition-all tw-duration-300 tw-cursor-pointer">
            <BsSuitHeartFill />
            <span>Wishlist</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Product;
