/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const options: ApexOptions = {
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'left',
  },
  colors: ['#3C50E0', '#80CAEE'],
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    height: 335,
    type: 'area',
    dropShadow: {
      enabled: true,
      color: '#623CEA14',
      top: 10,
      blur: 4,
      left: 0,
      opacity: 0.1,
    },

    toolbar: {
      show: false,
    },
  },
  responsive: [
    {
      breakpoint: 1024,
      options: {
        chart: {
          height: 300,
        },
      },
    },
    {
      breakpoint: 1366,
      options: {
        chart: {
          height: 350,
        },
      },
    },
  ],
  stroke: {
    width: [2, 2],
    curve: 'straight',
  },
  // labels: {
  //   show: false,
  //   position: "top",
  // },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 4,
    colors: '#fff',
    strokeColors: ['#3056D3', '#80CAEE'],
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    hover: {
      size: undefined,
      sizeOffset: 5,
    },
  },
  xaxis: {
    type: 'category',
    categories: [
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
    ],
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    title: {
      style: {
        fontSize: '0px',
      },
    },
    min: 0,
    max: 100,
  },
};

interface ChartOneState {
  series: {
    name: string;
    data: number[];
  }[];
}

const ChartOne: React.FC = () => {
  const [state, setState] = useState<ChartOneState>({
    series: [
      {
        name: 'Product One',
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 45],
      },

      {
        name: 'Product Two',
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 51],
      },
    ],
  });

  const handleReset = () => {
    setState((prevState) => ({
      ...prevState,
    }));
  };
  handleReset;

  return (
    <div className="tw-col-span-12 tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-px-5 tw-pt-7.5 tw-pb-5 tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark sm:tw-px-7.5 xl:tw-col-span-8">
      <div className="tw-flex tw-flex-wrap tw-items-start tw-justify-between tw-gap-3 sm:tw-flex-nowrap">
        <div className="tw-flex tw-w-full tw-flex-wrap tw-gap-3 sm:tw-gap-5">
          <div className="tw-flex tw-min-w-47.5">
            <span className="tw-mt-1 tw-mr-2 tw-flex tw-h-4 tw-w-full tw-max-w-4 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-primary">
              <span className="tw-block tw-h-2.5 tw-w-full tw-max-w-2.5 tw-rounded-full tw-bg-primary"></span>
            </span>
            <div className="tw-w-full">
              <p className="tw-font-semibold tw-text-primary">Total Revenue</p>
              <p className="tw-text-sm tw-font-medium">12.04.2022 - 12.05.2022</p>
            </div>
          </div>
          <div className="tw-flex tw-min-w-47.5">
            <span className="tw-mt-1 tw-mr-2 tw-flex tw-h-4 tw-w-full tw-max-w-4 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-secondary">
              <span className="tw-block tw-h-2.5 tw-w-full tw-max-w-2.5 tw-rounded-full tw-bg-secondary"></span>
            </span>
            <div className="tw-w-full">
              <p className="tw-font-semibold tw-text-secondary">Total Sales</p>
              <p className="tw-text-sm tw-font-medium">12.04.2022 - 12.05.2022</p>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-w-full tw-max-w-45 tw-justify-end">
          <div className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-whiter tw-p-1.5 dark:tw-bg-meta-4">
            <button className="tw-rounded tw-bg-white tw-py-1 tw-px-3 tw-text-xs tw-font-medium tw-text-black tw-shadow-card hover:tw-bg-white hover:tw-shadow-card dark:tw-bg-boxdark dark:tw-text-white dark:hover:tw-bg-boxdark">
              Day
            </button>
            <button className="tw-rounded tw-py-1 tw-px-3 tw-text-xs tw-font-medium tw-text-black hover:tw-bg-white hover:tw-shadow-card dark:tw-text-white dark:hover:tw-bg-boxdark">
              Week
            </button>
            <button className="tw-rounded tw-py-1 tw-px-3 tw-text-xs tw-font-medium tw-text-black hover:tw-bg-white hover:tw-shadow-card dark:tw-text-white dark:hover:tw-bg-boxdark">
              Month
            </button>
          </div>
        </div>
      </div>

      <div>
        <div id="chartOne" className="tw--ml-5">
          <ReactApexChart
            options={options}
            series={state.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartOne;
