import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DollarSign, ShoppingCart, Users, TrendingUp } from 'lucide-react';
import { ProfileHeader } from './profileComponents/ProfileHeader.tsx';
import { StatisticsCard } from './profileComponents/StatisticsCard.tsx';
import { ActivityTimeline } from './profileComponents/ActivityTimeLine.tsx';

const mockActivities = [
  {
    id: 1,
    title: 'New Order Received',
    time: '30 minutes ago',
    description: 'Order #12345 was placed for $299.00',
  },
  {
    id: 2,
    title: 'Product Review',
    time: '2 hours ago',
    description: 'Left a 5-star review on Product XYZ',
  },
  {
    id: 3,
    title: 'Profile Updated',
    time: '1 day ago',
    description: 'Updated shipping address and payment method',
  },
];

export default function ProfilePage() {
  const [userData, setUserData] = useState({
    username: "John Doe",
    email: "john.doe@example.com",
    location: "-"
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("TOKEN");
      try {
        const response = await axios.get("https://api.kseco.eu/api/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setUserData({
          username: response.data.data.name || "John Doe",
          email: response.data.data.email || "john.doe@example.com",
          location: response.data.data.location || "-"
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="tw-container tw-mx-auto tw-px-4 tw-py-8">
      <div className="tw-space-y-6">
        <ProfileHeader 
          username={userData.username}
          email={userData.email}
          location={userData.location}
        />
        
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-6">
          <StatisticsCard
            title="Total Earnings"
            value="$12,426.00"
            icon={<DollarSign className="tw-w-6 tw-h-6 tw-text-primary" />}
            trend={{ value: 12.5, isPositive: true }}
          />
          <StatisticsCard
            title="Orders"
            value="384"
            icon={<ShoppingCart className="tw-w-6 tw-h-6 tw-text-meta-6" />}
            trend={{ value: 8.2, isPositive: true }}
          />
          <StatisticsCard
            title="Total Customers"
            value="2,945"
            icon={<Users className="tw-w-6 tw-h-6 tw-text-meta-5" />}
            trend={{ value: 3.1, isPositive: false }}
          />
          <StatisticsCard
            title="Sales Growth"
            value="25.9%"
            icon={<TrendingUp className="tw-w-6 tw-h-6 tw-text-meta-3" />}
            trend={{ value: 4.8, isPositive: true }}
          />
        </div>

        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-6">
          <div className="lg:tw-col-span-2">
            <ActivityTimeline activities={mockActivities} />
          </div>
          <div className="tw-bg-white tw-rounded-2xl tw-p-6 tw-shadow-card">
            <h3 className="tw-text-xl tw-font-semibold tw-text-black tw-mb-6">Quick Actions</h3>
            <div className="tw-space-y-4">
              <button className="tw-w-full tw-bg-primary tw-text-white tw-rounded-lg tw-py-3 tw-px-4 tw-font-medium hover:tw-bg-primary/90 tw-transition-colors">
                View Store Analytics
              </button>
              <button className="tw-w-full tw-bg-meta-2 tw-text-black tw-rounded-lg tw-py-3 tw-px-4 tw-font-medium hover:tw-bg-meta-2/90 tw-transition-colors">
                Manage Products
              </button>
              <button className="tw-w-full tw-bg-meta-2 tw-text-black tw-rounded-lg tw-py-3 tw-px-4 tw-font-medium hover:tw-bg-meta-2/90 tw-transition-colors">
                Update Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}