import React from 'react';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb.tsx';
import ChartOne from '../components/Charts/ChartOne.tsx';
import ChartThree from '../components/Charts/ChartThree.tsx';
import ChartTwo from '../components/Charts/ChartTwo.tsx';

const Chart: React.FC = () => {
  return (
    <>
      <Breadcrumb pageName="Chart" />

      <div className="tw-grid tw-grid-cols-12 tw-gap-4 tw-md:gap-6 tw-2xl:gap-7.5">
        <ChartOne />
        <ChartTwo />
        <ChartThree />
      </div>
    </>
  );
};

export default Chart;
