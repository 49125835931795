import React, { useState } from 'react';

const SwitcherFour = () => {
  const [enabled, setEnabled] = useState<boolean>(false);

  return (
    <div>
      <label
        htmlFor="toggle4"
        className="tw-flex tw-cursor-pointer tw-select-none tw-items-center"
      >
        <div className="tw-relative">
          <input
            type="checkbox"
            id="toggle4"
            className="tw-sr-only"
            onChange={() => {
              setEnabled(!enabled);
            }}
          />
          <div className="tw-block tw-h-8 tw-w-14 tw-rounded-full tw-bg-black"></div>
          <div
            className={`tw-absolute tw-left-1 tw-top-1 tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-transition ${
              enabled && '!tw-right-1 !tw-translate-x-full'
            }`}
          ></div>
        </div>
      </label>
    </div>
  );
};

export default SwitcherFour;
