import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

const FilteredTable = ({ companies, searchTerm, convertedFilter, loading, tableRef, publicationFilter }) => {
  const { t } = useTranslation();
  const [debouncedFilter, setDebouncedFilter] = useState(convertedFilter);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filterLocked, setFilterLocked] = useState(false);

  useEffect(() => {
    // Filter the companies based on searchTerm and other filters
    const filtered = filterCompanies(companies, searchTerm, convertedFilter, publicationFilter);
    setFilteredCompanies(filtered);
  }, [companies, searchTerm, convertedFilter, publicationFilter]);

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedFilter(convertedFilter);
    }, 300); // Adjust debounce delay as needed

    handler();
    return () => {
      handler.cancel();
    };
  }, [convertedFilter]);

  useEffect(() => {
    console.log('Converted Filter:', debouncedFilter);
    console.log('Publication Filter:', publicationFilter);
    console.log('Companies:', companies);
    
    if (!filterLocked) {
      const isPublicationFilterActive = debouncedFilter.publications && debouncedFilter.publications.length > 0;

      if (isPublicationFilterActive) {
        const filtered = filterCompanies(companies, searchTerm, debouncedFilter, publicationFilter);
        console.log('Filtered Companies:', filtered);
        setFilteredCompanies(filtered);
      } else {
        setFilteredCompanies(companies);
      }
    } else {
      // If the filter is locked, show all companies
      setFilteredCompanies(companies);
    }
  }, [companies, searchTerm, debouncedFilter, publicationFilter, filterLocked]);

  const filterCompanies = (companies, searchTerm, convertedFilter, publicationFilter) => {
    return companies.filter((co) => {
      const cantonArray = convertedFilter.canton || []; // Ensure it's an array
      const publications = publicationFilter || [];
      const nationalityArray = convertedFilter.nationality || [];

      const matchesPublications =
        publications.length === 0 ||
        (co.publications && co.publications.some(publication => publications.includes(publication)));

      const matchesCanton =
        cantonArray.length === 0 ||
        (co.city?.cantonId && cantonArray.includes(co.city.cantonId));

      const matchesSearchTerm =
        searchTerm.trim() === "" ||
        (typeof co.companyName === 'string' && co.companyName.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (typeof co.che === 'string' && co.che.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (typeof co.companyType === 'string' && co.companyType.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (typeof co.dateOfRegistration === 'string' && co.dateOfRegistration.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (co.city?.name && co.city.name.toLowerCase().includes(searchTerm.toLowerCase()));

      const matchesSectors =
        convertedFilter.purposes.length === 0 ||
        (Array.isArray(co.purposes) && co.purposes.some(purpose => convertedFilter.purposes.includes(purpose)));


      const matchesNationality = nationalityArray.length === 0 || nationalityArray.includes(co.nationality);


      const matchesOtherFilters =
        (convertedFilter.companyType.length === 0 || convertedFilter.companyType.includes(co.companyType)) &&
        (convertedFilter.nationality.length === 0 || convertedFilter.nationality.includes(co.nationality)) &&
        (convertedFilter.services.length === 0 || convertedFilter.services.includes(co.services));

      return matchesCanton && matchesSearchTerm && matchesSectors && matchesOtherFilters && matchesPublications && matchesNationality;
    });
  };

  return (
    <div className="relative">
      <div ref={tableRef} className="overflow-x-auto">
        <table className="table table-striped table-bordered" id="CompaniesListTable">
          <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
            <tr className="head">
              <th className="p-2 whitespace-nowrap nam">
                <div className="font-semibold text-left">{t('dashboard.table.headers.companyName')}</div>
              </th>
              <th className="p-2 whitespace-nowrap">
                <div className="font-semibold text-left">{t('dashboard.table.headers.uniqueId')}</div>
              </th>
              <th className="p-2 whitespace-nowrap dateof">
                <div className="font-semibold text-left">{t('dashboard.table.headers.dateOfRegistration')}</div>
              </th>
              <th className="p-2 whitespace-nowrap dt">
                <div className="font-semibold text-left">{t('dashboard.table.headers.legalForm')}</div>
              </th>
              <th className="p-2 whitespace-nowrap dt">
                <div className="font-semibold text-left">{t('dashboard.table.headers.city')}</div>
              </th>
            </tr>
          </thead>
          {!loading ? (
            <tbody className="text-sm divide-y divide-gray-100">
              {filteredCompanies.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center nodata">{t('dashboard.table.noResults')}</td>
                </tr>
              ) : (
                filteredCompanies.map((co) => (
                  <tr key={co.id}>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left nam">
                        <Link
                          target="_blank"
                          to={{
                            pathname: `/details/${co.id}`,
                            state: { companies: co },
                          }}
                        >
                          {typeof co.companyName === 'string' ? co.companyName : ''}
                        </Link>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left left">
                        <Link
                          target="_blank"
                          to={{
                            pathname: `/details/${co.id}`,
                            state: { companies: co },
                          }}
                        >
                          {typeof co.che === 'string' ? co.che : ''}
                        </Link>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap dt">
                      <div className="text-left left">
                        <Link
                          target="_blank"
                          to={{
                            pathname: `/details/${co.id}`,
                            state: { companies: co },
                          }}
                        >
                          {typeof co.dateOfRegistration === 'string' ? new Date(co.dateOfRegistration).toLocaleDateString() : ''}
                        </Link>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap dt">
                      <div className="text-left left">
                        <Link
                          target="_blank"
                          to={{
                            pathname: `/details/${co.id}`,
                            state: { companies: co },
                          }}
                        >
                          {typeof co.companyType === 'string' ? co.companyType : ''}
                        </Link>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap dt">
                      <div className="text-left left">
                        {co.city?.city || 'No city available'}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          ) : (
            <div className="spin">
              <p className="hih">{t('dashboard.table.loading')}</p>
            </div>
          )}
        </table>
      </div>
    </div>
  );
};

export default FilteredTable;
