import React from "react";

interface Activity {
    id: number;
    title: string;
    time: string;
    description: string;
  }
  
  interface ActivityTimelineProps {
    activities: Activity[];
  }
  
  export function ActivityTimeline({ activities }: ActivityTimelineProps) {
    return (
      <div className="tw-bg-white tw-rounded-2xl tw-p-6 tw-shadow-card">
        <h3 className="tw-text-xl tw-font-semibold tw-text-black tw-mb-6">Recent Activity</h3>
        <div className="tw-space-y-6">
          {activities.map((activity) => (
            <div key={activity.id} className="tw-relative tw-pl-6 tw-border-l-2 tw-border-stroke">
              <span className="tw-absolute tw-left-[-9px] tw-top-0 tw-w-4 tw-h-4 tw-rounded-full tw-bg-primary"></span>
              <h5 className="tw-text-base tw-font-semibold tw-text-black">{activity.title}</h5>
              <p className="tw-text-sm tw-text-body tw-mt-1">{activity.description}</p>
              <span className="tw-text-sm tw-text-bodydark tw-mt-2 tw-block">{activity.time}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }