import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import Breadcrumbs from "../BreadCrumbs";
import { emptyCart } from "../assets/images";
import ItemCard from "./ItemCard";
import Navigationbar from "../../Navbar/Navbar";
import { ShoppingCart } from "lucide-react";

import Sidebar from "./Sidebar";

const Cart = () => {
  // Dummy product data for demonstration
  const [products, setProducts] = useState([
    {
      _id: "1",
      name: "Product 1",
      price: 50,
      quantity: 2,
    },
    {
      _id: "2",
      name: "Product 2",
      price: 30,
      quantity: 1,
    },
  ]);

  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);

  useEffect(() => {
    let price = 0;
    products.forEach((item) => {
      price += item.price * item.quantity;
    });
    setTotalAmt(price);
  }, [products]);

  useEffect(() => {
    if (totalAmt <= 200) {
      setShippingCharge(30);
    } else if (totalAmt <= 400) {
      setShippingCharge(25);
    } else if (totalAmt > 401) {
      setShippingCharge(20);
    }
  }, [totalAmt]);

  const resetCart = () => {
    setProducts([]); // Reset the cart by setting products to an empty array
  };


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

  return (
    <>
      <Navigationbar toggleSidebar={toggleSidebar}/>
      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
      <div className="tw-max-w-container tw-mx-auto tw-px-4">
        <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-4 tw-my-8">
          <Breadcrumbs title="Cart" />
          <ShoppingCart className="tw-h-16 tw-w-16 tw-text-primeColor" />
        </div>

        {products.length > 0 ? (
          <div className="tw-pb-20">
            <div className="tw-w-full tw-h-20 tw-bg-gray-100 tw-text-primeColor tw-hidden lg:tw-grid tw-grid-cols-5 tw-place-content-center tw-px-6 tw-text-lg tw-font-titleFont tw-font-semibold tw-rounded-lg tw-shadow-md tw-mb-5">
              <h2 className="tw-col-span-2">Product</h2>
              <h2>Price</h2>
              <h2>Quantity</h2>
              <h2>Sub Total</h2>
            </div>
            <div className="tw-mt-5">
              {products.map((item) => (
                <div key={item._id}>
                  <ItemCard item={item} />
                </div>
              ))}
            </div>

            <button
              onClick={resetCart}
              className="tw-my-4 tw-py-2 tw-px-10 tw-bg-red-600 tw-text-white tw-font-semibold tw-uppercase tw-rounded-md tw-shadow-lg tw-hover:bg-red-700 tw-duration-300"
            >
              Reset Cart
            </button>

            <div className="tw-flex tw-flex-col mdl:tw-flex-row tw-justify-between tw-border tw-py-4 tw-px-4 tw-items-center tw-gap-2 mdl:tw-gap-0 tw-rounded-lg tw-shadow-md tw-bg-gray-50">
              <div className="tw-flex tw-items-center tw-gap-4">
                <input
                  className="tw-w-44 mdl:tw-w-52 tw-h-10 tw-px-4 tw-border tw-border-gray-400 tw-text-primeColor tw-text-sm tw-rounded-md focus:tw-outline-none focus:tw-ring focus:tw-ring-primeColor"
                  type="text"
                  placeholder="Coupon Number"
                />
                <p className="tw-text-sm mdl:tw-text-base tw-font-semibold">
                  Apply Coupon
                </p>
              </div>
              <p className="tw-text-lg tw-font-semibold">Update Cart</p>
            </div>

            <div className="tw-max-w-7xl tw-gap-4 tw-flex tw-justify-end tw-mt-4">
              <div className="tw-w-96 tw-flex tw-flex-col tw-gap-4">
                <h1 className="tw-text-2xl tw-font-semibold tw-text-right">Cart Totals</h1>
                <div className="tw-bg-gray-200 tw-p-4 tw-rounded-lg tw-shadow-sm">
                  <p className="tw-flex tw-items-center tw-justify-between tw-py-1.5 tw-text-lg tw-font-medium">
                    Subtotal
                    <span className="tw-font-semibold tw-tracking-wide tw-font-titleFont">
                      ${totalAmt}
                    </span>
                  </p>
                  <p className="tw-flex tw-items-center tw-justify-between tw-py-1.5 tw-text-lg tw-font-medium">
                    Shipping Charge
                    <span className="tw-font-semibold tw-tracking-wide tw-font-titleFont">
                      ${shippingCharge}
                    </span>
                  </p>
                  <p className="tw-flex tw-items-center tw-justify-between tw-py-1.5 tw-text-lg tw-font-bold">
                    Total
                    <span className="tw-font-bold tw-tracking-wide tw-text-lg tw-font-titleFont">
                      ${totalAmt + shippingCharge}
                    </span>
                  </p>
                </div>
                <div className="tw-flex tw-justify-end">
                  <Link to="/checkout">
                    <button className="tw-w-52 tw-h-10 tw-bg-primeColor tw-text-white tw-rounded-md tw-shadow-lg tw-hover:bg-black tw-duration-300">
                      Proceed to Checkout
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            className="tw-flex tw-flex-col mdl:tw-flex-row tw-justify-center tw-items-center tw-gap-4 tw-pb-20"
          >
            <div>
              <img
                className="tw-w-80 tw-rounded-lg tw-p-4 tw-mx-auto"
                src={emptyCart}
                alt="emptyCart"
              />
            </div>
            <div className="tw-max-w-[500px] tw-p-4 tw-py-8 tw-bg-white tw-flex tw-gap-4 tw-flex-col tw-items-center tw-rounded-md tw-shadow-lg">
              <h1 className="tw-font-titleFont tw-text-xl tw-font-bold tw-uppercase">
                Your Cart Feels Lonely.
              </h1>
              <p className="tw-text-sm tw-text-center tw-px-10 -mt-2">
                Your shopping cart lives to serve. Give it purpose - fill it with
                books, electronics, videos, etc. and make it happy.
              </p>
              <Link to="/shop">
                <button className="tw-bg-primeColor tw-rounded-md tw-cursor-pointer tw-hover:bg-black tw-active:bg-gray-900 tw-px-8 tw-py-2 tw-font-titleFont tw-font-semibold tw-text-lg tw-text-gray-200 tw-hover:text-white tw-duration-300">
                  Continue Shopping
                </button>
              </Link>
            </div>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default Cart;
