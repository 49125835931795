
import React, { useState } from "react";

import CustomSlider from "../CustomSlider";

import {
  bannerImgTwo,
  bannerImgThree,
} from "../assets/images";

import a from '../../../images/output-onlinepngtools.png';

import image from "../../../images/Screen Shot 2022-05-29 at 23.24.24.png";


import test from "../../../images/facebook-ad-agency-8387166facebook-advertising (2).jpg";


const Banner = () => {

    const images = [
        a,
        "/images/pricingImg1.PNG",
        test
      ];

  return (
    <div className="tw-w-full tw-bg-white">
        {/* <img src="/images/bannerImgOne.webp" alt="Banner One" /> */}
      <CustomSlider images={images} />
    </div>
  );
};

export default Banner;