import Breadcrumb from '../components/Breadcrumbs/Breadcrumb.tsx';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { User, Mail, MapPin, Phone, Building2 } from 'lucide-react';

interface UserData {
  name: string;
  email: string;
  location?: string;
  phone?: string;
  company?: string;
}

const Settings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<UserData>({
    name: '',
    email: '',
    location: '',
    phone: '',
    company: ''
  });

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get("https://api.kseco.eu/api/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(response.data.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error('Failed to load user data');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const token = localStorage.getItem("TOKEN");

    try {
      await axios.put("https://api.kseco.eu/api/profile", userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error('Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <>
      <div className="tw-container tw-mx-auto">
        <div className="tw-mb-6 tw-px-4">
          <Breadcrumb pageName="Settings" />
        </div>

        <div className="tw-px-4">
          <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
            <div className="tw-border-b tw-border-stroke tw-py-4 tw-px-8 dark:tw-border-strokedark">
              <h3 className="tw-text-xl tw-font-semibold tw-text-black dark:tw-text-white">
                Personal Information
              </h3>
            </div>
            <div className="tw-p-8">
              <form onSubmit={handleSubmit}>
                <div className="tw-mb-8 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
                  <div className="tw-w-full">
                    <label
                      className="tw-mb-3 tw-block tw-text-sm tw-font-medium tw-text-black dark:tw-text-white"
                      htmlFor="name"
                    >
                      Full Name
                    </label>
                    <div className="tw-relative">
                      <span className="tw-absolute tw-left-4.5 tw-top-4">
                        <User className="tw-w-5 tw-h-5 tw-text-body" />
                      </span>
                      <input
                        className="tw-w-full tw-rounded tw-border tw-border-stroke tw-bg-gray tw-py-3 tw-pl-11.5 tw-pr-4.5 tw-text-black focus:tw-border-primary focus-visible:tw-outline-none dark:tw-border-strokedark dark:tw-bg-meta-4 dark:tw-text-white dark:focus:tw-border-primary"
                        type="text"
                        name="name"
                        id="name"
                        value={userData.name}
                        onChange={handleChange}
                        placeholder="Enter your full name"
                      />
                    </div>
                  </div>

                  <div className="tw-w-full">
                    <label
                      className="tw-mb-3 tw-block tw-text-sm tw-font-medium tw-text-black dark:tw-text-white"
                      htmlFor="phone"
                    >
                      Phone Number
                    </label>
                    <div className="tw-relative">
                      <span className="tw-absolute tw-left-4.5 tw-top-4">
                        <Phone className="tw-w-5 tw-h-5 tw-text-body" />
                      </span>
                      <input
                        className="tw-w-full tw-rounded tw-border tw-border-stroke tw-bg-gray tw-py-3 tw-pl-11.5 tw-pr-4.5 tw-text-black focus:tw-border-primary focus-visible:tw-outline-none dark:tw-border-strokedark dark:tw-bg-meta-4 dark:tw-text-white dark:focus:tw-border-primary"
                        type="text"
                        name="phone"
                        id="phone"
                        value={userData.phone || ''}
                        onChange={handleChange}
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </div>
                </div>

                <div className="tw-mb-8">
                  <label
                    className="tw-mb-3 tw-block tw-text-sm tw-font-medium tw-text-black dark:tw-text-white"
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <div className="tw-relative">
                    <span className="tw-absolute tw-left-4.5 tw-top-4">
                      <Mail className="tw-w-5 tw-h-5 tw-text-body" />
                    </span>
                    <input
                      className="tw-w-full tw-rounded tw-border tw-border-stroke tw-bg-gray tw-py-3 tw-pl-11.5 tw-pr-4.5 tw-text-black focus:tw-border-primary focus-visible:tw-outline-none dark:tw-border-strokedark dark:tw-bg-meta-4 dark:tw-text-white dark:focus:tw-border-primary"
                      type="email"
                      name="email"
                      id="email"
                      value={userData.email}
                      readOnly
                      placeholder="Enter your email"
                    />
                  </div>
                </div>

                <div className="tw-mb-8">
                  <label
                    className="tw-mb-3 tw-block tw-text-sm tw-font-medium tw-text-black dark:tw-text-white"
                    htmlFor="location"
                  >
                    Location
                  </label>
                  <div className="tw-relative">
                    <span className="tw-absolute tw-left-4.5 tw-top-4">
                      <MapPin className="tw-w-5 tw-h-5 tw-text-body" />
                    </span>
                    <input
                      className="tw-w-full tw-rounded tw-border tw-border-stroke tw-bg-gray tw-py-3 tw-pl-11.5 tw-pr-4.5 tw-text-black focus:tw-border-primary focus-visible:tw-outline-none dark:tw-border-strokedark dark:tw-bg-meta-4 dark:tw-text-white dark:focus:tw-border-primary"
                      type="text"
                      name="location"
                      id="location"
                      value={userData.location || ''}
                      onChange={handleChange}
                      placeholder="Enter your location"
                    />
                  </div>
                </div>

                <div className="tw-mb-8">
                  <label
                    className="tw-mb-3 tw-block tw-text-sm tw-font-medium tw-text-black dark:tw-text-white"
                    htmlFor="company"
                  >
                    Company
                  </label>
                  <div className="tw-relative">
                    <span className="tw-absolute tw-left-4.5 tw-top-4">
                      <Building2 className="tw-w-5 tw-h-5 tw-text-body" />
                    </span>
                    <input
                      className="tw-w-full tw-rounded tw-border tw-border-stroke tw-bg-gray tw-py-3 tw-pl-11.5 tw-pr-4.5 tw-text-black focus:tw-border-primary focus-visible:tw-outline-none dark:tw-border-strokedark dark:tw-bg-meta-4 dark:tw-text-white dark:focus:tw-border-primary"
                      type="text"
                      name="company"
                      id="company"
                      value={userData.company || ''}
                      onChange={handleChange}
                      placeholder="Enter your company name"
                    />
                  </div>
                </div>

                <div className="tw-flex tw-justify-end tw-gap-4.5">
                  <button
                    className="tw-flex tw-justify-center tw-rounded tw-border tw-border-stroke tw-py-2 tw-px-6 tw-font-medium tw-text-black hover:tw-shadow-1 dark:tw-border-strokedark dark:tw-text-white"
                    type="button"
                    onClick={() => fetchUserData()}
                  >
                    Cancel
                  </button>
                  <button
                    className="tw-flex tw-justify-center tw-rounded tw-bg-primary tw-py-2 tw-px-6 tw-font-medium tw-text-gray hover:tw-bg-opacity-90"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
