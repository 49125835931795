import React from 'react';

interface RangeInputsProps {
  minValue: number;
  maxValue: number;
  onMinChange: (value: number) => void;
  onMaxChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
}

export function RangeInputs({
  minValue,
  maxValue,
  onMinChange,
  onMaxChange,
  min = 0,
  max = 1000000,
  step = 1000,
}: RangeInputsProps) {
  return (
    <div className="tw-flex tw-gap-3">
      <div className="tw-group tw-flex-1">
        <input
          type="number"
          value={minValue}
          onChange={(e) => onMinChange(Number(e.target.value))}
          min={min}
          max={maxValue}
          step={step}
          className="tw-w-full tw-rounded-md tw-border tw-border-stroke tw-bg-transparent 
          tw-px-3 tw-py-2 tw-text-sm tw-text-bodydark 
          tw-transition-all tw-duration-300
          placeholder:tw-text-bodydark2
          focus:tw-border-primary focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-primary/30
          hover:tw-border-primary/50"
          placeholder="Min"
        />
      </div>
      <div className="tw-group tw-flex-1">
        <input
          type="number"
          value={maxValue}
          onChange={(e) => onMaxChange(Number(e.target.value))}
          min={minValue}
          max={max}
          step={step}
          className="tw-w-full tw-rounded-md tw-border tw-border-stroke tw-bg-transparent 
          tw-px-3 tw-py-2 tw-text-sm tw-text-bodydark 
          tw-transition-all tw-duration-300
          placeholder:tw-text-bodydark2
          focus:tw-border-primary focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-primary/30
          hover:tw-border-primary/50"
          placeholder="Max"
        />
      </div>
    </div>
  );
}