import React from "react";

interface StatisticsCardProps {
    title: string;
    value: string | number;
    icon: React.ReactNode;
    trend: {
      value: number;
      isPositive: boolean;
    };
  }
  
  export function StatisticsCard({ title, value, icon, trend }: StatisticsCardProps) {
    return (
      <div className="tw-bg-white tw-rounded-2xl tw-p-6 tw-shadow-card">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div>
            <span className="tw-block tw-text-sm tw-font-medium tw-text-body tw-mb-1">{title}</span>
            <h4 className="tw-text-2xl tw-font-bold tw-text-black">{value}</h4>
          </div>
          <div className="tw-w-12 tw-h-12 tw-rounded-full tw-bg-meta-2 tw-flex tw-items-center tw-justify-center">
            {icon}
          </div>
        </div>
        <div className="tw-mt-4">
          <div className={`tw-flex tw-items-center tw-gap-1 tw-text-sm ${trend.isPositive ? 'tw-text-meta-3' : 'tw-text-meta-1'}`}>
            <span>{trend.isPositive ? '↑' : '↓'}</span>
            <span>{Math.abs(trend.value)}%</span>
            <span className="tw-text-body tw-ml-1">from last month</span>
          </div>
        </div>
      </div>
    );
  }