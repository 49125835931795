import React from "react";

const Loader = () => {
  return (
    <div className="tw-flex tw-h-screen tw-items-center tw-justify-center tw-bg-white">
      <div className="tw-h-16 tw-w-16 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-primary tw-border-t-transparent"></div>
    </div>
  );
};

export default Loader;
