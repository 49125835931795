import React, { ReactNode } from 'react';

interface CardDataStatsProps {
  title: string;
  total: string;
  rate: string;
  levelUp?: boolean;
  levelDown?: boolean;
  children: ReactNode;
}

const CardDataStats: React.FC<CardDataStatsProps> = ({
  title,
  total,
  rate,
  levelUp,
  levelDown,
  children,
}) => {
  return (
    <div className="tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-py-6 tw-px-7.5 tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
      <div className="tw-flex tw-h-11.5 tw-w-11.5 tw-items-center tw-justify-center tw-rounded-full tw-bg-meta-2 dark:tw-bg-meta-4">
        {children}
      </div>

      <div className="tw-mt-4 tw-flex tw-items-end tw-justify-between">
        <div>
          <h4 className="tw-text-title-md tw-font-bold tw-text-black dark:tw-text-white">
            {total}
          </h4>
          <span className="tw-text-sm tw-font-medium">{title}</span>
        </div>

        <span
          className={`tw-flex tw-items-center tw-gap-1 tw-text-sm tw-font-medium ${
            levelUp && 'tw-text-meta-3'
          } ${levelDown && 'tw-text-meta-5'} `}
        >
          {rate}

          {levelUp && (
            <svg
              className="tw-fill-meta-3"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
                fill=""
              />
            </svg>
          )}
          {levelDown && (
            <svg
              className="tw-fill-meta-5"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
                fill=""
              />
            </svg>
          )}
        </span>
      </div>
    </div>
  );
};

export default CardDataStats;
