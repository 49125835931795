import { Nav, Navbar, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import a from '../../images/logo.png';
import './navbar2.css';
import axios from 'axios';
import '../Login/login.css';

import { IconShoppingCartFilled } from '@tabler/icons-react';

const Navigationbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const Logout = async () => {
    try {
      localStorage.removeItem('TOKEN');
      navigate('/');
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();


  const Auth = async (e) => {
    e.preventDefault();
    try {
      const login = await axios.post('https://api.kseco.ch/auth/login', {
        email: email,
        password: password,
      });
      localStorage.setItem('TOKEN', login.data.token);
      localStorage.setItem('user', JSON.stringify(users))
      navigate('/Dashboard');
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const [users, setUsers] = useState(null); // Initialize users state as null

  // Call getUsers function in useEffect hook


  const getUsers = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get("https://api.kseco.eu/api/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data); // Log the response data to check its structure
      setUsers(response.data); // Update users state with response data
      console.log(users); // Log the users state variable to double-check its content
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error (e.g., show error message to the user)
    }
  };
  
  useEffect(() => {
    getUsers();
  }, []);
  
  console.log(users)

  const [isLogged, setisLogged] = useState(false);

  useEffect(() => {
    checkStorage();
    return () => {};
  }, [isLogged]);

  function checkStorage() {
    if (localStorage.getItem('TOKEN')) {
      setisLogged(true);
    } else {
      setisLogged(false);
    }
  }
  return (
    <div>
      <div className="header_top_menu">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="header_top_menu_text">
                <p className="pir">
                  <i className="bi bi-telephone"></i> +41 (0) 44 500 45 16
                </p>
                <p className="pir">
                  <i className="bi bi-envelope"></i> info@kseco.ch
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="header_top_menu_icon">
                {/* Add any additional top menu icons/elements here */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar collapseOnSelect expand="sm" className="navbar2 navbar">
        <div className="container">
          <Navbar.Brand as={Link} to="/">
            <img src={a} className="logoja" alt="KSECO" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto">
              <NavLink eventKey="1" as={Link} to="/home" className="nav-link">
                Home
              </NavLink>
              <NavLink eventKey="2" as={Link} to="/shop" className="nav-link">
                Shop
              </NavLink>
              <NavLink eventKey="3" as={Link} to="/marketing" className="nav-link">
                Marketing
              </NavLink>
              {isLogged ? (
                <>
                  <NavLink eventKey="4" as={Link} to="/Dashboard" className="lista nav-link">
                    <button className="button isi">
                      <i className="icon-equalizer warning font-large-2"></i>
                      <span className="list-builder-text">List Builder</span>
                    </button>
                  </NavLink>
                  <div className="nav-item dropdown">
                    <button 
                      className="button is-light dropdown-toggle" 
                      type="button" 
                      id="dropdownMenuButton" 
                      data-toggle="dropdown" 
                      aria-haspopup="true" 
                      aria-expanded="false"
                    >
                      <i className="bi bi-person per"></i>
                      {users?.name || 'User'}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <Link to="/profile" className="dropdown-item">My account</Link>
                      <div className="dropdown-divider"></div>
                      <button onClick={Logout} className="dropdown-item">
                        <i className="bi bi-box-arrow-right"></i> Logout
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <button onClick={togglePopup} className="button is-light">
                  <i className="bi bi-person per"></i>
                  Login
                </button>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      {isOpen && (
        <div className="popup-box">
          <div className="box">
            <span className="close-icon" onClick={handleClose}>×</span>
            <form onSubmit={Auth} className="box">
              <p className="has-text-centered">{msg}</p>
              <div className="field mt-5">
                <div className="controls">
                  <input
                    type="text"
                    className="input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="field mt-5">
                <div className="controls">
                  <input
                    type="password"
                    className="input"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="field mt-5">
                <button className="button is-success is-fullwidth">Login</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navigationbar;