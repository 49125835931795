import { Link } from 'react-router-dom';
import { Chat } from '../../types/chat.ts';
import UserOne from '../../images/user/user-01.png';
import UserTwo from '../../images/user/user-02.png';
import UserThree from '../../images/user/user-03.png';
import UserFour from '../../images/user/user-04.png';
import UserFive from '../../images/user/user-05.png';
import React from 'react';
const chatData: Chat[] = [
  {
    avatar: "@",
    name: 'Besim Zukaj',
    text: 'KsecoCH',
    time: 12,
    textCount: 3,
    color: '#10B981',
  },
];

const ChatCard = () => {
  return (
    <div className="tw-col-span-12 tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-py-6 tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark xl:tw-col-span-4">
      <h4 className="tw-mb-6 tw-px-7.5 tw-text-xl tw-font-semibold tw-text-black dark:tw-text-white">
        Chats
      </h4>

      <div>
        {chatData.map((chat, key) => (
          <Link
            to="/"
            className="tw-flex tw-items-center tw-gap-5 tw-py-3 tw-px-7.5 hover:tw-bg-gray-3 dark:hover:tw-bg-meta-4"
            key={key}
          >
            <div className="tw-relative tw-h-14 tw-w-14 tw-rounded-full">
              <img src={chat.avatar} alt="User" />
              <span
                className="tw-absolute tw-right-0 tw-bottom-0 tw-h-3.5 tw-w-3.5 tw-rounded-full tw-border-2 tw-border-white"
                style={{backgroundColor: chat.color}}
              ></span>
            </div>

            <div className="tw-flex tw-flex-1 tw-items-center tw-justify-between">
              <div>
                <h5 className="tw-font-medium tw-text-black dark:tw-text-white">
                  {chat.name}
                </h5>
                <p>
                  <span className="tw-text-sm tw-text-black dark:tw-text-white">
                    {chat.text}
                  </span>
                  <span className="tw-text-xs"> . {chat.time} min</span>
                </p>
              </div>
              {chat.textCount !== 0 && (
                <div className="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary">
                  <span className="tw-text-sm tw-font-medium tw-text-white">
                    {' '}
                    {chat.textCount}
                  </span>
                </div>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ChatCard;
