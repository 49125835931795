import Breadcrumb from '../components/Breadcrumbs/Breadcrumb.tsx';
import TableOne from '../components/Tables/TableOne.tsx';
import TableThree from '../components/Tables/TableThree.tsx';
import TableTwo from '../components/Tables/TableTwo.tsx';
import React from 'react';
const Tables = () => {
  return (
    <>
      <Breadcrumb pageName="Tables" />

      <div className="tw-flex tw-flex-col tw-gap-10">
        <TableOne />
        <TableTwo />
        <TableThree />
      </div>
    </>
  );
};

export default Tables;
