import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../images/output-onlinepngtools.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const Auth = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMsg('');

    if (!email || !password) {
      setMsg('Please enter both email and password');
      setIsLoading(false);
      return;
    }
    
    try {
      console.log('Attempting login with:', { email });
      const response = await axios.post('https://api.kseco.eu/api/login', {
        email: email,
        password: password,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      if (response.data && response.data.token) {
        localStorage.setItem('TOKEN', response.data.token);
        if (response.data.user) {
          localStorage.setItem('USER', JSON.stringify(response.data.user));
        }
        navigate('/home');
      } else {
        setMsg('Invalid response from server');
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.response) {
        const errorMessage = error.response.data.message || error.response.data.error || 'Authentication failed';
        setMsg(errorMessage);
      } else if (error.request) {
        setMsg('No response from server. Please try again.');
      } else {
        setMsg('Error during login. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const root = document.documentElement;
      root.style.setProperty('--gradient-angle', `${(Date.now() / 50) % 360}deg`);
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-boxdark-2 tw-relative tw-overflow-hidden">
      {/* Animated background with multiple layers */}
      <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
        <div className="tw-absolute tw-inset-0 tw-bg-gradient-to-br tw-from-primary/30 tw-via-meta-5/20 tw-to-meta-3/30 tw-animate-gradient"></div>
        <div className="tw-absolute tw-inset-0 tw-bg-[radial-gradient(circle_at_50%_50%,rgba(60,80,224,0.15)_0%,rgba(0,0,0,0)_100%)]"></div>
        
        {/* Multiple animated circles with different sizes and positions */}
        <div className="tw-absolute tw-top-1/4 tw-left-1/4 tw-w-32 tw-h-32 tw-bg-primary/20 tw-rounded-full tw-blur-3xl tw-animate-pulse-slow"></div>
        <div className="tw-absolute tw-bottom-1/4 tw-right-1/4 tw-w-40 tw-h-40 tw-bg-meta-5/20 tw-rounded-full tw-blur-3xl tw-animate-pulse-slow tw-animation-delay-2000"></div>
        <div className="tw-absolute tw-top-1/2 tw-right-1/3 tw-w-24 tw-h-24 tw-bg-meta-3/20 tw-rounded-full tw-blur-2xl tw-animate-pulse-slow tw-animation-delay-1000"></div>
        <div className="tw-absolute tw-bottom-1/3 tw-left-1/3 tw-w-36 tw-h-36 tw-bg-meta-6/20 tw-rounded-full tw-blur-2xl tw-animate-pulse-slow tw-animation-delay-3000"></div>
      </div>

      {/* Main content container */}
      <div className="tw-w-full tw-max-w-md tw-relative tw-z-10">
        <div className="tw-bg-white/10 tw-backdrop-blur-lg tw-rounded-3xl tw-shadow-2xl tw-p-8 tw-space-y-8 tw-border tw-border-white/20 tw-animate-fade-in">
          {/* Logo or brand section */}
          <div className="tw-text-center tw-space-y-3">
            <div className="tw-flex tw-justify-center tw-mb-6">
              <div className="tw-w-48 tw-h-24 tw-bg-white tw-rounded-2xl tw-flex tw-items-center tw-justify-center tw-shadow-lg tw-transform hover:tw-scale-110 tw-transition-transform tw-duration-300 tw-px-4 tw-py-2">
                <img src={logo} alt="KSECO Logo" className="tw-w-full tw-h-full tw-object-contain" />
              </div>
            </div>
            <h1 className="tw-text-4xl tw-font-bold tw-bg-gradient-to-r tw-from-white tw-to-gray-300 tw-bg-clip-text tw-text-transparent">Welcome Back</h1>
            <p className="tw-text-gray-300 tw-text-lg">Sign in to your account</p>
          </div>

          <form onSubmit={Auth} className="tw-space-y-6">
            {msg && (
              <div className="tw-bg-meta-1/10 tw-border tw-border-meta-1/20 tw-text-meta-1 tw-p-4 tw-rounded-xl tw-text-sm tw-backdrop-blur-sm tw-animate-fade-in">
                <div className="tw-flex tw-items-center tw-space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                  <span>{msg}</span>
                </div>
              </div>
            )}

            <div className="tw-space-y-6">
              {/* Email input group */}
              <div className="tw-space-y-2">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-300">
                  Email Address
                </label>
                <div className="tw-relative tw-group">
                  <input
                    type="email"
                    className="tw-w-full tw-pl-12 tw-pr-4 tw-py-4 tw-rounded-xl tw-border tw-border-white/10 tw-bg-white/5 tw-backdrop-blur-sm tw-outline-none focus:tw-border-primary/50 tw-transition-all tw-duration-300 tw-text-white placeholder:tw-text-gray-400 hover:tw-border-white/30"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    disabled={isLoading}
                  />
                  <span className="tw-absolute tw-left-4 tw-top-4 tw-text-gray-400 tw-transition-transform tw-duration-300 group-hover:tw-scale-110">
                    <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                    </svg>
                  </span>
                </div>
              </div>

              {/* Password input group */}
              <div className="tw-space-y-2">
                <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-300">
                  Password
                </label>
                <div className="tw-relative tw-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="tw-w-full tw-pl-12 tw-pr-12 tw-py-4 tw-rounded-xl tw-border tw-border-white/10 tw-bg-white/5 tw-backdrop-blur-sm tw-outline-none focus:tw-border-primary/50 tw-transition-all tw-duration-300 tw-text-white placeholder:tw-text-gray-400 hover:tw-border-white/30"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    disabled={isLoading}
                  />
                  <span className="tw-absolute tw-left-4 tw-top-4 tw-text-gray-400 tw-transition-transform tw-duration-300 group-hover:tw-scale-110">
                    <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </span>
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="tw-absolute tw-right-4 tw-top-4 tw-text-gray-400 hover:tw-text-gray-300 tw-transition-colors tw-duration-300"
                  >
                    {showPassword ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>

            {/* Remember me and Forgot password */}
            <div className="tw-flex tw-items-center tw-justify-between tw-text-sm">
              <label className="tw-flex tw-items-center tw-space-x-2 tw-text-gray-300 hover:tw-text-white tw-transition-colors tw-cursor-pointer">
                <input type="checkbox" className="tw-rounded tw-border-gray-400 tw-text-primary tw-bg-transparent" />
                <span>Remember me</span>
              </label>
              <a href="#" className="tw-text-primary hover:tw-text-primary/80 tw-transition-colors">Forgot password?</a>
            </div>

            {/* Sign in button */}
            <button
              type="submit"
              disabled={isLoading}
              className={`tw-w-full tw-bg-gradient-to-r tw-from-primary tw-to-meta-5 tw-text-white tw-py-4 tw-px-6 tw-rounded-xl tw-font-semibold tw-transition-all tw-duration-300 tw-transform hover:tw-scale-[1.02] hover:tw-shadow-lg tw-relative tw-overflow-hidden
                ${isLoading ? 'tw-opacity-70 tw-cursor-not-allowed' : 'hover:tw-translate-y-[-2px] active:tw-scale-[0.98]'}
                before:tw-content-[''] before:tw-absolute before:tw-top-0 before:tw-left-0 before:tw-w-full before:tw-h-full before:tw-bg-gradient-to-r before:tw-from-transparent before:tw-via-white/20 before:tw-to-transparent before:tw-translate-x-[-100%] hover:before:tw-translate-x-[100%] before:tw-transition-transform before:tw-duration-700
              `}
            >
              {isLoading ? (
                <div className="tw-flex tw-items-center tw-justify-center tw-space-x-3">
                  <svg className="tw-animate-spin tw-h-5 tw-w-5 tw-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span>Signing in...</span>
                </div>
              ) : (
                'Sign In'
              )}
            </button>

            {/* Sign up link */}
            <div className="tw-text-center tw-text-gray-300">
              Don't have an account? <a href="#" className="tw-text-primary hover:tw-text-primary/80 tw-transition-colors">Sign up</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
