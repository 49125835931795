import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";

import {
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
} from "../assets/images/index";

import logoImg from "../../../images/output-onlinepngtools.png";

const BestSellers = () => {
  return (
    <div className="tw-w-full tw-py-10 tw-bg-gray-50 tw-px-4 tw-space-y-8">  {/* Added padding, background, and spacing */}
      <Heading heading="Our Bestsellers" />
      <div className="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lgl:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-8">
        <Product
          _id="1011"
          img={logoImg}
          productName="Plan 1"
          price="35.00"
          color="Data"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
        <Product
          _id="1012"
          img={logoImg}
          productName="Plan 2"
          price="180.00"
          color="KsecoCH"
          badge={false}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
        <Product
          _id="1013"
          img={logoImg}
          productName="Plan 3"
          price="25.00"
          color="KsecoCH"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
        <Product
          _id="1014"
          img={logoImg}
          productName="Plan 4"
          price="220.00"
          color="KsecoCH"
          badge={false}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
      </div>
    </div>
  );
};

export default BestSellers;
