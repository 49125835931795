import React, { ReactNode, useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar/index.tsx';
import Header from '../components/Header/index.tsx';

const DefaultLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth >= 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  console.log(sidebarOpen)
  return (
    <div className="tw-flex tw-h-screen tw-overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className={`tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-overflow-x-hidden ${sidebarOpen ? 'lg:tw-ml-72.5' : 'lg:tw-ml-0'}`}>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="tw-p-6">
          <div className={`tw-max-w-screen-xl tw-mx-auto tw-px-4 lg:tw-px-16 ${sidebarOpen ? 'lg:tw-ml-20 lg:tw-pl-72.5' : ''}`}>
            <div className="tw-flex tw-justify-start lg:tw-justify-center">
              {children}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};


export default DefaultLayout;