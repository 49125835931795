import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { pdf } from '@react-pdf/renderer';
import QRCode from 'qrcode';

// Define colors
const colors = {
  primary: '#2e4a71',
  primaryLight: '#4a6694', // Lighter version of primary color
  white: '#FFFFFF',
  lightGray: '#F5F5F5',
  borderGray: '#E0E0E0',
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingBottom: 120,
    fontSize: 8,
    backgroundColor: colors.white,
    fontFamily: 'Helvetica',
    position: 'relative',
  },
  watermarkContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
  watermarkImage: {
    width: 100,
    height: 50,
    opacity: 0.12,
  },
  watermarkTopLeft: {
    position: 'absolute',
    top: '20%',
    left: '20%',
    transform: 'rotate(-30deg)',
  },
  watermarkTopRight: {
    position: 'absolute',
    top: '20%',
    right: '20%',
    transform: 'rotate(30deg)',
  },
  watermarkCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(1.2) rotate(0deg)',
  },
  watermarkBottomLeft: {
    position: 'absolute',
    bottom: '20%',
    left: '20%',
    transform: 'rotate(30deg)',
  },
  watermarkBottomRight: {
    position: 'absolute',
    bottom: '20%',
    right: '20%',
    transform: 'rotate(-30deg)',
  },
  header: {
    marginBottom: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 0.5,
    borderColor: colors.borderGray,
    paddingBottom: 10,
  },
  headerLogo: {
    width: 90,
    height: 40,
    objectFit: 'contain',
  },
  headerTitle: {
    fontSize: 14,
    color: colors.primary,
    fontFamily: 'Helvetica-Bold',
  },
  companyInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 0.5,
    borderColor: colors.borderGray,
    paddingVertical: 5,
    marginBottom: 10,
  },
  infoCell: {
    flexDirection: 'column',
  },
  infoLabel: {
    fontSize: 8,
    color: colors.primary,
    marginBottom: 2,
    fontFamily: 'Helvetica-Bold',
  },
  infoValue: {
    fontSize: 8,
  },
  pageNumber: {
    fontSize: 10,
    color: colors.primary,
    position: 'absolute',
    right: 30,
    top: 30,
  },
  dataHeader: {
    fontSize: 9,
    color: colors.primary,
    marginBottom: 5,
    fontFamily: 'Helvetica-Bold',
  },
  table: {
    width: '100%',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderColor: colors.borderGray,
    minHeight: 16,
    alignItems: 'center',
  },
  tableHeader: {
    backgroundColor: colors.primaryLight,
    padding: 4,
  },
  tableHeaderText: {
    color: colors.white,
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
  },
  tableCell: {
    padding: 4,
  },
  tableCellText: {
    fontSize: 8,
    color: colors.primary,
  },
  strikethrough: {
    textDecoration: 'line-through',
    color: '#666666',
  },
  infoText: {
    fontSize: 8,
    color: colors.primary,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    paddingTop: 10,
    borderTopWidth: 0.5,
    borderColor: colors.borderGray,
    marginHorizontal: 20,
  },
  footerPageNumber: {
    fontSize: 10,
    color: colors.primary,
  },
  securitySection: {
    position: 'absolute',
    bottom: 40,
    left: 20,
    right: 20,
    borderTop: 1,
    borderColor: colors.borderGray,
    paddingTop: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  securityText: {
    fontSize: 6,
    color: colors.primary,
    fontFamily: 'Helvetica',
  },
  securityCode: {
    fontSize: 7,
    fontFamily: 'Helvetica-Bold',
    color: colors.primary,
  },
  barcodeContainer: {
    alignItems: 'center',
    marginLeft: 'auto',
  },
  barcodeText: {
    fontSize: 5,
    color: colors.primary,
    marginTop: 2,
  },
});

const generateSecurityCode = (data) => {
  const timestamp = new Date().getTime();
  const companyId = data.id || '';
  const randomPart = Math.random().toString(36).substring(2, 8).toUpperCase();
  return `KSC-${timestamp.toString().slice(-6)}-${randomPart}-${companyId.slice(0, 6)}`;
};

const generateQRContent = (data, securityCode) => {
  return JSON.stringify({
    documentType: 'KSECO_COMPANY_EXTRACT',
    companyId: data.id,
    companyName: data.companyName,
    che: data.che,
    securityCode: securityCode,
    generatedAt: new Date().toISOString(),
    verificationUrl: `https://kseco.ch/verify/${securityCode}`
  });
};

const generateQRCodeImage = async (data) => {
  const securityCode = generateSecurityCode(data);
  const qrContent = generateQRContent(data, securityCode);
  try {
    const qrDataUrl = await QRCode.toDataURL(qrContent, {
      width: 100,
      margin: 2,
      errorCorrectionLevel: 'H'
    });
    return { qrDataUrl, securityCode };
  } catch (err) {
    console.error('Error generating QR code:', err);
    return null;
  }
};

const CompanyRegisterPDF = async ({ data, sectors }) => {
  console.log('CompanyRegisterPDF received data:', {
    current: data.current,
    history: data.history,
    companyBasicInfo: data.companyBasicInfo
  });
  const { qrDataUrl, securityCode } = await generateQRCodeImage(data);

  const { current, history, companyBasicInfo, companyHistory } = data;

  const getAllEntries = (field) => {
    console.log(`Processing ${field} with current data:`, current[field]);
    console.log(`Processing ${field} with history data:`, history);
    const entries = [];

    // Helper function to ensure we're working with arrays
    const ensureArray = (data) => {
      if (!data) return [];
      return Array.isArray(data) ? data : [data];
    };

    // Helper function to check if an item is active
    const isActive = (item) => {
      const activeFlags = [
        'activeData',
        'activeDataPerson',
        'activeDataShares',
        'activeDataCapital',
        'activeDataPurpose',
        'activeDataAddress',
        'activeDataRemark',
        'activeDataPublication',
        'activeDataRegistration',
        'activeDataCity',
        'activeDataDateOfAct'
      ];
      return activeFlags.some(flag => item[flag]) || false;
    };

    console.log(`Getting entries for ${field}:`, {
      current: current[field],
      history: history?.map(h => h[field])
    });

    // Add current entries
    if (current && current[field]) {
      const currentEntries = ensureArray(current[field]).map(item => ({ 
        ...item, 
        current: isActive(item) || true,
        date: current.dateOfRegistration || current.date
      }));
      entries.push(...currentEntries);
    }

    // Add historical entries
    if (history && history.length > 0) {
      history.forEach(historyItem => {
        if (!historyItem) return;

        const processHistoryEntry = (entry) => ({
          ...entry,
          current: isActive(entry),
          date: historyItem.dateOfRegistration || historyItem.date,
          CompanyId: historyItem.id || historyItem.CompanyId
        });

        if (field === 'publications' && historyItem.publications) {
          const publicationEntries = ensureArray(historyItem.publications).map(pub => ({
            ...processHistoryEntry(pub),
            refPublication: pub.refPublication || '0',
            journalNumber: pub.journalNumber?.trim() || '',
            SOGCNumber: pub.SOGCNumber?.trim() || '',
            journalDate: pub.journalDate || '',
            SOGCDate: pub.SOGCDate || ''
          }));
          entries.push(...publicationEntries);
        } else if (field === 'companyNames' && historyItem.companyName) {
          console.log('Processing company name:', {
            name: historyItem.companyName,
            ca: historyItem.ca,
            isActive: isActive(historyItem)
          });
          entries.push({
            name: historyItem.companyName,
            ca: historyItem.ca || '',
            current: isActive(historyItem),
            date: historyItem.dateOfRegistration || historyItem.date,
            registrationOffice: historyItem.city ? `${historyItem.city.city} (${historyItem.city.ref})` : ''
          });
        } else if (historyItem[field]) {
          const historyEntries = ensureArray(historyItem[field]).map(processHistoryEntry);
          entries.push(...historyEntries);
        }
      });
    }

    // Sort entries by date, most recent first
    const sortedEntries = entries.sort((a, b) => {
      const dateA = new Date(a.date || a.dateOfRegistration || '');
      const dateB = new Date(b.date || b.dateOfRegistration || '');
      return dateB - dateA;
    });

    // Remove duplicates based on relevant fields
    const uniqueEntries = sortedEntries.filter((entry, index, self) => 
      index === self.findIndex((e) => (
        e.id === entry.id && 
        e.date === entry.date && 
        JSON.stringify(e) === JSON.stringify(entry)
      ))
    );

    console.log(`Final sorted ${field} entries:`, uniqueEntries);
    return uniqueEntries;
  };

  const getRegistrationOfficeEntries = (data) => {
    const entries = [];
    
    // Add current entry if it exists
    if (data.registrationOffice) {
      entries.push({
        refOffice: data.registrationOffice.refOffice,
        registrationOffice: data.registrationOffice.officialPublication,
        current: data.registrationOffice.activeDataRegistration
      });
    }

    // Add historical entries if they exist
    if (data.history && Array.isArray(data.history)) {
      data.history.forEach(hist => {
        if (hist.registrationOffice) {
          entries.push({
            refOffice: hist.registrationOffice.refOffice,
            registrationOffice: hist.registrationOffice.officialPublication,
            current: false
          });
        }
      });
    }

    // Sort entries by refOffice
    return entries.sort((a, b) => (a.refOffice || 0) - (b.refOffice || 0));
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Watermark Layer */}
        <View style={styles.watermarkContainer} fixed>
          <Image 
            src={require('../images/output-onlinepngtools.png')} 
            style={[styles.watermarkImage, styles.watermarkTopLeft]} 
          />
          <Image 
            src={require('../images/output-onlinepngtools.png')} 
            style={[styles.watermarkImage, styles.watermarkTopRight]} 
          />
          <Image 
            src={require('../images/output-onlinepngtools.png')} 
            style={[styles.watermarkImage, styles.watermarkCenter]} 
          />
          <Image 
            src={require('../images/output-onlinepngtools.png')} 
            style={[styles.watermarkImage, styles.watermarkBottomLeft]} 
          />
          <Image 
            src={require('../images/output-onlinepngtools.png')} 
            style={[styles.watermarkImage, styles.watermarkBottomRight]} 
          />
        </View>
        
        {/* Existing Content */}
        <View style={styles.header}>
          <Image src={require('../images/output-onlinepngtools.png')} style={styles.headerLogo} />
          <Text style={styles.headerTitle}>KSECO</Text>
        </View>

        <View style={styles.companyInfo}>
          <View style={styles.infoCell}>
            <Text style={styles.infoLabel}>Company No.</Text>
            <Text style={styles.infoValue}>{companyBasicInfo.companyNo}</Text>
          </View>
          <View style={styles.infoCell}>
            <Text style={styles.infoLabel}>Legal form</Text>
            <Text style={styles.infoValue}>{companyBasicInfo.legalForm}</Text>
          </View>
          <View style={styles.infoCell}>
            <Text style={styles.infoLabel}>Registered</Text>
            <Text style={styles.infoValue}>{companyBasicInfo.registeredDate}</Text>
          </View>
          <View style={styles.infoCell}>
            <Text style={styles.infoLabel}>Transfer CH</Text>
            <Text style={styles.infoValue}>{companyBasicInfo.transferCH || ''}</Text>
          </View>
        </View>

        <Text style={styles.dataHeader}>All data</Text>

        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={[styles.tableCell, { width: '8%' }]}>
              <Text style={styles.tableHeaderText}>Ent</Text>
            </View>
            <View style={[styles.tableCell, { width: '8%' }]}>
              <Text style={styles.tableHeaderText}>Ca</Text>
            </View>
            <View style={[styles.tableCell, { width: '64%' }]}>
              <Text style={styles.tableHeaderText}>Registered name</Text>
            </View>
            <View style={[styles.tableCell, { width: '20%' }]}>
              <Text style={styles.tableHeaderText}>Registered office</Text>
            </View>
          </View>
          {getAllEntries('companyNames').map((name, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={[styles.tableCell, { width: '8%' }]}>
                <Text style={styles.tableCellText}>{index + 1}</Text>
              </View>
              <View style={[styles.tableCell, { width: '8%' }]}>
                <Text style={styles.tableCellText}>{name.ca || ''}</Text>
              </View>
              <View style={[styles.tableCell, { width: '64%' }]}>
                <Text style={[styles.tableCellText, !name.current && styles.strikethrough]}>
                  {name.name}
                </Text>
              </View>
              <View style={[styles.tableCell, { width: '20%' }]}>
                <Text style={styles.tableCellText}>{name.registrationOffice}</Text>
              </View>
            </View>
          ))}
        </View>

        {/* Shares and Capital Tables Row */}
        <View style={{ flexDirection: 'row', gap: 10, marginBottom: 10 }}>
          {/* Shares Table */}
          <View style={[styles.table, { flex: 1 }]}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={[styles.tableCell, { width: '10%' }]}>
                <Text style={styles.tableHeaderText}>Ent</Text>
              </View>
              <View style={[styles.tableCell, { width: '10%' }]}>
                <Text style={styles.tableHeaderText}>Ca</Text>
              </View>
              <View style={[styles.tableCell, { width: '10%' }]}>
                <Text style={styles.tableHeaderText}>Mo</Text>
              </View>
              <View style={[styles.tableCell, { width: '35%' }]}>
                <Text style={styles.tableHeaderText}>Shares</Text>
              </View>
              <View style={[styles.tableCell, { width: '35%' }]}>
                <Text style={styles.tableHeaderText}>Partner</Text>
              </View>
            </View>
            {getAllEntries('shares').map((share, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={[styles.tableCell, { width: '10%' }]}>
                  <Text style={styles.tableCellText}>{index + 1}</Text>
                </View>
                <View style={[styles.tableCell, { width: '10%' }]}>
                  <Text style={styles.tableCellText}>{share.caShare || '0'}</Text>
                </View>
                <View style={[styles.tableCell, { width: '10%' }]}>
                  <Text style={styles.tableCellText}>{share.moShare || '0'}</Text>
                </View>
                <View style={[styles.tableCell, { width: '35%' }]}>
                  <Text style={[styles.tableCellText, !share.current && styles.strikethrough]}>
                    {share.shares || 'N/A'}
                  </Text>
                </View>
                <View style={[styles.tableCell, { width: '35%' }]}>
                  <Text style={[styles.tableCellText, !share.current && styles.strikethrough]}>
                    {share.partner || 'N/A'}
                  </Text>
                </View>
              </View>
            ))}
          </View>

          {/* Capital Table */}
          <View style={[styles.table, { flex: 1 }]}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={[styles.tableCell, { width: '10%' }]}>
                <Text style={styles.tableHeaderText}>Ent</Text>
              </View>
              <View style={[styles.tableCell, { width: '10%' }]}>
                <Text style={styles.tableHeaderText}>Ca</Text>
              </View>
              <View style={[styles.tableCell, { width: '80%' }]}>
                <Text style={styles.tableHeaderText}>Capital</Text>
              </View>
            </View>
            {getAllEntries('capital').map((capital, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={[styles.tableCell, { width: '10%' }]}>
                  <Text style={styles.tableCellText}>{index + 1}</Text>
                </View>
                <View style={[styles.tableCell, { width: '10%' }]}>
                  <Text style={styles.tableCellText}>{capital.caCapital || '0'}</Text>
                </View>
                <View style={[styles.tableCell, { width: '80%' }]}>
                  <Text style={[styles.tableCellText, !capital.current && styles.strikethrough]}>
                    {capital.capital || 'N/A'}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        {/* Purpose and Additional Addresses Tables Row */}
        <View style={{ flexDirection: 'row', gap: 10, marginBottom: 10 }}>
          {/* Purpose Table */}
          <View style={[styles.table, { flex: 3 }]}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={[styles.tableCell, { width: '6%' }]}>
                <Text style={styles.tableHeaderText}>Ent</Text>
              </View>
              <View style={[styles.tableCell, { width: '6%' }]}>
                <Text style={styles.tableHeaderText}>Ca</Text>
              </View>
              <View style={[styles.tableCell, { width: '48%' }]}>
                <Text style={styles.tableHeaderText}>Purpose</Text>
              </View>
              <View style={[styles.tableCell, { width: '20%' }]}>
                <Text style={styles.tableHeaderText}>Sector</Text>
              </View>
              <View style={[styles.tableCell, { width: '20%' }]}>
                <Text style={styles.tableHeaderText}>Service</Text>
              </View>
            </View>
            {getAllEntries('purposes').map((purpose, index) => {
              // Match sector ID with sectors data
              const sectorId = parseInt(purpose.sectorId);
              const sectorName = sectors?.data?.find?.(s => s.id === sectorId)?.name || 
                               sectors?.find?.(s => s.id === sectorId)?.name || 
                               'N/A';
              return (
                <View style={styles.tableRow} key={index}>
                  <View style={[styles.tableCell, { width: '6%' }]}>
                    <Text style={styles.tableCellText}>{index + 1}</Text>
                  </View>
                  <View style={[styles.tableCell, { width: '6%' }]}>
                    <Text style={styles.tableCellText}>{purpose.caPurpose || ''}</Text>
                  </View>
                  <View style={[styles.tableCell, { width: '48%' }]}>
                    <Text style={[styles.tableCellText, !purpose.current && styles.strikethrough]}>
                      {purpose.purpose || purpose.service?.name || 'N/A'}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { width: '20%' }]}>
                    <Text style={[styles.tableCellText, !purpose.current && styles.strikethrough]}>
                      {sectorName}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { width: '20%' }]}>
                    <Text style={[styles.tableCellText, !purpose.current && styles.strikethrough]}>
                      {purpose.service?.name || 'N/A'}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>

          {/* Additional Addresses and Date of Acts Column */}
          <View style={[{ flex: 1 }]}>
            {/* Additional Addresses Table */}
            <View style={[styles.table, { marginBottom: 10 }]}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <View style={[styles.tableCell, { width: '10%' }]}>
                  <Text style={styles.tableHeaderText}>Ent</Text>
                </View>
                <View style={[styles.tableCell, { width: '10%' }]}>
                  <Text style={styles.tableHeaderText}>Ca</Text>
                </View>
                <View style={[styles.tableCell, { width: '80%' }]}>
                  <Text style={styles.tableHeaderText}>Additional addresses</Text>
                </View>
              </View>
              {getAllEntries('addresses').map((address, index) => (
                <View style={styles.tableRow} key={index}>
                  <View style={[styles.tableCell, { width: '10%' }]}>
                    <Text style={styles.tableCellText}>{address.inAddress || '0'}</Text>
                  </View>
                  <View style={[styles.tableCell, { width: '10%' }]}>
                    <Text style={styles.tableCellText}>{address.caAddress || '0'}</Text>
                  </View>
                  <View style={[styles.tableCell, { width: '80%' }]}>
                    <Text style={[styles.tableCellText, !address.current && styles.strikethrough]}>
                      {address.address}
                    </Text>
                  </View>
                </View>
              ))}
            </View>

            {/* Date of Acts Table */}
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <View style={[styles.tableCell, { width: '15%' }]}>
                  <Text style={styles.tableHeaderText}>Ref</Text>
                </View>
                <View style={[styles.tableCell, { width: '85%' }]}>
                  <Text style={styles.tableHeaderText}>Date of Acts</Text>
                </View>
              </View>
              {getAllEntries('dateOfActs').map((act, index) => (
                <View style={styles.tableRow} key={index}>
                  <View style={[styles.tableCell, { width: '15%' }]}>
                    <Text style={styles.tableCellText}>{act.refDateOfActs || '0'}</Text>
                  </View>
                  <View style={[styles.tableCell, { width: '85%' }]}>
                    <Text style={[styles.tableCellText, !act.current && styles.strikethrough]}>
                      {act.dateOfAct || 'N/A'}
                    </Text>
                  </View>
                </View>
              ))}
            </View>

            {/* Registration Office Table */}
            <View style={[styles.table, { marginTop: 8 }]}>
              <View style={[styles.tableRow, styles.tableHeader, { minHeight: 14 }]}>
                <View style={[styles.tableCell, { width: '10%', padding: 3 }]}>
                  <Text style={[styles.tableHeaderText, { fontSize: 7 }]}>Ref</Text>
                </View>
                <View style={[styles.tableCell, { width: '90%', padding: 3 }]}>
                  <Text style={[styles.tableHeaderText, { fontSize: 7 }]}>Office</Text>
                </View>
              </View>
              {getRegistrationOfficeEntries(data).map((office, index) => (
                <View style={[styles.tableRow, { minHeight: 14 }]} key={index}>
                  <View style={[styles.tableCell, { width: '10%', padding: 3 }]}>
                    <Text style={[styles.tableCellText, { fontSize: 7 }]}>
                      {office.refOffice || 'N/A'}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { width: '90%', padding: 3 }]}>
                    <Text style={[styles.tableCellText, !office.current && styles.strikethrough, { fontSize: 7 }]}>
                      {office.registrationOffice || 'N/A'}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>

        {/* Persons Table */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={[styles.tableCell, { width: '6%' }]}>
              <Text style={styles.tableHeaderText}>Ent</Text>
            </View>
            <View style={[styles.tableCell, { width: '6%' }]}>
              <Text style={styles.tableHeaderText}>Ca</Text>
            </View>
            <View style={[styles.tableCell, { width: '6%' }]}>
              <Text style={styles.tableHeaderText}>Mo</Text>
            </View>
            <View style={[styles.tableCell, { width: '42%' }]}>
              <Text style={styles.tableHeaderText}>Personal Data</Text>
            </View>
            <View style={[styles.tableCell, { width: '20%' }]}>
              <Text style={styles.tableHeaderText}>Function</Text>
            </View>
            <View style={[styles.tableCell, { width: '20%' }]}>
              <Text style={styles.tableHeaderText}>Signature</Text>
            </View>
          </View>
          {getAllEntries('persons').map((person, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={[styles.tableCell, { width: '6%' }]}>
                <Text style={styles.tableCellText}>{index + 1}</Text>
              </View>
              <View style={[styles.tableCell, { width: '6%' }]}>
                <Text style={styles.tableCellText}>{person.caPerson || ''}</Text>
              </View>
              <View style={[styles.tableCell, { width: '6%' }]}>
                <Text style={styles.tableCellText}>{person.moPerson || ''}</Text>
              </View>
              <View style={[styles.tableCell, { width: '42%' }]}>
                <Text style={[styles.tableCellText, !person.current && styles.strikethrough]}>
                  {person.personalData || 'N/A'}
                </Text>
              </View>
              <View style={[styles.tableCell, { width: '20%' }]}>
                <Text style={[styles.tableCellText, !person.current && styles.strikethrough]}>
                  {person.function || 'N/A'}
                </Text>
              </View>
              <View style={[styles.tableCell, { width: '20%' }]}>
                <Text style={[styles.tableCellText, !person.current && styles.strikethrough]}>
                  {person.signature || 'N/A'}
                </Text>
              </View>
            </View>
          ))}
        </View>

        {/* Remarks Table */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={[styles.tableCell, { width: '8%' }]}>
              <Text style={styles.tableHeaderText}>Ent</Text>
            </View>
            <View style={[styles.tableCell, { width: '8%' }]}>
              <Text style={styles.tableHeaderText}>Ca</Text>
            </View>
            <View style={[styles.tableCell, { width: '84%' }]}>
              <Text style={styles.tableHeaderText}>Remarks</Text>
            </View>
          </View>
          {getAllEntries('remarks').map((remark, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={[styles.tableCell, { width: '8%' }]}>
                <Text style={styles.tableCellText}>{index + 1}</Text>
              </View>
              <View style={[styles.tableCell, { width: '8%' }]}>
                <Text style={styles.tableCellText}>{remark.ca || ''}</Text>
              </View>
              <View style={[styles.tableCell, { width: '84%' }]}>
                <Text style={[styles.tableCellText, !remark.current && styles.strikethrough]}>
                  {remark.remark}
                </Text>
              </View>
            </View>
          ))}
        </View>

        {/* Publications Table */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={[styles.tableCell, { width: '10%' }]}>
              <Text style={styles.tableHeaderText}>Ref</Text>
            </View>
            <View style={[styles.tableCell, { width: '25%' }]}>
              <Text style={styles.tableHeaderText}>Journal Date</Text>
            </View>
            <View style={[styles.tableCell, { width: '25%' }]}>
              <Text style={styles.tableHeaderText}>SOGC Date</Text>
            </View>
            <View style={[styles.tableCell, { width: '20%' }]}>
              <Text style={styles.tableHeaderText}>Journal Number</Text>
            </View>
            <View style={[styles.tableCell, { width: '20%' }]}>
              <Text style={styles.tableHeaderText}>SOGC Number</Text>
            </View>
          </View>
          {getAllEntries('publications').map((publication, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={[styles.tableCell, { width: '10%' }]}>
                <Text style={styles.tableCellText}>{publication.refPublication || '0'}</Text>
              </View>
              <View style={[styles.tableCell, { width: '25%' }]}>
                <Text style={[styles.tableCellText, !publication.current && styles.strikethrough]}>
                  {new Date(publication.journalDate).toLocaleDateString()}
                </Text>
              </View>
              <View style={[styles.tableCell, { width: '25%' }]}>
                <Text style={[styles.tableCellText, !publication.current && styles.strikethrough]}>
                  {new Date(publication.SOGCDate).toLocaleDateString()}
                </Text>
              </View>
              <View style={[styles.tableCell, { width: '20%' }]}>
                <Text style={[styles.tableCellText, !publication.current && styles.strikethrough]}>
                  {publication.journalNumber?.trim()}
                </Text>
              </View>
              <View style={[styles.tableCell, { width: '20%' }]}>
                <Text style={[styles.tableCellText, !publication.current && styles.strikethrough]}>
                  {publication.SOGCNumber?.trim()}
                </Text>
              </View>
            </View>
          ))}
        </View>

        {/* Branch Offices Table */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={[styles.tableCell, { width: '8%' }]}>
              <Text style={styles.tableHeaderText}>Ent</Text>
            </View>
            <View style={[styles.tableCell, { width: '8%' }]}>
              <Text style={styles.tableHeaderText}>Ca</Text>
            </View>
            <View style={[styles.tableCell, { width: '84%' }]}>
              <Text style={styles.tableHeaderText}>Branch office(s)</Text>
            </View>
          </View>
          {getAllEntries('branchOffices').map((office, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={[styles.tableCell, { width: '8%' }]}>
                <Text style={styles.tableCellText}>{index + 1}</Text>
              </View>
              <View style={[styles.tableCell, { width: '8%' }]}>
                <Text style={styles.tableCellText}>{office.ca || ''}</Text>
              </View>
              <View style={[styles.tableCell, { width: '84%' }]}>
                <Text style={[styles.tableCellText, !office.current && styles.strikethrough]}>
                  {office.address}
                </Text>
              </View>
            </View>
          ))}
        </View>

        {/* Security Section */}
        <View style={styles.securitySection} fixed>
          <View>
            <Text style={styles.securityText}>Document Security Information</Text>
            <Text style={styles.securityCode}>{securityCode}</Text>
            <Text style={styles.securityText}>
              Generated: {new Date().toISOString().split('T')[0]}
            </Text>
            <Text style={styles.securityText}>
              This document contains security features. Verify authenticity at kseco.ch/verify
            </Text>
          </View>
          <View style={styles.barcodeContainer}>
            <Image
              src={qrDataUrl}
              style={{
                width: 50,
                height: 50,
              }}
            />
            <Text style={styles.barcodeText}>Scan to verify authenticity</Text>
          </View>
        </View>

        {/* Separator line */}
        <View style={{ borderTopWidth: 1, borderColor: colors.borderGray, marginVertical: 20 }} />

        {/* Ending Section */}
        <View style={{ marginTop: 10 }}>
          {/* Date and Location */}
          <Text style={[styles.infoText, { marginBottom: 15 }]}>
            KSECO, {new Date().toLocaleDateString('de-CH', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </Text>

          {/* Improved Abbreviations Section */}
          <View style={[styles.table, { marginBottom: 20, backgroundColor: colors.lightGray, padding: 10, borderRadius: 4 }]}>
            <Text style={[styles.tableHeaderText, { 
              marginBottom: 15, 
              fontSize: 10,
              color: colors.primary,
              borderBottom: 1,
              borderColor: colors.primaryLight,
              paddingBottom: 5
            }]}>
              List of abbreviations
            </Text>
            
            {/* Abbreviations Grid */}
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>
              {[
                { abbr: 'Ent', desc: 'Entry reference number' },
                { abbr: 'Mo', desc: 'Modification reference number' },
                { abbr: 'Ca', desc: 'Cancellation reference number' },
                { abbr: 'Journal date', desc: 'Daily register entry date' },
                { abbr: 'SOGC', desc: 'Swiss Official Gazette of Commerce' },
                { abbr: 'Ref', desc: 'Reference number' },
                { abbr: 'Journal No.', desc: 'Daily register number' }
              ].map((item, index) => (
                <View key={index} style={{ 
                  flexBasis: '48%', 
                  flexDirection: 'row', 
                  alignItems: 'center',
                  paddingVertical: 4
                }}>
                  <Text style={[styles.tableCellText, { 
                    width: '30%', 
                    fontFamily: 'Helvetica-Bold',
                    color: colors.primary
                  }]}>
                    {item.abbr}
                  </Text>
                  <Text style={[styles.tableCellText, { width: '70%' }]}>
                    {item.desc}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          {/* Disclaimer with improved styling */}
          <View style={{ 
            backgroundColor: colors.lightGray, 
            padding: 15, 
            borderRadius: 4,
            marginBottom: 20
          }}>
            <Text style={[styles.infoText, { 
              textAlign: 'justify',
              lineHeight: 1.5,
              color: colors.primary
            }]}>
              The above information is given without commitment and has not legal effect. Only the official company record Kseco issued and certified by the commercial register of Aargau and published in the Swiss Commercial Gazette is legally binding.
            </Text>
          </View>

          {/* Footer with logo */}
          <View style={{ 
            flexDirection: 'row', 
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 10,
            paddingTop: 10,
            borderTopWidth: 0.5,
            borderColor: colors.borderGray
          }}>
            <Image
              src="/logo.png"
              style={[styles.headerLogo, { width: 60, height: 30 }]}
            />
          </View>
        </View>

        {/* Footer with page numbers */}
        <View style={styles.footer} fixed>
          <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => (
            `Page ${pageNumber} of ${totalPages}`
          )} />
        </View>
      </Page>
    </Document>
  );
};

export const generatePDF = async (data, sectors) => {
  try {
    const pdfDoc = await CompanyRegisterPDF({ data, sectors });
    const blob = await pdf(pdfDoc).toBlob();
    const url = URL.createObjectURL(blob);

    // Get CHE number from either current company or basic info
    const cheNumber = data.current?.ca || data.current?.CHE || data.companyBasicInfo?.companyNo || 'company';
    const filename = `${cheNumber}-register.pdf`;
    
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};