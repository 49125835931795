import Breadcrumb from '../components/Breadcrumbs/Breadcrumb.tsx';
import React from 'react';

const Calendar = () => {
  return (
    <>
      <Breadcrumb pageName="Calendar" />

      {/* <!-- ====== Calendar Section Start ====== --> */}
      <div className="tw-w-full tw-max-w-full tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark">
        <table className="tw-w-full">
          <thead>
            <tr className="tw-grid tw-grid-cols-7 tw-rounded-t-sm tw-bg-primary tw-text-white">
              <th className="tw-flex tw-h-15 tw-items-center tw-justify-center tw-rounded-tl-sm tw-p-1 tw-text-xs tw-font-semibold sm:tw-text-base xl:tw-p-5">
                <span className="tw-hidden lg:tw-block"> Sunday </span>
                <span className="tw-block lg:tw-hidden"> Sun </span>
              </th>
              <th className="tw-flex tw-h-15 tw-items-center tw-justify-center tw-p-1 tw-text-xs tw-font-semibold sm:tw-text-base xl:tw-p-5">
                <span className="tw-hidden lg:tw-block"> Monday </span>
                <span className="tw-block lg:tw-hidden"> Mon </span>
              </th>
              <th className="tw-flex tw-h-15 tw-items-center tw-justify-center tw-p-1 tw-text-xs tw-font-semibold sm:tw-text-base xl:tw-p-5">
                <span className="tw-hidden lg:tw-block"> Tuesday </span>
                <span className="tw-block lg:tw-hidden"> Tue </span>
              </th>
              <th className="tw-flex tw-h-15 tw-items-center tw-justify-center tw-p-1 tw-text-xs tw-font-semibold sm:tw-text-base xl:tw-p-5">
                <span className="tw-hidden lg:tw-block"> Wednesday </span>
                <span className="tw-block lg:tw-hidden"> Wed </span>
              </th>
              <th className="tw-flex tw-h-15 tw-items-center tw-justify-center tw-p-1 tw-text-xs tw-font-semibold sm:tw-text-base xl:tw-p-5">
                <span className="tw-hidden lg:tw-block"> Thursday </span>
                <span className="tw-block lg:tw-hidden"> Thur </span>
              </th>
              <th className="tw-flex tw-h-15 tw-items-center tw-justify-center tw-p-1 tw-text-xs tw-font-semibold sm:tw-text-base xl:tw-p-5">
                <span className="tw-hidden lg:tw-block"> Friday </span>
                <span className="tw-block lg:tw-hidden"> Fri </span>
              </th>
              <th className="tw-flex tw-h-15 tw-items-center tw-justify-center tw-rounded-tr-sm tw-p-1 tw-text-xs tw-font-semibold sm:tw-text-base xl:tw-p-5">
                <span className="tw-hidden lg:tw-block"> Saturday </span>
                <span className="tw-block lg:tw-hidden"> Sat </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* <!-- Line 1 --> */}
            <tr className="tw-grid tw-grid-cols-7">
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  1
                </span>
                <div className="tw-group tw-h-16 tw-w-full tw-flex-grow tw-cursor-pointer tw-py-1 md:tw-h-30">
                  <span className="group-hover:tw-text-primary md:tw-hidden">
                    More
                  </span>
                  <div className="tw-event tw-invisible tw-absolute tw-left-2 tw-z-99 tw-mb-1 tw-flex tw-w-[200%] tw-flex-col tw-rounded-sm tw-border-l-[3px] tw-border-primary tw-bg-gray tw-px-3 tw-py-1 tw-text-left tw-opacity-0 group-hover:tw-visible group-hover:tw-opacity-100 dark:tw-bg-meta-4 md:tw-visible md:tw-w-[190%] md:tw-opacity-100">
                    <span className="tw-event-name tw-text-sm tw-font-semibold tw-text-black dark:tw-text-white">
                      Redesign Website
                    </span>
                    <span className="tw-time tw-text-sm tw-font-medium tw-text-black dark:tw-text-white">
                      1 Dec - 2 Dec
                    </span>
                  </div>
                </div>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  2
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  3
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  4
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  5
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  6
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  7
                </span>
              </td>
            </tr>
            {/* <!-- Line 1 --> */}
            {/* <!-- Line 2 --> */}
            <tr className="tw-grid tw-grid-cols-7">
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  8
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  9
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  10
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  11
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  12
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  13
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  14
                </span>
              </td>
            </tr>
            {/* <!-- Line 2 --> */}
            {/* <!-- Line 3 --> */}
            <tr className="tw-grid tw-grid-cols-7">
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  15
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  16
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  17
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  18
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  19
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  20
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  21
                </span>
              </td>
            </tr>
            {/* <!-- Line 3 --> */}
            {/* <!-- Line 4 --> */}
            <tr className="tw-grid tw-grid-cols-7">
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  22
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  23
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  24
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  25
                </span>
                <div className="tw-group tw-h-16 tw-w-full tw-flex-grow tw-cursor-pointer tw-py-1 md:tw-h-30">
                  <span className="group-hover:tw-text-primary md:tw-hidden">
                    More
                  </span>
                  <div className="tw-event tw-invisible tw-absolute tw-left-2 tw-z-99 tw-mb-1 tw-flex tw-w-[300%] tw-flex-col tw-rounded-sm tw-border-l-[3px] tw-border-primary tw-bg-gray tw-px-3 tw-py-1 tw-text-left tw-opacity-0 group-hover:tw-visible group-hover:tw-opacity-100 dark:tw-bg-meta-4 md:tw-visible md:tw-w-[290%] md:tw-opacity-100">
                    <span className="tw-event-name tw-text-sm tw-font-semibold tw-text-black dark:tw-text-white">
                      App Design
                    </span>
                    <span className="tw-time tw-text-sm tw-font-medium tw-text-black dark:tw-text-white">
                      25 Dec - 27 Dec
                    </span>
                  </div>
                </div>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  26
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  27
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  28
                </span>
              </td>
            </tr>
            {/* <!-- Line 4 --> */}
            {/* <!-- Line 5 --> */}
            <tr className="tw-grid tw-grid-cols-7">
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  29
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  30
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  31
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  1
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  2
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  3
                </span>
              </td>
              <td className="tw-ease tw-relative tw-h-20 tw-cursor-pointer tw-border tw-border-stroke tw-p-2 tw-transition tw-duration-500 hover:tw-bg-gray dark:tw-border-strokedark dark:hover:tw-bg-meta-4 md:tw-h-25 md:tw-p-6 xl:tw-h-31">
                <span className="tw-font-medium tw-text-black dark:tw-text-white">
                  4
                </span>
              </td>
            </tr>
            {/* <!-- Line 5 --> */}
          </tbody>
        </table>
      </div>
      {/* <!-- ====== Calendar Section End ====== --> */}
    </>
  );
};

export default Calendar;
