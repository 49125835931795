import React, { useState, useEffect } from 'react';
import CustomCheckbox from './CustomCheckbox'; 

const CantonFilter = ({ cantons, onFilterChange, value }) => {
    const [selectedCantons, setSelectedCantons] = useState(value || []);

    useEffect(() => {
        setSelectedCantons(value || []);
    }, [value]);

    const handleCheckboxChange = (id) => {
        const isSelected = selectedCantons.includes(id);

        let updatedSelectedCantons;
        if (isSelected) {
            updatedSelectedCantons = selectedCantons.filter(cantonId => cantonId !== id);
        } else {
            updatedSelectedCantons = [...selectedCantons, id];
        }

        setSelectedCantons(updatedSelectedCantons);

        onFilterChange('canton', updatedSelectedCantons);
    };

    return (
        <li className="dropright sidebar-item nh">
            <a href="#" className="dropdown-toggle sidebar-title" data-toggle="dropdown">
                <i className="bi bi-flag cit"></i> Canton
            </a>
            <div className="dropdown-menu">
                <ul className="list-unstyled">
                    {cantons.map(canton => (
                        <li className="drop" key={canton.id}>
                            <CustomCheckbox
                                label={canton.name}
                                isSelected={selectedCantons.includes(canton.id)}
                                onCheckboxChange={() => handleCheckboxChange(canton.id)}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    );
};

export default CantonFilter;
