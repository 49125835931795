import { PaymentElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage(null);

        if (!stripe || !elements) {
            setLoading(false);
            return;
        }

        try {
            // Get billing details from the AddressElement
            const { complete, value: addressValues } = await elements.getElement('address').getValue();

            if (!complete) {
                setErrorMessage('Please fill in all required address fields.');
                setLoading(false);
                return;
            }

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/payment/success`,
                    payment_method_data: {
                        billing_details: {
                            address: addressValues.address,
                            name: addressValues.name,
                            email: addressValues.email,
                            phone: addressValues.phone,
                        },
                    },
                },
            });

            if (error) {
                setErrorMessage(error.message);
                setLoading(false);
            }
        } catch (err) {
            setErrorMessage('An unexpected error occurred. Please try again.');
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="tw-space-y-8">
            {/* Billing Address */}
            <div className="tw-mb-8">
                <h3 className="tw-text-lg tw-font-medium tw-mb-4">Billing Address</h3>
                <AddressElement 
                    options={{
                        mode: 'billing',
                        allowedCountries: ['US', 'CA', 'GB'],
                        fields: {
                            phone: 'required',
                        },
                        validation: {
                            phone: {
                                required: 'error',
                            },
                        },
                    }}
                />
            </div>

            {/* Payment Details */}
            <div>
                <h3 className="tw-text-lg tw-font-medium tw-mb-4">Card Details</h3>
                <PaymentElement 
                    options={{
                        layout: 'tabs',
                        defaultValues: {
                            billingDetails: {
                                name: 'John Doe',
                                email: 'john@example.com',
                            }
                        }
                    }}
                />
            </div>

            {errorMessage && (
                <div className="tw-bg-red-50 tw-text-red-500 tw-p-4 tw-rounded-md tw-text-sm">
                    {errorMessage}
                </div>
            )}

            <button
                type="submit"
                disabled={!stripe || loading}
                className={`tw-w-full tw-bg-[#366196] tw-text-white tw-py-4 tw-px-8 tw-rounded-md tw-font-medium
                    ${(!stripe || loading) ? 'tw-opacity-50 tw-cursor-not-allowed' : 'tw-hover:bg-[#2b4e78]'}
                    tw-transition-colors tw-duration-200`}
            >
                {loading ? (
                    <span className="tw-flex tw-items-center tw-justify-center">
                        <svg className="tw-animate-spin tw-h-5 tw-w-5 tw-mr-3" viewBox="0 0 24 24">
                            <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                            <path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                        Processing Payment...
                    </span>
                ) : (
                    'Pay $99.97'
                )}
            </button>

            <div className="tw-text-center tw-text-gray-500 tw-text-sm tw-space-y-2">
                <p>Your payment is secured by Stripe</p>
                <p>You will be redirected to complete the payment</p>
            </div>
        </form>
    );
};

export default CheckoutForm;