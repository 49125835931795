import flatpickr from 'flatpickr';
import { useEffect } from 'react';
import React from 'react';

const DatePickerTwo = () => {
  useEffect(() => {
    // Init flatpickr
    flatpickr('.form-datepicker', {
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      dateFormat: 'M j, Y',
      prevArrow:
        '<svg className="tw-fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg className="tw-fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    });
  }, []);

  return (
    <div>
      <label className="tw-mb-3 tw-block tw-text-sm tw-font-medium tw-text-black dark:tw-text-white">
        Select date
      </label>
      <div className="tw-relative">
        <input
          className="form-datepicker tw-w-full tw-rounded tw-border-[1.5px] tw-border-stroke tw-bg-transparent tw-px-5 tw-py-3 tw-font-normal tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary dark:tw-border-form-strokedark dark:tw-bg-form-input dark:focus:tw-border-primary"
          placeholder="mm/dd/yyyy"
          data-class="flatpickr-right"
        />

        <div className="tw-pointer-events-none tw-absolute tw-inset-0 tw-left-auto tw-right-5 tw-flex tw-items-center">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.0002 12.8249C8.83145 12.8249 8.69082 12.7687 8.5502 12.6562L2.08145 6.2999C1.82832 6.04678 1.82832 5.65303 2.08145 5.3999C2.33457 5.14678 2.72832 5.14678 2.98145 5.3999L9.0002 11.278L15.0189 5.34365C15.2721 5.09053 15.6658 5.09053 15.9189 5.34365C16.1721 5.59678 16.1721 5.99053 15.9189 6.24365L9.45019 12.5999C9.30957 12.7405 9.16895 12.8249 9.0002 12.8249Z"
              fill="#64748B"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default DatePickerTwo;
