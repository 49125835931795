// api.js
import axios from "axios";

const BASE_URL = 'https://api.kseco.eu/api';
const axiosInstance = axios.create({
  baseURL: BASE_URL, 
  headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('TOKEN')}`
  }
});

export const STATISTICS_API = {
  getCompaniesStatistics: `${BASE_URL}/companies-statistics`,
  getActiveBankruptCompanies: `${BASE_URL}/active-bankrupt-companies`,
  getActiveBankruptCompaniesByRange: (startDate, endDate) => 
    `${BASE_URL}/active-bankrupt-companies_by_range?start_at=${encodeURIComponent(startDate)}&end_at=${encodeURIComponent(endDate)}`,
  getLastTenCompanies: `${BASE_URL}/companies/last-ten`,
};

export const BANCKROPT_ACTIVE_COMPANIES = STATISTICS_API;

export const CANTON_API = {
  getAllCantons: `${BASE_URL}/cantons`,
  getCantonById: (id) => `${BASE_URL}/cantons/${id}`,
};

export const COMPANY_API = {
  getAllCompanies: `${BASE_URL}/companies`,
  getAlbanianCompanies: `${BASE_URL}/companies/albanianCompanies`,
  getTodayPublication: `${BASE_URL}/companies/todayPublication`,
  countAllCompanies: `${BASE_URL}/companies/countAllCompanies`,
  countAllTodayPublications: `${BASE_URL}/companies/countAllTodayPublications`,
  countTodayPublications: `${BASE_URL}/companies/countTodayPublications`,
  countAlbanianCompanies: `${BASE_URL}/companies/countAlbanianCompanies`,
  countMonthlyAllAndAlbanianCompanies: `${BASE_URL}/companies/countAL`,
  getCompanyById: (id) => `${BASE_URL}/companies/${id}`,
  getCompanyHistory: async (companyId) => {
    try {
      const token = localStorage.getItem("TOKEN");
      const response = await axios.get(`${BASE_URL}/companies/${companyId}/history`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching company history:', error);
      throw error;
    }
  },
};

export const COMPANY_TYPE_API = {
  getAllCompanyTypes: `${BASE_URL}/companiesType`,
};

export const FILTER_API = {
  filterByCanton: `${BASE_URL}/filter/canton`,
  filterByLegalForm: `${BASE_URL}/filter/legalform`,
  filterByMunicipality: (key) => `${BASE_URL}/filter/search/${key}`,
  filterByDate: (date) => `${BASE_URL}/filter/date/${date}`,
};

export const HOME_API = {
  getHomeData: `${BASE_URL}/home`,
  countAllCompanies: `${BASE_URL}/home/countAllCompanies`,
  countAllTodayPublications: `${BASE_URL}/home/countAllTodayPublications`,
  countAlbanianTodayPublications: `${BASE_URL}/home/countAlbanianTodayPublications`,
  countAlbanianCompanies: `${BASE_URL}/home/countAlbanianCompanies`,
  get10Companies: `${BASE_URL}/home/10Companies`,
  getMonthlyRegistration: `${BASE_URL}/home/monthlyRegistration`,
  getMonthlyCompanies: `${BASE_URL}/home/monthlyCompanies`,
  getCompanyByCanton: `${BASE_URL}/home/companyByCanton`,
};

export const NATIONALITY_API = {
  getAllNationalities: `${BASE_URL}/nationalities`,
};

export const SECTOR_API = {
  getAllSectors: `${BASE_URL}/sectors`,
};

export const SERVICES_API = {
  getAllServices: `${BASE_URL}/services`,
};


export const CART_API = {
  getCart: `${BASE_URL}/cart`,
  addItem: `${BASE_URL}/cart/items`,
  removeItem: (itemId) => `${BASE_URL}/cart/items/${itemId}`,
  updateQuantity: (itemId) => `${BASE_URL}/cart/items/${itemId}/quantity`,
  getItemsCount: `${BASE_URL}/cart/count`,

};


export const PAYMENT_API = {
  createPaymentIntent: `${BASE_URL}/payment/create-intent`,
  stripeWebhook: `${BASE_URL}/webhook/stripe`,
};

export const createPaymentIntent = () => {
  return axiosInstance.post(PAYMENT_API.createPaymentIntent);
};

export const makeAuthenticatedRequest = async (url) => {
  const token = localStorage.getItem('TOKEN');
  if (!token) {
    throw new Error('No authentication token found');
  }

  return axios.get(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

//OTHER IMPORTANT APIS TO USE



// https://api.kseco.eu/api/companies?name=Swisscann

// https://api.kseco.eu/api/companies?services=Verlag

// https://api.kseco.eu/api/companies?purposes=Verwaltung

// https://api.kseco.eu/api/companies?companyType%5B%5D=Einzelunternehmen

// https://api.kseco.eu/api/active-bankrupt-companies_by_range?start_at=2018-01-18&end_at=2023-01-18

// https://api.kseco.eu/api/owner-companies ne body kjo api e merr ID ne e rreshtit per pronar dhe i kthen kompanite e tjerea te pronarirt

const getCompaniesWithFilters = async (filters) => {
  try {
    const response = await axios.get('/api/companies', {
      params: {
        ...filters,
        officialPublication: filters.officialPublication?.join(',') // Convert array to comma-separated string
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
};