import React from 'react';

const PublicationFilter = ({ onFilterChange, currentFilter }) => {
    const handlePublicationChange = (event) => {
        const { value } = event.target;
        // If "All Publications" is selected, send empty string to match Dashboard behavior
        const filterValue = value === 'All Publications' ? '' : value;
        onFilterChange('publication', filterValue);
    };

    return (
        <li className="dropright sidebar-item nh">
            <button
                className="dropdown-toggle sidebar-title"
                data-toggle="dropdown"
                aria-expanded="false"
                aria-label="Toggle publication filter"
            >
                <i className="bi bi-file-earmark cit"></i> Publications
            </button>
            <div className="dropdown-menu natio">
                <ul className="list-unstyled">
                    <li className="drop">
                        <label>
                            <input
                                type="radio"
                                name="publication"
                                value="All Publications"
                                checked={!currentFilter || currentFilter === ''}
                                onChange={handlePublicationChange}
                            />
                            All Publications
                        </label>
                    </li>
                    <li className="drop">
                        <label>
                            <input
                                type="radio"
                                name="publication"
                                value="Today Publications"
                                checked={currentFilter === 'Today Publications'}
                                onChange={handlePublicationChange}
                            />
                            Today Publications
                        </label>
                    </li>
                    <li className="drop">
                        <label>
                            <input
                                type="radio"
                                name="publication"
                                value="albanian"
                                checked={currentFilter === 'albanian'}
                                onChange={handlePublicationChange}
                            />
                            All Albanian Publications
                        </label>
                    </li>
                </ul>
            </div>
        </li>
    );
};

export default PublicationFilter;
