import React from "react";
import { ImCross } from "react-icons/im";

const ItemCard = ({ item, onDelete, onDecrease, onIncrease }) => {
  return (
    <div className="tw-w-full tw-grid tw-grid-cols-5 tw-mb-4 tw-border tw-rounded-lg tw-shadow-md tw-bg-white tw-overflow-hidden">
      <div className="tw-col-span-5 mdl:tw-col-span-2 tw-flex tw-items-center tw-gap-4 tw-p-4">
        <ImCross
          onClick={onDelete}
          className="tw-text-primeColor tw-hover:text-red-500 tw-duration-300 tw-cursor-pointer"
        />
        <img className="tw-w-32 tw-h-32 tw-rounded-md tw-object-cover" src={item.image} alt="productImage" />
        <h1 className="tw-font-titleFont tw-font-semibold tw-text-lg tw-text-gray-800">{item.name}</h1>
      </div>
      <div className="tw-col-span-5 mdl:tw-col-span-3 tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-4">
        <div className="tw-flex tw-w-1/3 tw-items-center tw-text-lg tw-font-semibold tw-text-gray-700">
          ${item.price}
        </div>
        <div className="tw-w-1/3 tw-flex tw-items-center tw-gap-4 tw-text-lg">
          <span
            onClick={onDecrease}
            className="tw-w-8 tw-h-8 tw-bg-gray-100 tw-text-2xl tw-flex tw-items-center tw-justify-center tw-rounded-full tw-shadow hover:tw-bg-gray-200 tw-cursor-pointer tw-duration-300 tw-border tw-border-gray-300"
          >
            -
          </span>
          <p className="tw-text-lg tw-font-medium">{item.quantity}</p>
          <span
            onClick={onIncrease}
            className="tw-w-8 tw-h-8 tw-bg-gray-100 tw-text-2xl tw-flex tw-items-center tw-justify-center tw-rounded-full tw-shadow hover:tw-bg-gray-200 tw-cursor-pointer tw-duration-300 tw-border tw-border-gray-300"
          >
            +
          </span>
        </div>
        <div className="tw-w-1/3 tw-flex tw-items-center tw-font-titleFont tw-font-bold tw-text-lg">
          <p>${item.quantity * item.price}</p>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
