import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      navigation: {
        home: 'Home',
        shop: 'Shop',
        marketing: 'Marketing',
        dashboard: 'Dashboard',
        myDashboard: 'List Builder',
        login: 'Login',
        register: 'Register',
        myAccount: 'My Account',
        logout: 'Logout'
      },
      statistics: {
        allCompanies: "All Companies",
        allAlbanianCompanies: "All Albanian Companies",
        todayAlbanianCompanies: "Today Albanian Companies",
        todayAllCompanies: "Today All Companies",
        bankruptCompanies: "Bankrupt Companies",
        activeCompanies: "Active Companies"
      },
      search: {
        advancedSearch: "Advanced search",
        placeholder: "Business name or UID",
        loading: "Loading..."
      },
      flipbox: {
        services: {
          title: "Provide All Kind Of IT Services",
          description: "Whether bringing new amazing products and services to market"
        },
        security: {
          title: "Solution For All IT Security",
          description: "Whether bringing new amazing products and services to market"
        },
        consultancy: {
          title: "All IT Consultancy Solution",
          description: "Whether bringing new amazing products and services to market"
        },
        engineer: {
          title: "Best Experience Engineer",
          description: "Whether bringing new amazing products and services to market"
        }
      },
      filter: {
        title: "Filter Companies",
        calendar: "Calendar",
        service: "Service",
        servicePlaceholder: "🔍 Search by Service",
        city: "City",
        cityPlaceholder: "🔍 Search by City",
        clearDate: "Clear Date"
      },
      about: {
        experience: "10 YEARS OF EXPERIENCE",
        success: {
          title1: "Preparing For Your Success",
          title2: "Provide Best",
          title3: "Solutions"
        },
        warranty: {
          title: "Warranty Management",
          description: "Morem Ipsum is simply dummy text of the printing and pepesetting found."
        },
        quality: {
          title: "Quality Control System",
          description: "Morem Ipsum is simply dummy text of the printing and pepesetting found."
        }
      },
      companies: {
        latest: "Latest registered",
        companies: "Companies",
        searchResults: "Search results",
        companyName: "Company Name",
        uniqueId: "Unique ID",
        registrationDate: "Date of registration",
        legalForm: "Legal Form",
        city: "City",
        checking: "Checking the database.",
        noData: "No data found.",
        noCityAvailable: "No city available",
        type: "Company Type"
      },
      statistics: {
        title: "Latest",
        statistics: "Statistics",
        allCompanies: "All Companies",
        allAlbanianCompanies: "All Albanian Companies",
        todayAlbanianCompanies: "Today Albanian Companies",
        todayAllCompanies: "Today All Companies",
        bankruptCompanies: "Bankrupt Companies",
        activeCompanies: "Active Companies"
      },
      pricing: {
        sectionTitle: "PRICING AND PLAN",
        mainTitle: "Choose Your Best Plan",
        currency: "$",
        perMonth: "/ month",
        chooseButton: "Choose Plan",
        seeDetails: "See our offers in detail",
        basicPlan: {
          title: "Basic Plan"
        },
        premiumPlan: {
          title: "Premium Plan"
        },
        ultraPlan: {
          title: "Ultra Plan"
        },
        features: {
          trial: "30 Days Trial Features",
          cloudSync: "Synced To Cloud Database",
          support: "10 Hours Of Support",
          socialMedia: "Social Media Integration",
          unlimited: "Unlimited Features"
        }
      },
      callToAction: {
        phoneNumber: "+41 22 548 10 30",
        title: {
          line1: "Need Any Information?",
          line2: "Contact Us"
        },
        button: "Join us Now"
      },
      footer: {
        information: {
          title: "Information",
          pages: "Pages",
          ourTeam: "Our Team",
          features: "Features"
        },
        resources: {
          title: "Resources",
          monitoringGrader: "Monitoring Grader",
          videoTutorial: "Video Tutorial",
          termService: "Terms & Service"
        },
        help: {
          title: "Help",
          signUp: "Sign Up",
          login: "Login",
          termsOfServices: "Terms of Services"
        },
        contactUs: {
          title: "Contact Us",
          description: "Contact us if need help with anything",
          copyright: "2022 KSECO. All Rights Reserved"
        }
      },
      dashboard: {
        filters: {
          publicationType: "Publication Type",
          municipality: "Municipality",
          capital: "Capital",
          publicationDate: "Publication Date",
          publicationDateRange: "Publication Date Range",
          shareValue: "Share Value",
          legalForm: {
            title: "Legal Form",
            types: {
              einzelunternehmen: "Einzelunternehmen",
              kollektivgesellschaft: "Kollektivgesellschaft",
              aktiengesellschaft: "Aktiengesellschaft",
              genossenschaft: "Genossenschaft",
              gmbh: "Gesellschaft mit beschränkter Haftung",
              verein: "Verein",
              stiftung: "Stiftung",
              institut: "Istitut des öffentlichen Rechts",
              zweigniederlassung: "Zweigniederlassung",
              kommanditgesellschaft: "Kommanditgesellschaft",
              auslandisch: "Zweigniederlassung auslänische Gesellschaft"
            }
          },
          canton: "Canton",
          nationality: "Nationality",
          sectors: "Sectors",
          service: "Service",
          calendar: "Calendar",
          city: "City"
        },
        publication: {
          title: "Filter Results",
          dataRange: "Data from",
          to: "to",
          filters: {
            publication: "Publication",
            canton: "Canton",
            legalForm: "Legal Form",
            nationality: "Nationality",
            sectors: "Sectors",
            services: "Services"
          },
          search: {
            placeholder: "Search Companies"
          },
          loading: "Loading your data...",
          noPublication: "No Publication Selected"
        },
        table: {
          headers: {
            companyName: "COMPANY NAME",
            uniqueId: "UNIQUE ID",
            dateOfRegistration: "DATE OF REGISTRATION",
            legalForm: "LEGAL FORM",
            city: "CITY"
          },
          noResults: "No results found!",
          loading: "Checking the database."
        }
      },
      details: {
        companyInfo: {
          uniqueId: "Unique ID",
          dateOfRegistration: "Date of registration"
        },
        buttons: {
          generatePdf: "Generate PDF with History"
        },
        tables: {
          business: {
            title: "Business Information",
            headers: {
              in: "In",
              businessName: "Business name",
              legalForm: "Legal Form"
            }
          },
          legalSeat: {
            title: "Legal Seat",
            noData: "No Legal Seats Available"
          },
          address: {
            title: "Address",
            noData: "No Addresses Available"
          },
          dateOfActs: {
            title: "Date of Acts",
            noData: "No Date of Acts Available"
          },
          sectors: {
            title: "Sector Name",
            sectorId: "Sector ID",
            noData: "No Sectors Available"
          },
          shares: {
            title: "Shares",
            headers: {
              in: "In",
              shares: "Shares",
              partner: "Partner"
            },
            noData: "No Share Data Available"
          },
          capital: {
            title: "Capital",
            noData: "No Capital Data Available"
          },
          registrationOffice: {
            title: "Registration Office",
            noData: "No Registration Offices Available"
          },
          remarks: {
            title: "Remarks",
            headers: {
              in: "In"
            },
            noData: "No Remarks Available"
          },
          publications: {
            headers: {
              in: "In",
              journalDate: "Journal Date",
              sogcDate: "SOGC Date",
              journalNumber: "Journal Number",
              sogcNumber: "SOGC Number"
            },
            noData: "No Publications Available"
          },
          persons: {
            headers: {
              in: "In",
              personalData: "Personal Data",
              function: "Function",
              signature: "Signature"
            },
            noData: "No Persons Available"
          },
          purpose: {
            headers: {
              in: "In",
              purpose: "Purpose",
              sector: "Sector",
              service: "Service"
            }
          }
        },
        network: {
          legend: {
            person: "Person",
            company: "Company",
            connection: "Connection"
          },
          loading: "Loading network visualization..."
        }
      }
    }
  },
  al: {
    translation: {
      navigation: {
        home: 'Ballina',
        shop: 'Dyqani',
        marketing: 'Marketing',
        dashboard: 'Paneli',
        myDashboard: 'Ndërtuesi i Listës',
        login: 'Kyçu',
        register: 'Regjistrohu',
        myAccount: 'Llogaria Ime',
        logout: 'Dilni'
      },
      statistics: {
        allCompanies: "Të Gjitha Kompanitë",
        allAlbanianCompanies: "Të Gjitha Kompanitë Shqiptare",
        todayAlbanianCompanies: "Kompanitë Shqiptare Sot",
        todayAllCompanies: "Të Gjitha Kompanitë Sot",
        bankruptCompanies: "Kompanitë e Falimentuara",
        activeCompanies: "Kompanitë Aktive"
      },
      search: {
        advancedSearch: "Kërkim i avancuar",
        placeholder: "Emri i biznesit ose UID",
        loading: "Duke ngarkuar..."
      },
      flipbox: {
        services: {
          title: "Ofrojmë Të Gjitha Llojet e Shërbimeve IT",
          description: "Qoftë duke sjellë produkte dhe shërbime të reja të mrekullueshme në treg"
        },
        security: {
          title: "Zgjidhje për të Gjithë Sigurinë IT",
          description: "Qoftë duke sjellë produkte dhe shërbime të reja të mrekullueshme në treg"
        },
        consultancy: {
          title: "Të Gjitha Zgjidhjet e Konsulencës IT",
          description: "Qoftë duke sjellë produkte dhe shërbime të reja të mrekullueshme në treg"
        },
        engineer: {
          title: "Inxhinieri me Përvojën më të Mirë",
          description: "Qoftë duke sjellë produkte dhe shërbime të reja të mrekullueshme në treg"
        }
      },
      filter: {
        title: "Filtro Kompanitë",
        calendar: "Kalendari",
        service: "Shërbimi",
        servicePlaceholder: "🔍 Kërko sipas Shërbimit",
        city: "Qyteti",
        cityPlaceholder: "🔍 Kërko sipas Qytetit",
        clearDate: "Pastro Datën"
      },
      about: {
        experience: "10 VJET PËRVOJË",
        success: {
          title1: "Duke u Përgatitur për Suksesin Tuaj",
          title2: "Ofron më të Mirën",
          title3: "Zgjidhjet"
        },
        warranty: {
          title: "Menaxhimi i Garancisë",
          description: "Morem Ipsum është thjesht tekst i rremë i printimit dhe përcaktimit të gjetura."
        },
        quality: {
          title: "Sistemi i Kontrollit të Cilësisë",
          description: "Morem Ipsum është thjesht tekst i rremë i printimit dhe përcaktimit të gjetura."
        }
      },
      companies: {
        latest: "Të regjistruara së fundmi",
        companies: "Kompanitë",
        searchResults: "Rezultatet e kërkimit",
        companyName: "Emri i Kompanisë",
        uniqueId: "ID Unike",
        registrationDate: "Data e regjistrimit",
        legalForm: "Forma Ligjore",
        city: "Qyteti",
        checking: "Duke kontrolluar bazën e të dhënave.",
        noData: "Nuk u gjetën të dhëna.",
        noCityAvailable: "Nuk ka qytet në dispozicion",
        type: "Lloji i Kompanisë"
      },
      statistics: {
        title: "Të fundit",
        statistics: "Statistikat",
        allCompanies: "Të Gjitha Kompanitë",
        allAlbanianCompanies: "Të Gjitha Kompanitë Shqiptare",
        todayAlbanianCompanies: "Kompanitë Shqiptare Sot",
        todayAllCompanies: "Të Gjitha Kompanitë Sot",
        bankruptCompanies: "Kompanitë e Falimentuara",
        activeCompanies: "Kompanitë Aktive"
      },
      pricing: {
        sectionTitle: "ÇMIMI DHE PLANI",
        mainTitle: "Zgjidhni Planin Tuaj më të Mirë",
        currency: "$",
        perMonth: "/ muaj",
        chooseButton: "Zgjidhni Planin",
        seeDetails: "Shihni ofertat tona në detaje",
        basicPlan: {
          title: "Plani Bazë"
        },
        premiumPlan: {
          title: "Plani Premium"
        },
        ultraPlan: {
          title: "Plani Ultra"
        },
        features: {
          trial: "30 Ditë Veçori Provë",
          cloudSync: "Sinkronizuar me Cloud Database",
          support: "10 Orë Mbështetje",
          socialMedia: "Integrimi i Mediave Sociale",
          unlimited: "Veçori të Pakufizuara"
        }
      },
      callToAction: {
        phoneNumber: "+41 22 548 10 30",
        title: {
          line1: "Keni nevojë për informacion?",
          line2: "Na kontaktoni"
        },
        button: "Na bashkohuni tani"
      },
      footer: {
        information: {
          title: "Informacion",
          pages: "Faqet",
          ourTeam: "Ekipi ynë",
          features: "Veçoritë"
        },
        resources: {
          title: "Burimet",
          monitoringGrader: "Monitorimi Grader",
          videoTutorial: "Tutorial video",
          termService: "Kushtet e shërbimit"
        },
        help: {
          title: "Ndihmë",
          signUp: "Regjistrohu",
          login: "Hyr",
          termsOfServices: "Kushtet e shërbimit"
        },
        contactUs: {
          title: "Na kontaktoni",
          description: "Na kontaktoni nëse keni nevojë për ndihmë",
          copyright: "2022 KSECO. Të gjitha të drejtat e rezervuara"
        }
      },
      dashboard: {
        filters: {
          publicationType: "Lloji i publikimit",
          municipality: "Bashkia",
          capital: "Kapitali",
          publicationDate: "Data e publikimit",
          publicationDateRange: "Intervali i datës së publikimit",
          shareValue: "Vlera e aksionit",
          legalForm: {
            title: "Forma ligjore",
            types: {
              einzelunternehmen: "Biznes individual",
              kollektivgesellschaft: "Shoqëri kolektive",
              aktiengesellschaft: "Shoqëri aksionare",
              genossenschaft: "Kooperativë",
              gmbh: "Shoqëri me përgjegjësi të kufizuar",
              verein: "Shoqatë",
              stiftung: "Fondacion",
              institut: "Institut i së drejtës publike",
              zweigniederlassung: "Degë",
              kommanditgesellschaft: "Shoqëri komandite",
              auslandisch: "Degë e shoqërisë së huaj"
            }
          },
          canton: "Kantoni",
          nationality: "Kombësia",
          sectors: "Sektorët",
          service: "Shërbimi",
          calendar: "Kalendari",
          city: "Qyteti"
        },
        publication: {
          title: "Rezultatet e filtrit",
          dataRange: "Të dhënat nga",
          to: "deri",
          filters: {
            publication: "Publikimi",
            canton: "Kantoni",
            legalForm: "Forma ligjore",
            nationality: "Kombësia",
            sectors: "Sektorët",
            services: "Shërbimet"
          },
          search: {
            placeholder: "Kërko kompanitë"
          },
          loading: "Duke ngarkuar të dhënat tuaja...",
          noPublication: "Asnjë publikim i zgjedhur"
        },
        table: {
          headers: {
            companyName: "EMRI I KOMPANISË",
            uniqueId: "ID UNIKE",
            dateOfRegistration: "DATA E REGJISTRIMIT",
            legalForm: "FORMA LIGJORE",
            city: "QYTETI"
          },
          noResults: "Nuk u gjetën rezultate!",
          loading: "Duke kontrolluar bazën e të dhënave."
        }
      },
      details: {
        companyInfo: {
          uniqueId: "ID Unike",
          dateOfRegistration: "Data e regjistrimit"
        },
        buttons: {
          generatePdf: "Gjenero PDF me historikun"
        },
        tables: {
          business: {
            title: "Informacioni i biznesit",
            headers: {
              in: "Në",
              businessName: "Emri i biznesit",
              legalForm: "Forma ligjore"
            }
          },
          legalSeat: {
            title: "Selia ligjore",
            noData: "Nuk ka seli ligjore në dispozicion"
          },
          address: {
            title: "Adresa",
            noData: "Nuk ka adresa në dispozicion"
          },
          dateOfActs: {
            title: "Data e akteve",
            noData: "Nuk ka data aktesh në dispozicion"
          },
          sectors: {
            title: "Emri i sektorit",
            sectorId: "ID e sektorit",
            noData: "Nuk ka sektorë në dispozicion"
          },
          shares: {
            title: "Aksionet",
            headers: {
              in: "Në",
              shares: "Aksionet",
              partner: "Partneri"
            },
            noData: "Nuk ka të dhëna për aksionet"
          },
          capital: {
            title: "Kapitali",
            noData: "Nuk ka të dhëna për kapitalin"
          },
          registrationOffice: {
            title: "Zyra e regjistrimit",
            noData: "Nuk ka zyra regjistrimi në dispozicion"
          },
          remarks: {
            title: "Shënime",
            headers: {
              in: "Në"
            },
            noData: "Nuk ka shënime në dispozicion"
          },
          publications: {
            headers: {
              in: "Në",
              journalDate: "Data e gazetës",
              sogcDate: "Data SHGJK",
              journalNumber: "Numri i gazetës",
              sogcNumber: "Numri SHGJK"
            },
            noData: "Nuk ka publikime në dispozicion"
          },
          persons: {
            headers: {
              in: "Në",
              personalData: "Të dhënat personale",
              function: "Funksioni",
              signature: "Nënshkrimi"
            },
            noData: "Nuk ka persona në dispozicion"
          },
          purpose: {
            headers: {
              in: "Në",
              purpose: "Qëllimi",
              sector: "Sektori",
              service: "Shërbimi"
            }
          }
        },
        network: {
          legend: {
            person: "Person",
            company: "Kompani",
            connection: "Lidhje"
          },
          loading: "Duke ngarkuar vizualizimin e rrjetit..."
        }
      }
    }
  },
  fr: {
    translation: {
      navigation: {
        home: 'Accueil',
        shop: 'Boutique',
        marketing: 'Marketing',
        dashboard: 'Tableau de Bord',
        myDashboard: 'Créateur de Liste',
        login: 'Connexion',
        register: "S'inscrire",
        myAccount: 'Mon Compte',
        logout: 'Déconnexion'
      },
      statistics: {
        allCompanies: "Toutes les Entreprises",
        allAlbanianCompanies: "Toutes les Entreprises Albanaises",
        todayAlbanianCompanies: "Entreprises Albanaises d'Aujourd'hui",
        todayAllCompanies: "Toutes les Entreprises d'Aujourd'hui",
        bankruptCompanies: "Entreprises en Faillite",
        activeCompanies: "Entreprises Actives"
      },
      search: {
        advancedSearch: "Recherche avancée",
        placeholder: "Nom de l'entreprise ou UID",
        loading: "Chargement..."
      },
      flipbox: {
        services: {
          title: "Fournir Tous Types de Services IT",
          description: "Que ce soit en apportant de nouveaux produits et services étonnants sur le marché"
        },
        security: {
          title: "Solution Pour Toute la Sécurité IT",
          description: "Que ce soit en apportant de nouveaux produits et services étonnants sur le marché"
        },
        consultancy: {
          title: "Toutes les Solutions de Conseil IT",
          description: "Que ce soit en apportant de nouveaux produits et services étonnants sur le marché"
        },
        engineer: {
          title: "Ingénieur avec la Meilleure Expérience",
          description: "Que ce soit en apportant de nouveaux produits et services étonnants sur le marché"
        }
      },
      filter: {
        title: "Filtrer les Entreprises",
        calendar: "Calendrier",
        service: "Service",
        servicePlaceholder: "🔍 Rechercher par Service",
        city: "Ville",
        cityPlaceholder: "🔍 Rechercher par Ville",
        clearDate: "Effacer la Date"
      },
      about: {
        experience: "10 ANS D'EXPÉRIENCE",
        success: {
          title1: "Préparation de Votre Succès",
          title2: "Fournir les Meilleures",
          title3: "Solutions"
        },
        warranty: {
          title: "Gestion de la Garantie",
          description: "Morem Ipsum est simplement un texte factice de l'impression et de la composition trouvées."
        },
        quality: {
          title: "Système de Contrôle Qualité",
          description: "Morem Ipsum est simplement un texte factice de l'impression et de la composition trouvées."
        }
      },
      companies: {
        latest: "Dernièrement enregistrées",
        companies: "Entreprises",
        searchResults: "Résultats de la recherche",
        companyName: "Nom de l'entreprise",
        uniqueId: "ID Unique",
        registrationDate: "Date d'enregistrement",
        legalForm: "Forme Juridique",
        city: "Ville",
        checking: "Vérification de la base de données.",
        noData: "Aucune donnée trouvée.",
        noCityAvailable: "Pas de ville disponible",
        type: "Type d'Entreprise"
      },
      statistics: {
        title: "Dernières",
        statistics: "Statistiques",
        allCompanies: "Toutes les Entreprises",
        allAlbanianCompanies: "Toutes les Entreprises Albanaises",
        todayAlbanianCompanies: "Entreprises Albanaises Aujourd'hui",
        todayAllCompanies: "Toutes les Entreprises Aujourd'hui",
        bankruptCompanies: "Entreprises en Faillite",
        activeCompanies: "Entreprises Actives"
      },
      pricing: {
        sectionTitle: "PRIX ET PLAN",
        mainTitle: "Choisissez Votre Meilleur Plan",
        currency: "$",
        perMonth: "/ mois",
        chooseButton: "Choisir le Plan",
        seeDetails: "Voir nos offres en détail",
        basicPlan: {
          title: "Plan Basic"
        },
        premiumPlan: {
          title: "Plan Premium"
        },
        ultraPlan: {
          title: "Plan Ultra"
        },
        features: {
          trial: "30 Jours de Fonctionnalités d'Essai",
          cloudSync: "Synchronisé avec la Base de Données Cloud",
          support: "10 Heures de Support",
          socialMedia: "Intégration des Médias Sociaux",
          unlimited: "Fonctionnalités Illimitées"
        }
      },
      callToAction: {
        phoneNumber: "+41 22 548 10 30",
        title: {
          line1: "Besoin d'informations?",
          line2: "Contactez-nous"
        },
        button: "Rejoignez-nous maintenant"
      },
      footer: {
        information: {
          title: "Information",
          pages: "Pages",
          ourTeam: "Notre équipe",
          features: "Fonctionnalités"
        },
        resources: {
          title: "Ressources",
          monitoringGrader: "Surveillance Grader",
          videoTutorial: "Tutoriel vidéo",
          termService: "Conditions de service"
        },
        help: {
          title: "Aide",
          signUp: "S'inscrire",
          login: "Se connecter",
          termsOfServices: "Conditions d'utilisation"
        },
        contactUs: {
          title: "Contactez-nous",
          description: "Contactez-nous si vous avez besoin d'aide",
          copyright: "2022 KSECO. Tous droits réservés"
        }
      },
      dashboard: {
        filters: {
          publicationType: "Type de publication",
          municipality: "Municipalité",
          capital: "Capital",
          publicationDate: "Date de publication",
          publicationDateRange: "Plage de dates de publication",
          shareValue: "Valeur de l'action",
          legalForm: {
            title: "Forme juridique",
            types: {
              einzelunternehmen: "Entreprise individuelle",
              kollektivgesellschaft: "Société en nom collectif",
              aktiengesellschaft: "Société anonyme",
              genossenschaft: "Coopérative",
              gmbh: "Société à responsabilité limitée",
              verein: "Association",
              stiftung: "Fondation",
              institut: "Institut de droit public",
              zweigniederlassung: "Succursale",
              kommanditgesellschaft: "Société en commandite",
              auslandisch: "Succursale de société étrangère"
            }
          },
          canton: "Canton",
          nationality: "Nationalité",
          sectors: "Secteurs",
          service: "Service",
          calendar: "Calendrier",
          city: "Ville"
        },
        publication: {
          title: "Résultats du filtre",
          dataRange: "Données du",
          to: "au",
          filters: {
            publication: "Publication",
            canton: "Canton",
            legalForm: "Forme juridique",
            nationality: "Nationalité",
            sectors: "Secteurs",
            services: "Services"
          },
          search: {
            placeholder: "Rechercher des entreprises"
          },
          loading: "Chargement de vos données...",
          noPublication: "Aucune publication sélectionnée"
        },
        table: {
          headers: {
            companyName: "NOM DE L'ENTREPRISE",
            uniqueId: "ID UNIQUE",
            dateOfRegistration: "DATE D'ENREGISTREMENT",
            legalForm: "FORME JURIDIQUE",
            city: "VILLE"
          },
          noResults: "Aucun résultat trouvé !",
          loading: "Vérification de la base de données."
        }
      },
      details: {
        companyInfo: {
          uniqueId: "ID unique",
          dateOfRegistration: "Date d'enregistrement"
        },
        buttons: {
          generatePdf: "Générer un PDF avec l'historique"
        },
        tables: {
          business: {
            title: "Information sur l'entreprise",
            headers: {
              in: "En",
              businessName: "Nom de l'entreprise",
              legalForm: "Forme juridique"
            }
          },
          legalSeat: {
            title: "Siège social",
            noData: "Aucun siège social disponible"
          },
          address: {
            title: "Adresse",
            noData: "Aucune adresse disponible"
          },
          dateOfActs: {
            title: "Date des actes",
            noData: "Aucune date d'actes disponible"
          },
          sectors: {
            title: "Nom du secteur",
            sectorId: "ID du secteur",
            noData: "Aucun secteur disponible"
          },
          shares: {
            title: "Actions",
            headers: {
              in: "En",
              shares: "Actions",
              partner: "Partenaire"
            },
            noData: "Aucune donnée d'actions disponible"
          },
          capital: {
            title: "Capital",
            noData: "Aucune donnée de capital disponible"
          },
          registrationOffice: {
            title: "Bureau d'enregistrement",
            noData: "Aucun bureau d'enregistrement disponible"
          },
          remarks: {
            title: "Remarques",
            headers: {
              in: "En"
            },
            noData: "Aucune remarque disponible"
          },
          publications: {
            headers: {
              in: "En",
              journalDate: "Date du journal",
              sogcDate: "Date FOSC",
              journalNumber: "Numéro du journal",
              sogcNumber: "Numéro FOSC"
            },
            noData: "Aucune publication disponible"
          },
          persons: {
            headers: {
              in: "En",
              personalData: "Données personnelles",
              function: "Fonction",
              signature: "Signature"
            },
            noData: "Aucune personne disponible"
          },
          purpose: {
            headers: {
              in: "En",
              purpose: "Objectif",
              sector: "Secteur",
              service: "Service"
            }
          }
        },
        network: {
          legend: {
            person: "Personne",
            company: "Entreprise",
            connection: "Connexion"
          },
          loading: "Chargement de la visualisation du réseau..."
        }
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    },
    load: 'languageOnly',
    ns: ['translation'],
    defaultNS: 'translation',
    keySeparator: '.',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    }
  });

export default i18n;
