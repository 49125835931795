import React, { useState, useEffect, useRef } from 'react';

interface Option {
  value: string;
  text: string;
  selected: boolean;
  element?: HTMLElement;
}

interface DropdownProps {
  id: string;
}

const MultiSelect: React.FC<DropdownProps> = ({ id }) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [show, setShow] = useState(false);
  const dropdownRef = useRef<any>(null);
  const trigger = useRef<any>(null);

  useEffect(() => {
    const loadOptions = () => {
      const select = document.getElementById(id) as HTMLSelectElement | null;
      if (select) {
        const newOptions: Option[] = [];
        for (let i = 0; i < select.options.length; i++) {
          newOptions.push({
            value: select.options[i].value,
            text: select.options[i].innerText,
            selected: select.options[i].hasAttribute('selected'),
          });
        }
        setOptions(newOptions);
      }
    };

    loadOptions();
  }, [id]);

   const open = () => {
     setShow(true);
   };

   const isOpen = () => {
     return show === true;
   };

 const select = (index: number, event: React.MouseEvent) => {
   const newOptions = [...options];

   if (!newOptions[index].selected) {
     newOptions[index].selected = true;
     newOptions[index].element = event.currentTarget as HTMLElement;
     setSelected([...selected, index]);
   } else {
     const selectedIndex = selected.indexOf(index);
     if (selectedIndex !== -1) {
       newOptions[index].selected = false;
       setSelected(selected.filter((i) => i !== index));
     }
   }

   setOptions(newOptions);
 };

  const remove = (index: number) => {
    const newOptions = [...options];
    const selectedIndex = selected.indexOf(index);

    if (selectedIndex !== -1) {
      newOptions[index].selected = false;
      setSelected(selected.filter((i) => i !== index));
      setOptions(newOptions);
    }
  };

  const selectedValues = () => {
    return selected.map((option) => options[option].value);
  };

    useEffect(() => {
      const clickHandler = ({ target }: MouseEvent) => {
        if (!dropdownRef.current) return;
        if (
          !show ||
          dropdownRef.current.contains(target) ||
          trigger.current.contains(target)
        )
          return;
        setShow(false);
      };
      document.addEventListener('click', clickHandler);
      return () => document.removeEventListener('click', clickHandler);
    });

  return (
    <div className="tw-relative tw-z-50">
      <label className="tw-mb-3 tw-block tw-text-sm tw-font-medium tw-text-black dark:tw-text-white">
        Multiselect Dropdown
      </label>
      <div>
        <select className="tw-hidden" id={id}>
          <option value="1">Option 2</option>
          <option value="2">Option 3</option>
          <option value="3">Option 4</option>
          <option value="4">Option 5</option>
        </select>

        <div className="tw-flex tw-flex-col tw-items-center">
          <input name="values" type="hidden" defaultValue={selectedValues()} />
          <div className="tw-relative tw-z-20 tw-inline-block tw-w-full">
            <div className="tw-relative tw-flex tw-flex-col tw-items-center">
              <div ref={trigger} onClick={open} className="tw-w-full">
                <div className="tw-mb-2 tw-flex tw-rounded tw-border tw-border-stroke tw-py-2 tw-pl-3 tw-pr-3 tw-outline-none tw-transition focus:tw-border-primary active:tw-border-primary dark:tw-border-form-strokedark dark:tw-bg-form-input">
                  <div className="tw-flex tw-flex-auto tw-flex-wrap tw-gap-3">
                    {selected.map((index) => (
                      <div
                        key={index}
                        className="tw-my-1.5 tw-flex tw-items-center tw-justify-center tw-rounded tw-border-[.5px] tw-border-stroke tw-bg-gray tw-px-2.5 tw-py-1.5 tw-text-sm tw-font-medium dark:tw-border-strokedark dark:tw-bg-white/30"
                      >
                        <div className="tw-max-w-full tw-flex-initial">
                          {options[index].text}
                        </div>
                        <div className="tw-flex tw-flex-auto tw-flex-row-reverse">
                          <div
                            onClick={() => remove(index)}
                            className="tw-cursor-pointer tw-pl-2 hover:tw-text-danger"
                          >
                            <svg
                              className="tw-fill-current"
                              role="button"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    ))}
                    {selected.length === 0 && (
                      <div className="tw-flex-1">
                        <input
                          placeholder="Select an option"
                          className="tw-h-full tw-w-full tw-appearance-none tw-bg-transparent tw-p-1 tw-px-2 tw-outline-none"
                          defaultValue={selectedValues()}
                        />
                      </div>
                    )}
                  </div>
                  <div className="tw-flex tw-w-8 tw-items-center tw-py-1 tw-pl-1 tw-pr-1">
                    <button
                      type="button"
                      onClick={open}
                      className="tw-h-6 tw-w-6 tw-cursor-pointer tw-outline-none focus:tw-outline-none"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                            fill="#637381"
                          ></path>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="tw-w-full tw-px-4">
                <div
                  className={`tw-max-h-select tw-absolute tw-top-full tw-left-0 tw-z-40 tw-w-full tw-overflow-y-auto tw-rounded tw-bg-white tw-shadow dark:tw-bg-form-input ${
                    isOpen() ? '' : 'tw-hidden'
                  }`}
                  ref={dropdownRef}
                  onFocus={() => setShow(true)}
                  onBlur={() => setShow(false)}
                >
                  <div className="tw-flex tw-w-full tw-flex-col">
                    {options.map((option, index) => (
                      <div key={index}>
                        <div
                          className="tw-w-full tw-cursor-pointer tw-rounded-t tw-border-b tw-border-stroke hover:tw-bg-primary/5 dark:tw-border-form-strokedark"
                          onClick={(event) => select(index, event)}
                        >
                          <div
                            className={`tw-relative tw-flex tw-w-full tw-items-center tw-border-l-2 tw-border-transparent tw-p-2 tw-pl-2 ${
                              option.selected ? 'tw-border-primary' : ''
                            }`}
                          >
                            <div className="tw-flex tw-w-full tw-items-center">
                              <div className="tw-mx-2 tw-leading-6">
                                {option.text}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
