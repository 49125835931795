import React, { useState } from 'react';

const CheckboxFive = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <div>
      <label
        htmlFor="checkboxLabelFive"
        className="tw-flex tw-cursor-pointer tw-select-none tw-items-center"
      >
        <div className="tw-relative">
          <input
            type="checkbox"
            id="checkboxLabelFive"
            className="tw-sr-only"
            onChange={() => {
              setIsChecked(!isChecked);
            }}
          />
          <div
            className={`tw-box tw-mr-4 tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-primary ${
              isChecked && '!tw-border-4'
            }`}
          >
            <span className="tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-white dark:tw-bg-transparent"></span>
          </div>
        </div>
        Checkbox Text
      </label>
    </div>
  );
};

export default CheckboxFive;
