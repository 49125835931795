import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

const PaymentSuccess = () => {
    return (
        <div className="tw-min-h-screen tw-bg-gray-50 tw-flex tw-items-center tw-justify-center tw-p-4">
            <div className="tw-max-w-md tw-w-full tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-8 tw-text-center">
                <CheckCircle className="tw-w-16 tw-h-16 tw-text-green-500 tw-mx-auto tw-mb-6" />
                <h1 className="tw-text-2xl tw-font-bold tw-text-gray-900 tw-mb-4">
                    Payment Successful!
                </h1>
                <p className="tw-text-gray-600 tw-mb-8">
                    Thank you for your purchase. Your order has been processed successfully.
                </p>
                <div className="tw-space-y-4">
                    <Link to="/shop" className="tw-block tw-w-full tw-bg-[#366196] tw-text-white tw-py-3 tw-rounded-md tw-font-medium tw-hover:bg-[#2b4e78]">
                        Continue Shopping
                    </Link>
                    <Link to="/orders" className="tw-block tw-w-full tw-bg-gray-100 tw-text-gray-700 tw-py-3 tw-rounded-md tw-font-medium tw-hover:bg-gray-200">
                        View Orders
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;