import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

const AnimatedCTA = ({ title, description, buttonText, onButtonClick }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="tw-my-8 tw-bg-gradient-to-br tw-from-[#366196] tw-to-[#2b4e78] tw-text-white tw-rounded-lg tw-shadow-2xl tw-p-10 tw-max-w-3xl tw-mx-auto tw-relative"
    >
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="tw-text-4xl tw-font-bold tw-mb-4 tw-text-shadow-md"
      >
        {title}
      </motion.h2>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        className="tw-text-lg tw-mb-6 tw-opacity-90"
      >
        {description}
      </motion.p>
      <motion.button
        whileHover={{ scale: 1.05, backgroundColor: '#e2e8f0', color: '#1a202c' }}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6, duration: 0.5 }}
        onClick={onButtonClick}
        className="tw-my-4 tw-mt-8 tw-bg-white tw-text-[#2b4e78] tw-font-semibold tw-py-3 tw-px-6 tw-rounded-full tw-inline-flex tw-items-center tw-space-x-2 tw-shadow-lg hover:tw-shadow-xl transition-shadow"
      >
        <span>{buttonText}</span>
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: 5, rotate: 0 }}
          transition={{ repeat: Infinity, duration: 1, repeatType: 'reverse' }}
        >
          <ArrowRight size={20} />
        </motion.div>
      </motion.button>
    </motion.div>
  );
};

export default AnimatedCTA;
