import React, { useState } from "react";
import { Link } from "react-router-dom";

const CustomSlider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="tw-relative tw-w-full tw-bg-white">
      <div className="tw-overflow-hidden">
        <div
          className="tw-flex tw-transition-transform tw-duration-500"
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
          }}
        >
          {images.map((imgSrc, index) => (
            <Link to="/offer" key={index} className="tw-flex-shrink-0 tw-w-full">
              <img src={imgSrc} alt={`Banner ${index + 1}`} className="tw-w-full tw-h-80" />
            </Link>
          ))}
        </div>
      </div>

      {/* Previous Button */}
      <button
        onClick={prevSlide}
        className="tw-absolute tw-left-0 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-h-12 tw-w-12 tw-rounded-full tw-bg-[#366196] tw-text-white tw-shadow-lg tw-transition-all tw-duration-300 z-10 ml-4"
      >
        &#10094; {/* Left Arrow */}
      </button>

      {/* Next Button */}
      <button
        onClick={nextSlide}
        className="tw-absolute tw-right-0 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-h-12 tw-w-12 tw-rounded-full tw-bg-[#366196] tw-text-white tw-shadow-lg tw-transition-all tw-duration-300 z-10 mr-4"
      >
        &#10095; {/* Right Arrow */}
      </button>

      {/* Dot Indicators */}
      <div className="tw-absolute tw-bottom-4 tw-left-1/2 tw-transform tw--translate-x-1/2 tw-flex tw-space-x-2 z-10">
        {images.map((_, index) => (
          <button
            key={index}
            className={`tw-h-2 tw-w-2 tw-rounded-full ${currentSlide === index ? 'tw-bg-blue-600' : 'tw-bg-gray-300'}`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};


export default CustomSlider;
