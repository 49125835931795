import { useState } from 'react';
import { Link } from 'react-router-dom';
import ClickOutside from '../ClickOutside.tsx';
import React from 'react';

const DropdownNotification = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifying, setNotifying] = useState(true);

  return (
    <ClickOutside onClick={() => setDropdownOpen(false)} className="tw-relative">
      <li>
        <Link
          onClick={() => {
            setNotifying(false);
            setDropdownOpen(!dropdownOpen);
          }}
          to="#"
          className="tw-relative tw-flex tw-h-8.5 tw-w-8.5 tw-items-center tw-justify-center tw-rounded-full tw-border-[0.5px] tw-border-stroke tw-bg-gray hover:tw-text-primary dark:tw-border-strokedark dark:tw-bg-meta-4 dark:tw-text-white"
        >
          <span
            className={`tw-absolute -tw-top-0.5 tw-right-0 tw-z-1 tw-h-2 tw-w-2 tw-rounded-full tw-bg-meta-1 ${
              notifying === false ? 'tw-hidden' : 'tw-inline'
            }`}
          >
            <span className="tw-absolute -tw-z-1 tw-inline-flex tw-h-full tw-w-full tw-animate-ping tw-rounded-full tw-bg-meta-1 tw-opacity-75"></span>
          </span>

          <svg
            className="tw-fill-current tw-duration-300 tw-ease-in-out"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1999 14.9343L15.6374 14.0624C15.5249 13.8937 15.4687 13.7249 15.4687 13.528V7.67803C15.4687 6.01865 14.7655 4.47178 13.4718 3.31865C12.4312 2.39053 11.0812 1.7999 9.64678 1.6874V1.1249C9.64678 0.787402 9.36553 0.478027 8.9999 0.478027C8.6624 0.478027 8.35303 0.759277 8.35303 1.1249V1.65928C8.29678 1.65928 8.24053 1.65928 8.18428 1.6874C4.92178 2.05303 2.4749 4.66865 2.4749 7.79053V13.528C2.44678 13.8093 2.39053 13.9499 2.33428 14.0343L1.7999 14.9343C1.63115 15.2155 1.63115 15.553 1.7999 15.8343C1.96865 16.0874 2.2499 16.2562 2.55928 16.2562H8.38115V16.8749C8.38115 17.2124 8.6624 17.5218 9.02803 17.5218C9.36553 17.5218 9.6749 17.2405 9.6749 16.8749V16.2562H15.4687C15.778 16.2562 16.0593 16.0874 16.228 15.8343C16.3968 15.553 16.3968 15.2155 16.1999 14.9343ZM3.23428 14.9905L3.43115 14.653C3.5999 14.3718 3.68428 14.0343 3.74053 13.6405V7.79053C3.74053 5.31553 5.70928 3.23428 8.3249 2.95303C9.92803 2.78428 11.503 3.2624 12.6562 4.2749C13.6687 5.1749 14.2312 6.38428 14.2312 7.67803V13.528C14.2312 13.9499 14.3437 14.3437 14.5968 14.7374L14.7655 14.9905H3.23428Z"
              fill=""
            />
          </svg>
        </Link>

        {dropdownOpen && (
          <div
            className={`tw-absolute -tw-right-27 tw-mt-2.5 tw-flex tw-h-90 tw-w-75 tw-flex-col tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default dark:tw-border-strokedark dark:tw-bg-boxdark sm:tw-right-0 sm:tw-w-80`}
          >
            <div className="tw-px-4.5 tw-py-3">
              <h5 className="tw-text-sm tw-font-medium tw-text-bodydark2">
                Notification
              </h5>
            </div>

            <ul className="tw-flex tw-h-auto tw-flex-col tw-overflow-y-auto">
              <li>
                <Link
                  className="tw-flex tw-flex-col tw-gap-2.5 tw-border-t tw-border-stroke tw-px-4.5 tw-py-3 hover:tw-bg-gray-2 dark:tw-border-strokedark dark:hover:tw-bg-meta-4"
                  to="#"
                >
                  <p className="tw-text-sm">
                    <span className="tw-text-black dark:tw-text-white">
                      Edit your information in a swipe
                    </span>{' '}
                    Sint occaecat cupidatat non proident, sunt in culpa qui
                    officia deserunt mollit anim.
                  </p>

                  <p className="tw-text-xs">12 May, 2025</p>
                </Link>
              </li>
              <li>
                <Link
                  className="tw-flex tw-flex-col tw-gap-2.5 tw-border-t tw-border-stroke tw-px-4.5 tw-py-3 hover:tw-bg-gray-2 dark:tw-border-strokedark dark:hover:tw-bg-meta-4"
                  to="#"
                >
                  <p className="tw-text-sm">
                    <span className="tw-text-black dark:tw-text-white">
                      It is a long established fact
                    </span>{' '}
                    that a reader will be distracted by the readable.
                  </p>

                  <p className="tw-text-xs">24 Feb, 2025</p>
                </Link>
              </li>
              <li>
                <Link
                  className="tw-flex tw-flex-col tw-gap-2.5 tw-border-t tw-border-stroke tw-px-4.5 tw-py-3 hover:tw-bg-gray-2 dark:tw-border-strokedark dark:hover:tw-bg-meta-4"
                  to="#"
                >
                  <p className="tw-text-sm">
                    <span className="tw-text-black dark:tw-text-white">
                      There are many variations
                    </span>{' '}
                    of passages of Lorem Ipsum available, but the majority have
                    suffered
                  </p>

                  <p className="tw-text-xs">04 Jan, 2025</p>
                </Link>
              </li>
              <li>
                <Link
                  className="tw-flex tw-flex-col tw-gap-2.5 tw-border-t tw-border-stroke tw-px-4.5 tw-py-3 hover:tw-bg-gray-2 dark:tw-border-strokedark dark:hover:tw-bg-meta-4"
                  to="#"
                >
                  <p className="tw-text-sm">
                    <span className="tw-text-black dark:tw-text-white">
                      There are many variations
                    </span>{' '}
                    of passages of Lorem Ipsum available, but the majority have
                    suffered
                  </p>

                  <p className="tw-text-xs">01 Dec, 2024</p>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </li>
    </ClickOutside>
  );
};

export default DropdownNotification;
